import { getAgendaList } from '../services/agendaServices'
import { Moment } from 'moment'

export interface IAgendaRepository {
  codiogProfesional: number
  dateStart: Moment
  dateEnd: Moment
}

export const agendaRepositories = () => {
  return {
    list: async (parameters: IAgendaRepository) => {
      const { codiogProfesional, dateStart, dateEnd } = parameters
      return await getAgendaList({ codiogProfesional, dateStart, dateEnd })
    }
  }
}
