import React from 'react'
import TopBar from './topBar/topBar'
import { Link, useNavigate } from 'react-router-dom'
import { MoreOptions } from '..'
import { MenuProps, PageHeader } from 'antd'
import { usePatient } from '../../services/patients/patientContextServices'
import { useHeaderPage } from '../../services/context/common/headerPageContextServices'
import { useIsMobile } from '../../services/responsive'
import styled from 'styled-components'

const PageHeaderApp = styled(PageHeader)`
      background: #fff;
`
export interface HeaderPageProps {
    sidebarCollapsed: boolean
    toggleSidebarCollapsed: () => void
}

const HeaderPage = (props: HeaderPageProps) => {
  const isMobile = useIsMobile()
  const usePatients = usePatient()
  const useHeader = useHeaderPage()

  const rootsOptions: MenuProps['items'] = [
    {
      key: '1',
      label: <Link className="" to={`/paciente/${usePatients.patientCode}/Anamnesis`}>
        <span className="">Anamnesis</span>
      </Link>
    }
  ]

  const navigation = useNavigate()
  const onBack = () => navigation(useHeader.onBack)

  return <PageHeaderApp
    onBack={useHeader.onBack.length > 0 ? onBack : undefined}
    title={!isMobile
      ? useHeader.headerPageTitle
      : <>{ isMobile
        ? <TopBar collapsed={props.sidebarCollapsed}
                onCollapse={props.toggleSidebarCollapsed}
                page={useHeader.headerPageTitle} />
        : null }</>}
    extra={ !useHeader.activeMoreOptionMenu ? <></> : <MoreOptions items={rootsOptions}/>}
    />
}

export default HeaderPage
