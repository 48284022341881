import React, { useEffect, useState } from 'react'
import { PatientRepositories } from '../../repositories'
import { useFetchParams } from '../../interfaces'
import useFetch from '../../hooks/useFetch'
import { FaUserTie } from 'react-icons/fa'
import '../../styles/patient.css'
import { Alert, Divider, Skeleton, Tabs, Typography } from 'antd'
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api'
import { CustomerServiceOutlined } from '@ant-design/icons'
import { useHeaderPage } from '../../services/context/common/headerPageContextServices'

const { Text, Paragraph, Title } = Typography
const Patient = () => {
  const useHeader = useHeaderPage()

  useEffect(() => {
    useHeader.setHeaderPageTitle('Detalle Del Paciente')
    useHeader.setOnBack('/Pacientes')
    useHeader.setActiveNavBot(true)
    useHeader.setActiveMoreOptionMenu(true)
  }, [])

  localStorage.setItem('numNav', JSON.stringify('1'))
  const url = window.location.toString().split('/')
  // const [loadingSke, setLoadingSke] = useState(true)
  const [loading, setLoading] = useState(true)
  const params: useFetchParams = { repositorieParam: PatientRepositories, param: url[url.length - 1], valueYear: 0, valueMonth: '' }
  const api = useFetch(params, setLoading)
  const [dat, setDat] = useState<number>(0)

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`
  })

  setTimeout(function () {
    api?.map((data) => setDat(data.codigoEntidadContratante))
    setLoading(false)
  }, 0)

  localStorage.setItem('dat', JSON.stringify(dat))

  return (
    <Skeleton loading={loading} active avatar style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px' }}>
      <div>
        {api?.map((patient) => (
          <div className='patient--container' key={patient!.codigoPaciente}>
            <div className='cardPatient--container'>
              <Skeleton loading={loading} active>
              <FaUserTie />
                <div>
                  <Text>({patient.codigoPaciente}) {patient.nombrePaciente}</Text>
                  <Text>{patient.direccionAtencion}</Text>
                  <Text>{patient.localidad}</Text>
                </div>
              </Skeleton>
            </div>
            <div>
            <Skeleton loading={loading} active>
            <Tabs defaultActiveKey="1" className='tab'
            items={[
              {
                label: 'Info Paciente',
                key: '1',
                style: { margin: 0 },
                children:
                  <>
                    <div className='text--patient'>
                      <p>Fecha de nacimiento </p>
                      <div>{patient.fechaNac ? patient.fechaNac : 'No posee'}</div>
                    </div>
                    <div className='text--patient'>
                      <p>Telefono </p>
                      <div>{patient.telefono ? patient.telefono : 'No posee'}</div>
                    </div>
                    <div className='text--patient'>
                      <p>Telefono alterno </p>
                      <div>{patient.telefonoAlt ? patient.telefonoAlt : 'No posee'}</div>
                    </div>
                    <div className='text--patient'>
                      <p>Telefono celular </p>
                      <div>{patient.telefonoCelular ? patient.telefonoCelular : 'No posee'}</div>
                    </div>
                    <div className='text--patient'>
                      <p>Empresa contratante </p>
                      <div>{patient.empresaContratante ? patient.empresaContratante : 'No posee'}</div>
                    </div>
                    <div className='text--patient'>
                      <p>Documento </p>
                      <div>{patient.tipoDocumento ? patient.tipoDocumento : 'No posee'}</div>
                    </div>
                    <div className='text--patient'>
                      <p>Nro de Documento </p>
                      <div>{patient.nroDocumento ? patient.nroDocumento : 'No posee'}</div>
                    </div>
                    <div className='text--patient'>
                      <p>Obra Social </p>
                      <div>{patient.obraSocial ? patient.obraSocial : 'No posee'}</div>
                    </div>
                    <div className='text--patient'>
                      <p>Nro Afiliado </p>
                      <div>{patient.nroAfiliado ? patient.nroAfiliado : 'No posee'}</div>
                    </div>
                  </>
              },
              {
                label: 'Info Responsables',
                key: '2',
                children: <div>
                  <Divider style={{ color: 'green' }} orientation="left">
                    Responsable 1
                  </Divider>
                      <div className='text--patient'>
                        <p>Familiar responsable </p>
                        <div>{patient.familiarResponsable01 ? patient.familiarResponsable01 : 'No posee'}</div>
                      </div>
                      <div className='text--patient'>
                        <p>Parentesco responsable </p>
                        <div>{patient.parentesco ? patient.parentesco : 'No posee'}</div>
                      </div>
                      <div className='text--patient'>
                        <p>Telefono responsable </p>
                        <div>{patient.telefonoResp01 ? patient.telefonoResp01 : 'No posee'}</div>
                      </div>
                      <div className='text--patient'>
                        <p>Telefono alterno resposable: </p>
                        <div>{patient.telefonoAltResp01 ? patient.telefonoAltResp01 : 'No posee'}</div>
                      </div>
                      <div className='text--patient'>
                        <p>Telefono celular responsable </p>
                        <div>{patient.telefonoCelularResp01 ? patient.telefonoCelularResp01 : 'No posee'}</div>
                      </div>
                  <Divider style={{ color: 'green', marginTop: '44px' }} orientation="left">
                    Responsable 2
                  </Divider>
                      <div className='text--patient'>
                        <p>Familiar responsable </p>
                        <div>{patient.familiarResponsable02 ? patient.familiarResponsable02 : 'No posee'}</div>
                      </div>
                      <div className='text--patient'>
                        <p>Parentesco responsable </p>
                        <div>{patient.parentesco02 ? patient.parentesco02 : 'No posee'}</div>
                      </div>
                      <div className='text--patient'>
                        <p>Telefono responsable </p>
                        <div>{patient.telefonoResp02 ? patient.telefonoResp02 : 'No posee'}</div>
                      </div>
                      <div className='text--patient'>
                        <p>Telefono alterno resposable: </p>
                        <div>{patient.telefonoAltResp02 ? patient.telefonoAltResp02 : 'No posee'}</div>
                      </div>
                      <div className='text--patient'>
                        <p>Telefono celular responsable </p>
                        <div>{patient.telefonoCelularResp02 ? patient.telefonoCelularResp02 : 'No posee'}</div>
                      </div>
                  <Divider style={{ color: 'green', marginTop: '44px' }} orientation="left">
                    Responsable 3
                  </Divider>
                      <div className='text--patient'>
                        <p>Familiar responsable </p>
                        <div>{patient.familiarResponsable03 ? patient.familiarResponsable03 : 'No posee'}</div>
                      </div>
                      <div className='text--patient'>
                        <p>Parentesco responsable </p>
                        <div>{patient.parentesco03 ? patient.parentesco03 : 'No posee'}</div>
                      </div>
                      <div className='text--patient'>
                        <p>Telefono responsable </p>
                        <div>{patient.telefonoResp03 ? patient.telefonoResp03 : 'No posee'}</div>
                      </div>
                      <div className='text--patient'>
                        <p>Telefono alterno resposable: </p>
                        <div>{patient.telefonoAltResp03 ? patient.telefonoAltResp03 : 'No posee'}</div>
                      </div>
                      <div className='text--patient'>
                        <p>Telefono celular responsable </p>
                        <div>{patient.telefonoCelularResp03 ? patient.telefonoCelularResp03 : 'No posee'}</div>
                      </div>
                </div>
              },
              {
                label: 'Ubicacion',
                key: '3',
                children: <div>
                <Title level={3}>Ubicacion</Title>
                    {!isLoaded && <Paragraph>Cargando...</Paragraph>}
                    {patient.longitud && patient.latitud
                      ? <GoogleMap zoom={16} center={{ lat: parseFloat(patient.latitud), lng: parseFloat(patient.longitud) }} mapContainerClassName={'map--container'}>
                        <MarkerF position={{ lat: parseFloat(patient.latitud), lng: parseFloat(patient.longitud) }}/>
                      </GoogleMap>
                      : <Alert
                          description={<>
                        <p> <CustomerServiceOutlined style={{ fontSize: '20px' }}/>La configuración de la longitud y latitud del paciente no está establecida actualmente. Le recomendamos que se comunique con la empresa de internación para resolver este problema.</p>
                          </>}
                          type="error"
                        />
                      }
                </div>
              }
            ]}>
          </Tabs>
          </Skeleton>
            </div>
          </div>
        ))}
      </div>
    </Skeleton>
  )
}
export default Patient
