import { User } from '../interfaces'
import { loginService } from '../services'

export const loginRepositories = async (values: User) => {
  const user = values.usuario
  const password = values.password
  if (!user || !password) {
    return alert('Por favor completa ambos campos')
  }
  const res = await loginService(values)
  return res
}
