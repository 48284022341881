import { iconLoader } from '../../lib/icon'
import React from 'react'
import { useAsync } from 'react-use'
import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'

export interface CurarteIconProps extends React.SVGAttributes<SVGElement> {
    name: string
    spin?: boolean
    backgroundColor?: string
}

const spinStyle = `
    & svg {
        -webkit-animation: icon-spin 2s infinite linear;
        animation: icon-spin 2s infinite linear;
    }
`

const IconContainer = styled.i<{ spin?: boolean }>`
    ${(props) => (props.spin ? spinStyle : '')}
`

const wrappedIconLoader = async (name: string) => {
  if (!name) return
  return iconLoader(name)
}

const CurarteIcon = (props: CurarteIconProps) => {
  const { name, spin, ...restProps } = props

  const loader = useAsync(() => wrappedIconLoader(name), [name])

  if (loader.loading) return <LoadingOutlined />

  if (loader.error || !loader.value) return null

  const SelectedIcon = loader.value
  return (
      <IconContainer className="anticon" spin={spin}>
          <SelectedIcon {...restProps} />
      </IconContainer>
  )
}

export default CurarteIcon
