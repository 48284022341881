import React from 'react'
import { Button } from 'antd'

interface SaveCancelButton {
    clickedCancel: () => void
    clickedSave: () => void
    clickExtraButton?: () => void
    disabled: boolean
    extraButtonHc?: boolean
    extraClass?: string
}
const style = { background: '#408a94 ', borderColor: '#408a94 ', color: '#fff' }

const ButtonGroup = (props: SaveCancelButton) => <>
    <Button disabled={props.disabled} onClick={props.clickedCancel} danger>Cancelar</Button>
    { props.extraButtonHc ? <Button disabled={props.disabled} onClick={props.clickExtraButton} style={ !props.disabled ? style : undefined}>Resetear</Button> : <></>}
    <Button disabled={props.disabled} onClick={props.clickedSave} type='primary'>Guardar</Button>
</>

export const SaveAndCancelButton = (props: SaveCancelButton) => {
  return (props.extraClass && props.extraClass !== ''
    ? <div className={props.extraClass}>
        <ButtonGroup {...props}/>
      </div>
    : <>
        <ButtonGroup {...props}/>
      </>)
}
