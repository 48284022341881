import { ClinicHistory, ClinicHistoryPut } from '../interfaces'
import { clininHistotyServices, putClinicHistory, GetClininHistory } from '../services'

export const clinicHistoryRepositories = async (values: ClinicHistory, valueYear: number, valueMonth: string) => {
  return await clininHistotyServices(values, valueYear, valueMonth)
}
export const clinicHistoryRepoPut = async (params : any) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo')!)
  const dateNow = new Date()
  const date = await formatDate(dateNow)
  const data: any = {
    codigoHc: params.codigoHc,
    codigoLinea: params.codigoLinea,
    fechaVisita: !params.fechaVisita ? null : await formatDate(new Date(params.fechaVisita)),
    tiempoAtencion: params.tiempoAtencion,
    detalle: params.detalle,
    usuarioModifica: userInfo.codigoUsuario,
    fechaModifica: date,
    codigoArchivo: 1,
    entradaLatitud: params.entradaLatitud,
    entradaLongitud: params.entradaLongitud,
    entradaFechaCarga: !params.entradaFechaCarga ? null : await formatDate(new Date(params.entradaFechaCarga)),
    salidaLatitud: params.salidaLatitud,
    salidaLongitud: params.salidaLongitud,
    firma: params.firma,
    salidaFechaCarga: !params.salidaFechaCarga ? null : await formatDate(new Date(params.salidaFechaCarga))
  }
  return await putClinicHistory(data)
}
export const getClinicHistory = async (params: ClinicHistory, valueYear: number, valueMonth: string) => {
  try {
    const res = await GetClininHistory(params, valueYear, valueMonth)
    return res
  } catch (error) {
    return null
  }
}
export const clinicHistoryRepoReset = async (params : ClinicHistoryPut) => {
  const data: ClinicHistoryPut = {
    codigoHc: params.codigoHc,
    codigoLinea: params.codigoLinea,
    fechaVisita: params.fechaVisita,
    tiempoAtencion: params.tiempoAtencion,
    detalle: params.detalle,
    usuarioModifica: null,
    fechaModifica: null,
    codigoArchivo: 1,
    entradaLatitud: params.entradaLatitud,
    entradaLongitud: params.entradaLongitud,
    entradaFechaCarga: params.entradaFechaCarga,
    salidaLatitud: params.salidaLatitud,
    salidaLongitud: params.salidaLongitud,
    firma: params.firma,
    salidaFechaCarga: params.salidaFechaCarga
  }
  return await putClinicHistory(data)
}

const formatDate = async (date) => {
  const month = ('00' + ((date.getMonth() + 1)))
  const day = ('00' + (date.getDate()))
  const hours = ('00' + (date.getHours()))
  const minute = ('00' + (date.getMinutes()))
  const seconds = ('00' + (date.getSeconds()))
  const formattedDate = date.getFullYear() + '-' + month.substring(month.length, month.length - 2) + '-' + day.substring(day.length, day.length - 2) + 'T' + hours.substring(hours.length, hours.length - 2) + ':' + minute.substring(minute.length, minute.length - 2) + ':' + seconds.substring(seconds.length, seconds.length - 2)
  return formattedDate
}
