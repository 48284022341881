import React, { useContext, useEffect, useState } from 'react'
import { Button, Input, Form } from 'antd'
import { loginRepositories } from '../repositories'
import '../styles/login.css'
import { useNavigate } from 'react-router-dom'
import { AuthLoginContext } from '../services/authContextService'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Download, OpenNotification } from '../components/'
import { Authentication, User } from '../interfaces/'
import { usePatient } from '../services/patients/patientContextServices'

export const Login = () => {
  const navigate = useNavigate()
  const authContext = useContext(AuthLoginContext)
  const usePatientInfo = usePatient()
  const disabled = localStorage.getItem('CorrectChangePassword')
  const disabledClear = disabled?.slice(1, -1)
  const tokenExpired = localStorage.getItem('TokenExpired')
  const tokenExpiredClear = tokenExpired?.slice(1, -1)
  const [isReadyForInstall, setIsReadyForInstall] = useState(false)

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (event) => {
      globalThis.deferredPrompt = event
      setIsReadyForInstall(true)
    })
  }, [])

  const downloadApp = async () => {
    const promptEvent = globalThis.deferredPrompt
    if (!promptEvent) {
      return
    }
    promptEvent.prompt()
    await promptEvent.userChoice
    globalThis.deferredPrompt = null
    setIsReadyForInstall(false)
  }

  useEffect(() => {
    if (disabledClear === 'true') {
      OpenNotification(
        'Modificacion Realizada',
        'Se ha realizado correctamente la modificacion de la Contraseña.',
        'top',
        'success'
      )
      localStorage.removeItem('CorrectChangePassword')
    }
    if (tokenExpiredClear === 'true') {
      OpenNotification(
        'Su Sesión Expiró',
        'Su sesión expiró y debido a eso debe loguearse de nuevo.',
        'top',
        'warning'
      )
      localStorage.removeItem('TokenExpired')
    }
    authContext.valirSessionUser()
    usePatientInfo.validaData()
  }, [])

  const userLogin = async (values: User) => {
    const res = (await loginRepositories(values)) as Authentication
    authContext.login(res.token)
    authContext.userInfoHandler(res.userInfo)
    navigate('/')
  }

  return (
    <div className="login--container">
      <img
        src={require('../assets/logo.png')}
        alt="Curarte, una plataforma de bienestar y salud"
      />
      <h1 style={{ padding: '20px', textAlign: 'center', fontSize: '1.2rem' }}>
        Software para la gestión de empresas de internación domiciliaria
      </h1>
      <Form
        className="login-form"
        name="basic"
        onFinish={userLogin}
        autoComplete="off"
        layout={'horizontal'}
      >
        <Form.Item
          className="login-form-item"
          name="usuario"
          rules={[{ required: true, message: 'Por favor inserta un usuario' }]}
        >
          <Input
            prefix={
              <UserOutlined
                className="site-form-item-icon"
                style={{ color: '#36bc9b' }}
              />
            }
            placeholder="Usuario"
          />
        </Form.Item>
        <Form.Item
          className="login-form-item"
          name="password"
          rules={[
            { required: true, message: 'Por favor ingresa una contraseña' },
          ]}
        >
          <Input.Password
            prefix={
              <LockOutlined
                className="site-form-item-icon"
                style={{ color: '#36bc9b' }}
              />
            }
            placeholder="Contraseña"
          />
        </Form.Item>
        <Form.Item className="login-form-item">
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ background: '#36bc9b', borderColor: '#36bc9b' }}
          >
            Ingresar
          </Button>
        </Form.Item>
      </Form>
      {isReadyForInstall && <Download onClick={downloadApp} />}
    </div>
  )
}
