import React, { useContext } from 'react'
import 'antd/dist/antd.min.css'
import { AuthLoginContext } from './services/authContextService'
import { Login, MainPage } from './pages'

const App = () => {
  const authContext = useContext(AuthLoginContext)
  return (authContext.isLoggedIn ? <MainPage /> : <Login />)
}
export default App
