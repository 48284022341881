import React from 'react'
import MainLayout from '../components/Layout'
import { Router } from '../router'
const MainPage = () => {
  return <MainLayout>
          <Router />
        </MainLayout>
}

export default MainPage
