import { authService } from './authService'
import axios from 'axios'

const remitosServices = async (values : any, valueYear: number, valueMonth: string) => {
  const year = valueYear.toString()
  authService()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/pacientes/${values.codigoPaciente}/remitos?Año=${year}&Mes=${valueMonth}&Limit=9999999&Offset=0`)
  return await api
}
export default remitosServices
