import React, { createContext, useContext, useState } from 'react'
import { IHeaderPageContextProps, IHeaderPageContextProviderProps } from '../../../interfaces'

export const HeaderPageContext = createContext<IHeaderPageContextProps>(undefined!)

export const useHeaderPage = () => useContext(HeaderPageContext)

export const HeaderPageContextProvider: React.FC<IHeaderPageContextProviderProps> = ({ children }) => {
  const [headerPageTitle, setHeaderPageTitle] = useState<string>('')
  const [onBack, setOnBack] = useState<string>('')
  const [activeNavBot, setActiveNavBot] = useState<boolean>(false)
  const [activeMoreOptionMenu, setActiveMoreOptionMenu] = useState<boolean>(false)

  const contextValue: IHeaderPageContextProps = {
    headerPageTitle,
    setHeaderPageTitle,
    onBack,
    setOnBack,
    activeNavBot,
    setActiveNavBot,
    activeMoreOptionMenu,
    setActiveMoreOptionMenu
  }
  return <HeaderPageContext.Provider value={contextValue}>{children}</HeaderPageContext.Provider>
}
