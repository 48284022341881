import { useWindowSize } from 'react-use'

export const screenBreakpoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
}

const calcIsMobile = (currentWidth: number) =>
  currentWidth <= screenBreakpoints.md

export const getIsMobile = () => calcIsMobile(window.innerWidth)

export const useIsMobile = () => {
  const { width: currentWidth } = useWindowSize()
  return calcIsMobile(currentWidth)
}

export const useShowElement = (
  hideOnDesktop?: boolean,
  hideOnMobile?: boolean
) => {
  return useIsMobile() ? !hideOnMobile : !hideOnDesktop
}
