import { Form, Input, Select, Typography } from 'antd'
import React from 'react'
import '../../styles/SelectYearMonthScaneo.css'

export interface SelectYearMonthScaneoProps {
    year: number
    month: string
    selectYear: (e: any) => void
    selectMonth: (e: any) => void
    disabledSelectYear: boolean
    disabledSelectMonth: boolean
}

const SelectYearMonthScaneo = (props: SelectYearMonthScaneoProps) => {
  const currentTime = new Date()
  const year = currentTime.getFullYear()
  const { Option } = Select
  const { Text } = Typography

  return (
    <>
    <Form.Item
            name={'Select-MesAño'}
          >
            <Input.Group compact className='inputYearMonth'>
                  <Text className='selectFecha-Text'>Año</Text>
                  <Select className='selectYear-scaneo' defaultValue={props.year} onChange={props.selectYear}>
                      <Option value={year - 3}>{year - 3}</Option>
                      <Option value={year - 2}>{year - 2}</Option>
                      <Option value={year - 1}>{year - 1}</Option>
                      <Option value={year}>{year}</Option>
                      <Option value={year + 1}>{year + 1}</Option>
                  </Select>
                  <Text className='selectFecha-Text'>Mes</Text>
                  <Select className='selectMonth-scaneo' defaultValue={props.month} onChange={props.selectMonth} style={{ width: 120 }} listHeight={400} >
                      <Option value="1">Enero</Option>
                      <Option value="2">Febrero</Option>
                      <Option value="3">Marzo</Option>
                      <Option value="4">Abril</Option>
                      <Option value="5">Mayo</Option>
                      <Option value="6">Junio</Option>
                      <Option value="7">Julio</Option>
                      <Option value="8">Agosto</Option>
                      <Option value="9">Septiembre</Option>
                      <Option value="10">Octubre</Option>
                      <Option value="11">Noviembre</Option>
                      <Option value="12">Diciembre</Option>
                  </Select>
              </Input.Group>
      </Form.Item>
      </>
  )
}
export default SelectYearMonthScaneo
