import React, { useEffect, useState } from 'react'
import { useFetchParams } from '../interfaces'
import '../styles/remitos.css'
import SelectorYearMonth from '../components/Inputs/SelectorYearMonth'
import remitosRepositories from '../repositories/remitosRepositories'
import { Spin, Table, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { LoadingOutlined } from '@ant-design/icons'
import { useHeaderPage } from '../services/context/common/headerPageContextServices'
import { usePatient } from '../services/patients/patientContextServices'
import { FullNamePatient } from '../components'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

interface DataType {
  key: React.Key;
  Articulo: string;
  Cantidad: number;
  Tipo: string;
}
interface ServicesType {
  type: string
  color: string
}

const colorServicestype: ServicesType[] = [
  {
    type: 'Insumos',
    color: '#FFBC79'
  },
  {
    type: 'Alimentos',
    color: '#FFE280'
  },
  {
    type: 'Medicamentos',
    color: '#FF7171'
  },
  {
    type: 'Articulos Retornables',
    color: '#6DC0FE'
  }
]
const columns: ColumnsType<DataType> = [
  {
    title: 'Articulo',
    dataIndex: 'articulo'
  },
  {
    title: 'Cantidad',
    dataIndex: 'cantidad',
    align: 'center'
  },
  {
    title: 'Tipo',
    dataIndex: 'tipo',
    render: (text: string) => (
      <>
        {
        <Tag color={ colorServicestype.find(x => x.type === text)?.color || ''}>
          {text}
        </Tag>
        }
      </>
    )
  }
]
// text === 'Insumos' ? '#FFBC79' : text === 'Alimentos' ? '#FFE280' : text === 'Medicamentos' ? '#FF7171' : '6DC0FE'

function Remitos () {
  const usePatients = usePatient()
  const useHeader = useHeaderPage()

  useEffect(() => {
    useHeader.setHeaderPageTitle('Stock')
    useHeader.setOnBack(`/Paciente/${usePatients.patientCode}`)
    useHeader.setActiveNavBot(true)
    useHeader.setActiveMoreOptionMenu(false)
  }, [])

  localStorage.setItem('numNav', JSON.stringify('2'))
  const data: any = {
    codigoPaciente: usePatients.patientCode
  }
  const selectYear = localStorage.getItem('selectYear')
  const selectYearClear = parseInt(selectYear!)
  const selectMonth = localStorage.getItem('selectMonth')
  const selectMonthClear = selectMonth!.slice(1, -1)
  const [selectValueYear, setselectvalueYear] = useState(selectYearClear)
  const [selectValueMonth, setselectvalueMonth] = useState(selectMonthClear)
  const [data1, setData1] = useState<DataType[]>([])
  const [loading, setLoading] = useState(false)
  const params: useFetchParams = { repositorieParam: remitosRepositories, param: data, valueYear: selectValueYear, valueMonth: selectValueMonth }
  useEffect(() => {
    setLoading(true)
    const fecth = async (values : useFetchParams) => {
      const data = await values.repositorieParam(values.param, values.valueYear, values.valueMonth)
      return data
    }
    fecth(params).then((res) => {
      setData1(res.data.data.map((el: any, index: number) => {
        return { key: index, articulo: el.nombreArticulo, cantidad: el.cantidad, tipo: el.tipoArticulo }
      }))
      setLoading(false)
    })
  }, [selectValueYear, selectValueMonth])
  return (
    <div style={{ background: '#fff' }}>
        <FullNamePatient />
        <SelectorYearMonth disabledSelect={false} defaulYear={selectValueYear} defaulMonth={selectValueMonth} selectYear={function (e: React.SetStateAction<number>): void {
          setselectvalueYear(e)
        } } selectMonth={function (e: React.SetStateAction<string>): void {
          setselectvalueMonth(e)
        } }/>
        <Spin indicator={antIcon} spinning={loading}>
          <Table
            columns={columns}
            dataSource={data1}
            pagination={false}
          />
        </Spin>
    </div>
  )
}

export default Remitos
