import React from 'react'

const iconPackLoader = async (
): Promise<undefined | Record<string, any>> => {
  return await import('@ant-design/icons')
}

export const iconLoader = async (name: string) => {
  if (!name) return

  const iconPack = await iconPackLoader()
  if (!iconPack) return

  return iconPack[name] as React.ComponentType<React.SVGAttributes<SVGElement>>
}
