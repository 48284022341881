import { useEffect, useState } from 'react'
import { useFetchParams } from '../interfaces'

const useFetch = (params: useFetchParams, setState: (value: any) => void) => {
  const [api, setApi] = useState<any[]>()
  useEffect(() => {
    setState(true)
    const fecth = async (values : useFetchParams) => {
      const data = await values.repositorieParam(values.param, values.valueYear, values.valueMonth)
      setState(false)
      return data
    }
    fecth(params).then((res) => setApi(res.data.data !== undefined ? [res.data.data] : res.data))
  }, [])
  return api
}

export default useFetch
