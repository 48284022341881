import React, { useEffect, useState } from 'react'
import { useFetchParams } from '../interfaces'
import { developmentsRepositories } from '../repositories'
import styled from 'styled-components'
import '../styles/developments.css'
import { Avatar, Button, Form, Input, Modal, Spin } from 'antd'
import { EditOutlined, EyeFilled, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import Table, { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import { developmentsRepoDelete, developmentsRepoPost, developmentsRepoPut } from '../repositories/developmentsRepositories'
import Calendar from '../components/Inputs/Calendar'
import developmentsPut from '../interfaces/developments/developments'
import SelectorYearMonth from '../components/Inputs/SelectorYearMonth'
import { Key } from 'antd/lib/table/interface'
import { useHeaderPage } from '../services/context/common/headerPageContextServices'
import { usePatient } from '../services/patients/patientContextServices'
import { useAuthentication } from '../services/authContextService'
import { FullNamePatient } from '../components'

const FixedWidgets = styled.div`
  position: fixed;
  right: 10px;
  bottom: 90px;
  z-index: 2147483640;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`
const AvatarFixed = {
  color: '#000',
  background: '#fff',
  boxShadow: '0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d',
  transition: 'color .3s'
}

interface DataType {
  key: React.Key;
  fechaEvolucion: Date;
  descripcion: string;
  codigoPaciente: number,
  codigoProfesional: number,
  codigoEntidad: number,
  año: number,
  mes: number,
  fechaAlta: string | Date,
  nombreProfesional: string,
  Profesional: string,
  nombrePaciente: string,
  disabled:boolean,
  isSelected: boolean
}

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  defaultValue?:any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

interface CustomizedFormProps {
  onChange: (fields: FieldData[]) => void;
  fields: FieldData[];
  own: boolean;
}

const CustomizedForm: React.FC<CustomizedFormProps> = ({
  onChange,
  fields,
  own
}) => (
  <Form
    name="global_state"
    fields={fields}
    onFieldsChange={(_, allFields) => {
      onChange(allFields)
    }}
  >
    <Form.Item
      name="fechaEvolucion"
      label="Fecha Evolucion"
    >
      <Calendar notShowTime={true} disabled={true} color={'Black'} />
    </Form.Item>

    <Form.Item
      name="descripcion"
      label="Descripcion"
    >
      <Input.TextArea rows={6} disabled={own} style={{ color: 'Black' }} />
    </Form.Item>
    <Form.Item
      name="Profesional"
      label="Profesional"
    >
      <Input disabled={true} style={{ color: 'Black' }}/>
    </Form.Item>
  </Form>
)
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const Developments = () => {
  const userInfo = useAuthentication().userInfo
  const usePatients = usePatient()
  const useHeader = useHeaderPage()

  useEffect(() => {
    useHeader.setHeaderPageTitle('Evoluciones')
    useHeader.setOnBack(`/Paciente/${usePatients.patientCode}`)
    useHeader.setActiveNavBot(true)
    useHeader.setActiveMoreOptionMenu(false)
  }, [])

  localStorage.setItem('numNav', JSON.stringify('2'))

  const [indexEdit, setIndexEdit] = useState<string | Date | undefined>()
  const [patient, setPatient] = useState({ patient: '', patientCode: 0, EntityCode: 0 })
  const selectYear = localStorage.getItem('selectYear')
  const selectYearClear = parseInt(selectYear!)
  const selectMonth = localStorage.getItem('selectMonth')
  const selectMonthClear = selectMonth!.slice(1, -1)
  const [selectValueYear, setselectvalueYear] = useState(selectYearClear)
  const [selectValueMonth, setselectvalueMonth] = useState(selectMonthClear)
  const [data1, setData1] = useState<DataType[]>([])
  const [api, setApi] = useState<any[]>()
  const [Posts, setPosts] = useState({ descripcion: '', fechaEvolucion: new Date('1996-09-02') })
  const [PostsEdit, setPostsEdit] = useState({ descripcion: '' })
  const [isOwn, setIsOwn] = useState(true)
  const [, setIndexDelete] = useState<any>(1000000)
  /*   const [isOwnDelete, setIsOwnDelete] = useState(true) */
  const [isModified, setIsModified] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false)
  const [displayValue, setDisplayValue] = useState('none')
  const [fields, setFields] = useState<FieldData[]>([])
  const namePatient = localStorage.getItem('nombrePaciente')
  const namePatientClear = namePatient?.slice(1, -1)
  const codEntidad = localStorage.getItem('dat')
  const [loading, setLoading] = useState(false)
  const [reloadPost, setReloadPost] = useState<any>(0)
  const [reloadPut, setReloadPut] = useState<any>(0)
  const [errorModal, setErrorModal] = useState({ datePicker: '', description: 'undefined' })
  const [loadingModal, setLoadingModal] = useState(false)
  const [form] = Form.useForm()
  const [selectedRowKey, setSelectedRowKey] = useState<Key | undefined>()
  const [condition, setCondition] = useState(false)

  const dateNowCompare = new Date()
  const dateNowMonthCompare = (dateNowCompare.getMonth() + 1)
  const dateNowYearCompare = dateNowCompare.getFullYear()
  const dateYearCompare : any = []
  const dateMonthCompare : any = []

  const handleButtonClick = async (key: React.Key, descrip, fechaEvo, codigoEnti, codigoPac, codigoProf, fechaAlt, añoEvolucion, mesEvolucion, nomProfesional, nomPaciente) => {
    // Maneja el clic en el botón usando la clave de la fila clickeada
    await (
      developmentsRepoDelete({ fechaAlta: fechaAlt, descripcion: descrip, fechaEvolucion: fechaEvo, codigoEntidad: codigoEnti, codigoProfesional: codigoProf, codigoPaciente: codigoPac, año: añoEvolucion, mes: mesEvolucion, nombreProfesional: nomProfesional, nombrePaciente: nomPaciente }),
      setReloadPut(2)
    )
  }
  const calculateCondition = () => {
    const selectedData = data1.find(item => item.key === selectedRowKey)
    if (!selectedData) return false

    const selectedDescripcion = fields.find(field => field.name[0] === 'descripcion')?.value
    /* const selectedProfesional = fields.find(field => field.name[0] === 'Profesional')?.value */

    return (
      selectedData.descripcion === selectedDescripcion &&
      selectedData.nombreProfesional === userInfo.nombreUsuario
    )
  }

  useEffect(() => {
    const newCondition = calculateCondition()
    setCondition(newCondition)
  }, [selectedRowKey, fields])

  const handleRowSelect = (record: DataType) => {
    setSelectedRowKey(record.key)
    const updatedData = data1.map(item => ({
      ...item,
      isSelected: item.key === record.key
    }))
    setData1(updatedData)
  }

  const func = (a, b) => {
    dateYearCompare.pop()
    dateMonthCompare.pop()
    dateYearCompare.push(a)
    dateMonthCompare.push(b)
  }
  const compareDate = () => {
    (dateNowCompare.getMonth() - 1) === 0 || (dateNowCompare.getMonth() - 1) === -1
      ? func((dateNowCompare.getFullYear() - 1), 12)
      : func((dateNowCompare.getFullYear()), (dateNowCompare.getMonth() - 1))
  }
  compareDate()

  /** Refactorizar */
  const showModal = () => {
    setIsModalOpen(true)
    setDisplayValue('none')
  }
  const handleOk = () => {
    setLoadingModal(true)
    Post()
    form.resetFields()
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const showModalEdit = () => {
    setIsModalOpenEdit(true)
  }
  const handleOkEdit = () => {
    setIsModalOpenEdit(false)
    if (isOwn && isModified) {
      Put()
      setIsModified(false) // una vez actualizado devuelvo la variable a false
    }
  }
  const handleCancelEdit = () => {
    setIsModalOpenEdit(false)
  }
  /*****************/
  const url = window.location.toString().split('/')
  const data = {
    codigoProfesional: userInfo.codigoProfesional,
    codigoPaciente: url[url.length - 2],
    Limit: 20,
    Offset: 1
  }
  const params: useFetchParams = { repositorieParam: developmentsRepositories, param: data, valueYear: selectValueYear, valueMonth: selectValueMonth }

  useEffect(() => {
    const data1Result = api?.find(el => el.fechaEvolucion === indexEdit)
    if (data1Result) {
      setIndexDelete(api?.findIndex(elemento => elemento.fechaEvolucion === data1Result.fechaEvolucion && elemento.descripcion === data1Result.descripcion && elemento.nombreProfesional === data1Result.nombreProfesional && elemento.codigoPaciente === data1Result.codigoPaciente && elemento.año === data1Result.año && elemento.mes === data1Result.mes && elemento.codigoEntidad === data1Result.codigoEntidad))
    }
  }, [indexEdit])

  useEffect(() => {
    setLoading(true)
    setDisplayValue('none')
    const fecth = async (values : useFetchParams) => {
      const data = await values.repositorieParam(values.param, values.valueYear, values.valueMonth)
      return data
    }
    fecth(params).then((res) => {
      setPatient(res.data.data.filter((a: any) => a.codigoPaciente === parseInt(url[url.length - 2])).length === 0 ? { patient: namePatientClear, patientCode: data.codigoPaciente, EntityCode: codEntidad } : { patient: res.data.data[0].nombrePaciente, patientCode: res.data.data[0].codigoPaciente, EntityCode: res.data.data[0].codigoEntidad })
      setApi(res.data.data.filter((a: any) => a.codigoPaciente === parseInt(url[url.length - 2])))
      setData1(res.data.data.filter((a: any, index: number) => a.codigoPaciente === parseInt(url[url.length - 2])).map((el: any, index: number) => {
        return { key: el.fechaEvolucion, fechaEvolucion: moment(el.fechaEvolucion).toISOString().substring(0, 10), descripcion: el.descripcion, Profesional: el.nombreProfesional, codigoPaciente: el.codigoPaciente, codigoProfesional: el.codigoProfesional, fechaAlta: el.fechaAlta, codigoEntidad: el.codigoEntidad, año: el.año, mes: el.mes, nombrePaciente: el.nombrePaciente, nombreProfesional: el.nombreProfesional, disabled: condition, isSelected: false }
      }))
      setLoading(false)
    })
    setReloadPost(0)
    setReloadPut(0)
  }, [selectValueYear, selectValueMonth, reloadPost, reloadPut])

  const columns: ColumnsType<DataType> = [
    {
      title: 'Fecha Evolucion',
      dataIndex: 'fechaEvolucion'
      // render: (text: string) => <a>{text}</a>
    },
    {
      title: 'Descripcion',
      dataIndex: 'descripcion'
    },
    {
      title: 'Profesional',
      dataIndex: 'Profesional'
    },
    {
      title: 'Acción',
      dataIndex: 'Accion',
      render: (_, record) => (
        <Button disabled={!record.isSelected || !condition} className='button-delete' onClick={() => handleButtonClick(record.key, record.descripcion, record.key, record.codigoEntidad, record.codigoPaciente, record.codigoProfesional, record.fechaAlta, record.año, record.mes, record.nombreProfesional, record.nombrePaciente)}>Eliminar</Button>
      )
    }
  ]
  /** Format Date */
  const formatDate = (e) => {
    const month = ('00' + ((e.getMonth() + 1)))
    const day = ('00' + (e.getDate()))
    const hours = ('00' + (e.getHours()))
    const minute = ('00' + (e.getMinutes()))
    const seconds = ('00' + (e.getSeconds()))
    const formattedDate2 = e.getFullYear() + '-' + month.substring(month.length, month.length - 2) + '-' + day.substring(day.length, day.length - 2) + 'T' + hours.substring(hours.length, hours.length - 2) + ':' + minute.substring(minute.length, minute.length - 2) + ':' + seconds.substring(seconds.length, seconds.length - 2)
    return formattedDate2
  }
  /** Method Post */
  const Post = async () => {
    const dateNow = new Date()
    const date = formatDate(dateNow)
    const dataNews: developmentsPut = {
      codigoPaciente: patient.patientCode,
      codigoProfesional: userInfo.codigoProfesional,
      fechaEvolucion: undefined,
      codigoEntidad: patient.EntityCode,
      descripcion: '',
      usuarioAlta: userInfo.codigoUsuario,
      fechaAlta: date,
      usuarioModifica: undefined,
      fechaModifica: undefined
    }

    setErrorModal({ datePicker: Posts.fechaEvolucion.getFullYear() < 2000 ? 'error' : '', description: Posts.descripcion.length === 0 ? 'error' : '' })
    if (Posts.fechaEvolucion.getFullYear() < 2000 || Posts.descripcion.length === 0) {
      setLoadingModal(false)
    } else {
      await developmentsRepoPost({ ...dataNews, ...Posts, fechaEvolucion: formatDate(Posts.fechaEvolucion) })
      setLoadingModal(false)
      setIsModalOpen(false)
      setReloadPut(1)
    }
  }
  /** Method Put */
  const Put = async () => {
    const result = api?.filter(el => el.fechaEvolucion === indexEdit)[0]
    const dateNow = new Date()
    const date = formatDate(dateNow)
    await (
      developmentsRepoPut({ ...result, ...PostsEdit, fechaAlta: result.fechaAlta, usuarioModifica: userInfo.codigoUsuario, fechaModifica: date, nombreUsuarioModifica: userInfo.nombreUsuario }),
      setReloadPut(1)
    )
  }
  return (
    <div style={{ backgroundColor: 'white' }}>
      <FullNamePatient />
      <SelectorYearMonth disabledSelect={false} defaulYear={selectValueYear} defaulMonth={selectValueMonth} selectYear={function (e: React.SetStateAction<number>): void {
        setselectvalueYear(e)
      } } selectMonth={function (e: React.SetStateAction<string>): void {
        setselectvalueMonth(e)
      } }/>
    <Spin indicator={antIcon} spinning={loading}>
    <Table
  pagination={false}
  className='tabla-dev'
  rowSelection={{
    type: 'radio',
    selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
    onSelect: (record: DataType) => {
      handleRowSelect(record)
      const updatedData = data1.map(item => ({
        ...item,
        isSelected: item.key === record.key
      }))
      setData1(updatedData)
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.descripcion === 'Disabled',
      name: record.descripcion
    }),
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setDisplayValue('')
      const result = api?.find(el => el.fechaEvolucion === selectedRowKeys[0])
      setFields([
        { name: ['descripcion'], value: result.descripcion },
        { name: ['fechaEvolucion'], value: moment(new Date(result.fechaEvolucion)) },
        { name: ['Profesional'], value: result.nombreProfesional },
        { name: ['fechaEvolucionData'], value: result.fechaEvolucion }
      ])
      const user = JSON.parse(localStorage.getItem('userInfo') || '[]')
      if (result.codigoProfesional === user.codigoProfesional) {
        setIsOwn(true)
      } else {
        setIsOwn(false)
      }
      setIndexEdit(result.fechaEvolucion)
    }
  }}
  columns={columns} // Definición de columnas actualizada
  dataSource={data1.map(item => ({
    ...item,
    isSelected: item.key === selectedRowKey
  }))}
/>
      </Spin>
      {dateNowCompare.getDate() > 15
        ? dateNowMonthCompare === parseInt(selectValueMonth) && dateNowYearCompare === selectValueYear
          ? isModalOpen || isModalOpenEdit
            ? null
            : <FixedWidgets>
            <Avatar onClick={showModal} size={60} icon={<PlusOutlined />} style={ AvatarFixed } />
          </FixedWidgets>
          : null
        : (dateMonthCompare[0] === parseInt(selectValueMonth) && dateYearCompare[0] === selectValueYear) || (dateNowMonthCompare === parseInt(selectValueMonth) && dateNowYearCompare === selectValueYear)
            ? isModalOpen || isModalOpenEdit
              ? null
              : <FixedWidgets>
            <Avatar onClick={showModal} size={60} icon={<PlusOutlined />} style={ AvatarFixed } />
          </FixedWidgets>
            : null}

     {isModalOpenEdit
       ? null
       : <FixedWidgets style={{ bottom: '160px', display: displayValue }}>
           <Avatar onClick={showModalEdit} size={60} icon={ isOwn ? <EditOutlined /> : <EyeFilled /> } style={ AvatarFixed }/>
         </FixedWidgets> }

      <Modal
        title="Nueva Evolucion"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        closable={false}
        confirmLoading={loadingModal}
      >
        <Form form={form}>
          <Form.Item
            label="Fecha Evolucion"
            name="fechaEvolucion"
            >
              <Calendar notShowTime={true} status={errorModal.datePicker} onChange={(e: any) => {
                setPosts({ ...Posts, fechaEvolucion: new Date(e._d) })
                setErrorModal({ datePicker: new Date(e._d).getFullYear() < 2000 ? 'error' : '', description: Posts.descripcion.length === 0 ? 'error' : '' })
              }} inputReadOnly={true} />
          </Form.Item>
          <Form.Item
          label="Descripción"
          name="descripcion"
          >
            <Input.TextArea status={errorModal.description === 'error' ? errorModal.description : undefined} onChange={(e) => {
              setPosts({ ...Posts, descripcion: e.target.value })
              setErrorModal({ datePicker: Posts.fechaEvolucion.getFullYear() < 2000 ? 'error' : '', description: Posts.descripcion.length === 0 ? 'error' : '' })
            }} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal title={isOwn ? 'Editar Evolucion' : 'Visualizar Evolucion' } open={isModalOpenEdit} onOk={handleOkEdit} onCancel={handleCancelEdit} maskClosable={false} closable={false}>
        <CustomizedForm
          fields={fields}
          onChange={(newFields) => {
            setPostsEdit({ descripcion: newFields[1].value })
            setIsModified(true)
          }}
          own={!isOwn}
        />
      </Modal>
    </div>
  )
}

export default Developments
/** 439 lineas de codigo */
