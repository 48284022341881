import { Layout } from 'antd'
import React, { useEffect, useState } from 'react'
// import styled from 'styled-components'
import { MainMenu, NavInferior } from '.'
import { useIsMobile } from '../services/responsive'
import HeaderPage from './Header/headerPage'
import { useLocation } from 'react-router-dom'
import { useHeaderPage } from '../services/context/common/headerPageContextServices'

// import Router from '../router/Router'
// const { Content, Footer, Header } = Layout
const { Content, Footer } = Layout
/* const StyledHeader = styled(Header)`
    box-shadow: rgb(204, 204, 204) 0px 1px 2px;
    z-index: 9;
` */
export interface LayoutProps {
    children: React.ReactNode
}

const MainLayout = (props: LayoutProps) => {
  const isMobile = useIsMobile()
  const useHeader = useHeaderPage()
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
  // const mobile = isMobile
  const location = useLocation()
  const toggleSidebarCollapsed = () => {
    setSidebarCollapsed((collapsed) => !collapsed)
  }

  const sidebar = <MainMenu
                    collapsed={sidebarCollapsed}
                    onCollapse={toggleSidebarCollapsed} />

  useEffect(() => {
    setSidebarCollapsed(false)
  }, [location])

  const layoutStyle = {
    paddingLeft: isMobile
      ? '0px'
      : sidebarCollapsed
        ? '200px'
        : '84px'
  }
  const contentStyle: React.CSSProperties = {
    padding: isMobile ? 0 : 14,
    margin: 0,
    minHeight: 260,
    marginBottom: isMobile
      ? 0
      : 60
  }

  return <Layout hasSider style={{ minHeight: '100vh' }}>
            {sidebar}
            <Layout style={layoutStyle}>
                <HeaderPage
                 sidebarCollapsed={sidebarCollapsed}
                 toggleSidebarCollapsed={toggleSidebarCollapsed}
                 />
                 <Content style={contentStyle}>{props.children}</Content>

                { !useHeader.activeNavBot
                  ? <Footer style={{
                    textAlign: 'center',
                    padding: '12px',
                    height: '48px',
                    bottom: 0,
                    background: 'white',
                    borderTop: '1px solid  rgb(217, 217, 217)'
                  }}>Vignolo y Asosiados S.A.</Footer>
                  : <NavInferior />}
            </Layout>
        </Layout>
}

export default MainLayout
