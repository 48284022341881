import React from 'react'
import { useIsMobile } from '../../services/responsive'
import Desktop from './desktop'
import Mobile from './mobile'

export interface SidebarProps {
    collapsed: boolean
    onCollapse(): void
}

const MainMenu = (porps: SidebarProps) => {
  return !useIsMobile()
    ? (<Desktop {...porps}/>)
    : (<Mobile {...porps}/>)
}

export default MainMenu
