import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'

export const Download = (props: any) => {
  return (<>
      <Button type="primary" icon={<DownloadOutlined />} size={'large'} {...props}>
        Download
      </Button>
        </>)
}
