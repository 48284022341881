import React from 'react'
import { Avatar } from 'antd'
import { IFloatButtomProps } from 'interfaces/Common/FloatButtom'
import styled from 'styled-components'
import CurarteIcon from '../Common/CurarteIcons'

const FixedWidgets = styled.div`
position: fixed;
right: 10px;
bottom: 90px;
z-index: 2147483640;
display: flex;
flex-direction: column;
cursor: pointer;
`
const AvatarFixed = {
  color: '#000',
  background: '#fff',
  boxShadow: '0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d',
  transition: 'color .3s'
}

export const FloatButtom = (props: IFloatButtomProps) => {
  const { onClick, type, visible, position } = props

  const display: string = visible ? 'initial' : 'none'

  const icon = type === 'View' ? <CurarteIcon name={'EyeFilled'} /> : type === 'Edit' ? <CurarteIcon name={'EditOutlined'} /> : <CurarteIcon name={'PlusOutlined'} />

  return <FixedWidgets style={{ display, bottom: position }}>
            <Avatar onClick={onClick} size={60} icon={icon} style={ AvatarFixed } />
        </FixedWidgets>
}
