import React from 'react'
import styled from 'styled-components'
import Sider from 'antd/lib/layout/Sider'
import MenuApp from './menu'
import { SidebarProps } from '.'
import { Button } from 'antd'
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons'

const StyledSider = styled(Sider)`
      box-shadow: rgb(204, 204, 204) 5px 0px 5px;
      position: fixed !important;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 999;
      .ant-layout-sider-children {
          display: flex;
          flex-direction: column;
          overflow: hidden;
      }
`
export interface DesktopSidebarProps extends SidebarProps {}

const CollapseContainer = styled.div`
    bottom: 0;
    height: 50px;
    background: rgba(255, 255, 255);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s ease-out;
`

const Desktop = (props: DesktopSidebarProps) => {
  const collapse = (
    <CollapseContainer onClick={props.onCollapse}>
        <Button type='text'>
        {!props.collapsed && (
          <DoubleRightOutlined />
        )}
        {props.collapsed && (
          <DoubleLeftOutlined />
        )}
        </Button>
    </CollapseContainer>
  )
  return (
    <StyledSider collapsible={true}
                 trigger={null}
                 collapsed={!props.collapsed}
                 onCollapse={props.onCollapse}
                 theme="light">
        <MenuApp
          inlineCollapsed={!props.collapsed}
        />
        {collapse}
    </StyledSider>
  )
}

export default Desktop
