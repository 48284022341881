import { OpenNotification } from '../components/Notification/notification'
import changePasswordServices from '../services/changePasswordServices'

const changePasswordRepositories = async (param: any, usuario: string) => {
  const password = param.password
  const newPassword = param.passwordNew
  if (!password || !newPassword) {
    return OpenNotification('Error', 'Completar Los Campos.', 'top', 'error')
  }
  return await changePasswordServices(param, usuario)
}

export default changePasswordRepositories
