import axios from 'axios'
import { authService } from './authService'

const patientServices = async (patien: string) => {
  authService()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/pacientes/${patien}`).then(res => res)
  return await api
}

export default patientServices
