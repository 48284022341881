import { FormOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import React, { useRef, useState } from 'react'
import { SaveAndCancelButton } from '../../components'
import SignaturePad from 'react-signature-canvas'
import { useIsMobile } from '../../services/responsive'
import { setIn } from 'timm'

export const Signature = (props: any) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const padRef = useRef<SignaturePad>(null)
  const ClearButton = (props?: any) => {
    return <Button {...props}>Limpiar</Button>
  }

  const styles = {
    border: '1px solid black'
  }
  return (<div>
             <h3 className='title-Hc'>Firma:</h3>
             <Button onClick={() => {
               padRef.current?.clear()
               setShowModal(!showModal)
             } } disabled={props.disabled}><FormOutlined /></Button>
             <Modal
                title={'Firma Profesional'}
                open={showModal}
                onCancel={() => setShowModal(!showModal)}
                onOk={() => setShowModal(!showModal)}
                closable={false}
                width={1000}
                maskClosable={false}
                key={props.index}
                footer={[
                    <ClearButton key={'clear'} onClick={() => padRef.current?.clear()}/>,
                    <SaveAndCancelButton key={'buttons'} disabled={false} clickedCancel={() => setShowModal(!showModal)} clickedSave={() => {
                      const url = padRef.current?.getTrimmedCanvas().toDataURL('image/png')
                      const newData = setIn(props.data, [props.index, 'firma'], url)
                      props.setData(newData)
                      setShowModal(!showModal)
                    }} />
                ]}
                >
                 <SignaturePad ref={padRef} key={props.index} canvasProps={{ height: '300', width: useIsMobile() ? '350' : '950', style: styles }} />
             </Modal>
              <img
              id='horaAtencion'
              className={'sigImage'}
              src={props.data[props.index].firma}
              />
        </div>)
}
