import { authService } from './authService'
import axios from 'axios'
import scaneoPost from '../interfaces/scaneo/scaneoPost'
import { OpenNotification } from '../components/Notification/notification'

export const tipoScaneoServices = async (values : any, valueYear: number, valueMonth: string) => {
  authService()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/TipoScaneo`)
  return await api
}

export const hcScaneoProfesionalServices = async (values : any, valueYear: number, valueMonth: string) => {
  const year = valueYear.toString()
  authService()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/HcScaneoProfesional?CodigoProfesional=${values.CodigoProfesional}&CodigoPaciente=${values.CodigoPacient}&Año=${year}&Mes=${valueMonth}&Limit=100&Offset=0`)
  return await api
}

export const getArchivoPatientServices = async (values : any, valueYear: number, valueMonth: string) => {
  authService()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/GetArchivo?codigoArchivo=${values.CodigoArchivo}&codigoPaciente=${values.CodigoPaciente}&codigoHc=${values.CodigoHc}&tipoArchivo=${values.TipoArchivo}&fechaCarga=${values.FechaCarga}&descripcion=${values.Descripcion}&codigoTipoScaneo=${values.CodigoTipoScaneo}&nombreArchivo=${values.NombreArchivo}`)
  return await api
}

export const getArchivoProfessionalServices = async (values : any, valueYear: number, valueMonth: string) => {
  authService()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/GetArchivo/${values.CodigoProfesional}?CodigoArchivo=${values.CodigoArchivo}&CodigoProfesional=${values.CodigoProfesional}&TipoArchivo=${values.TipoArchivo}&FechaCarga=${values.FechaCarga}&Descripcion=${values.Descripcion}&CodigoTipoScaneo=${values.CodigoTipoScaneo}&NombreArchivo=${values.NombreArchivo}`)
  return await api
}

export const archivoPatientServices = async (values : any, valueYear: number, valueMonth: string) => {
  authService()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/Archivo?CodigoProfesional=${values.codigoProfesional}`)
  return await api
}

export const archivoProfessionalServices = async (values : any, valueYear: number, valueMonth: string) => {
  authService()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/Archivo/${values.codigoProfesional}?CodigoProfesional=${values.codigoProfesional}`)
  return await api
}

export const postArchivo = async (params: scaneoPost) => {
  authService()
  const api = axios.post(`${process.env.REACT_APP_URI_BASE}/Archivo`, params).then(res => res && OpenNotification('Carga Realizada', 'Se ha realizado correctamente la carga.', 'top', 'success')).catch(() => OpenNotification('Error', 'No se pudo realizar la carga.', 'top', 'error'))
  return await api
}
export const deleteArchivoPatient = async (params: any) => {
  authService()
  const api = axios.delete(`${process.env.REACT_APP_URI_BASE}/Archivo?nombreArchivo=${params.nombreArchivo}&codigoArchivo=${params.codigoArchivo}&codigoEs=${params.codigoEs}&codigoHc=${params.codigoHc}&codigoPaciente=${params.codigoPaciente}&codigoProfesional=${params.codigoProfesional}&codigoTipoScaneo=${params.codigoTipoScaneo}&descripcion=${params.descripcion}&fechaCarga=${params.fechaCarga}&tipoArchivo=${params.tipoArchivo}`).then(res => res && OpenNotification('Eliminacion Realizada', 'Se ha realizado correctamente la eliminacion.', 'top', 'success')).catch(() => OpenNotification('Error', 'No se pudo realizar la eliminacion.', 'top', 'error'))
  return await api
}
export const deleteArchivoProfessional = async (params: any) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '[]')
  authService()
  const api = axios.delete(`${process.env.REACT_APP_URI_BASE}/Archivo/${userInfo.codigoProfesional}?nombreArchivo=${params.nombreArchivo}&codigoArchivo=${params.codigoArchivo}&codigoEs=${params.codigoEs}&codigoHc=${params.codigoHc}&codigoProfesional=${params.codigoProfesional}&codigoTipoScaneo=${params.codigoTipoScaneo}&descripcion=${params.descripcion}&fechaCarga=${params.fechaCarga}&tipoArchivo=${params.tipoArchivo}`).then(res => res && OpenNotification('Eliminacion Realizada', 'Se ha realizado correctamente la eliminacion.', 'top', 'success')).catch(() => OpenNotification('Error', 'No se pudo realizar la eliminacion.', 'top', 'error'))
  return await api
}
