import React, { useCallback, useEffect, useState } from 'react'
import '../../styles/scaneo.css'
import { Avatar, Button, DatePicker, Form, Input, Modal, Select, Skeleton, Space } from 'antd'
import { CameraOutlined, PaperClipOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import Webcam from 'react-webcam'
import { useFetchParams } from '../../interfaces'
import { PatientsRepositories } from '../../repositories'
import { OpenNotification } from '../Notification/notification'
import { archivoRepositoriesPost } from '../../repositories/scaneoRepositories'
import TipoScaneoCall from './TipoScaneoCall'
import HcScaneoProfesionalCall from './HcScaneoProfesional'
import SelectYearMonthScaneo from './SelectYearMonthScaneo'

const FixedWidgets = styled.div`
  position: fixed;
  right: 10px;
  bottom: 90px;
  z-index: 2147483640;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`
const AvatarFixed = {
  color: '#000',
  background: '#fff',
  boxShadow: '0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d',
  transition: 'color .3s'
}
const videoConstraintsMobile = {
  width: 580,
  height: 380,
  facingMode: 'environment'
}
const Scaneo = () => {
  localStorage.setItem('namePage', JSON.stringify('Nuevo Scaneo'))
  localStorage.setItem('numNav', JSON.stringify('1'))
  const userInfo = JSON.parse(localStorage.getItem('userInfo')!)
  const currentTime = new Date()
  const mes = (currentTime.getMonth() + 1).toString()
  const yearActual = currentTime.getFullYear()
  const [selectValueYear, setselectvalueYear] = useState(yearActual)
  const [selectValueMonth, setselectvalueMonth] = useState(mes)
  const [dataArchivo, setDataArchivo] = useState({})
  const [apiPatientData, setApiPatientData] = useState<any[]>()
  const [codPatient, setCodPatient] = useState<any>(null)
  const [valueTipoScaneo, setValueTipoScaneo] = useState<any>(null)
  const [valueDetalleHcScaneoProfesional, setValueDetalleHcScaneoProfesional] = useState<any>()
  const [valueDataHcScaneoProfesional, setValueDataHcScaneoProfesional] = useState<any>({ año: 0, codigoEntidad: 0, codigoHc: 0, codigoPaciente: 0, codigoProfesional: 0, detalle: '', mes: 0 })
  const [disableTipoScaneo, setDisableTipoScaneo] = useState<any>({ codigoPerioricidad: 0, codigoTipoScaneo: 0, descripcion: '', fechaAlta: null, grabaCodigoHc: null, obligatorio: '', usaAño: null, usaFechaVencimiento: null, usaMes: null, usaPaciente: null, usaProfesional: null, usuarioAlta: null })
  const [modalLoading, setModalLoading] = useState(true)
  const [reloadPost, setReloadPost] = useState<any>(0)

  const [isModalOpenUpLoading, setIsModalOpenUpLoading] = useState(false)
  const [isModalOpenCamera, setIsModalOpenCamera] = useState(false)
  const [displayValueCamera, setDisplayValueCamera] = useState('')
  const [displayValueUploading, setDisplayValueUploading] = useState('')
  const [loadingModal, setLoadingModal] = useState(false)
  const [fileUploadFile, setUploadFile] = useState<any>(null)
  const [fileUploadFileName, setUploadFileName] = useState<any>('')
  const [fileUploadFileType, setUploadFileType] = useState<any>('')
  const [nameImageCamera, setNameImageCamera] = useState<any>('')
  const [entityPatient, setEntityPatient] = useState<any>()
  const [imageSrc, setImageSrc] = useState(null)
  const [shotImage, setShotImage] = useState<any>(null)
  const [formUpLoading] = Form.useForm()
  const [formModalCamera] = Form.useForm()
  const [formSelects] = Form.useForm()

  const dataPatient = {
    codigoProfesional: userInfo.codigoProfesional
  }
  // Call patients

  const params: useFetchParams = { repositorieParam: PatientsRepositories, param: dataPatient, valueYear: selectValueYear, valueMonth: selectValueMonth }
  useEffect(() => {
    setModalLoading(true)
    formSelects.resetFields(['Select-Patient', 'Select-Tipo', 'Select-FechaVencimiento'])
    setCodPatient(null)
    const fecthPatient = async (values : useFetchParams) => {
      const data = await values.repositorieParam(values.param, values.valueYear, values.valueMonth)
      return data
    }
    fecthPatient(params).then((res) => {
      setApiPatientData(res.data.data !== undefined ? [res.data.data] : res.data)
      setModalLoading(false)
    })
  }, [selectValueYear, selectValueMonth])

  // Reload
  useEffect(() => {
    formSelects.resetFields(['Select-Patient', 'Select-MesAño', 'Select-Tipo', 'Select-FechaVencimiento'])
    setModalLoading(true)
    setDataArchivo({})
    setDataArchivo({ codigoTipoScaneo: valueTipoScaneo })
    setCodPatient(null)
    setTimeout(function () {
      setModalLoading(false)
    }, 1)
    setReloadPost(0)
    setUploadFile(null)
    setUploadFileName(null)
    setUploadFileType(null)
  }, [valueTipoScaneo, reloadPost])

  useEffect(() => {
    if (disableTipoScaneo.usaMes === 1 && disableTipoScaneo.usaAño === 1) {
      setDataArchivo({ ...dataArchivo, año: selectValueYear, mes: selectValueMonth })
    }
  }, [fileUploadFile, imageSrc])

  // Array Patients
  const patientsOptions: any[] = apiPatientData! && apiPatientData[0]!.map((e: any, index: number) => ({ value: e.codigoPaciente, label: e.nombrePaciente, entidad: e.codigoEntidadContratante }))
  const patients: any[] = apiPatientData! && apiPatientData[0]!.map((e: any, index: number) => e.codigoPaciente)
  const patientsOptionsOrder = patientsOptions && patientsOptions.slice().sort((a, b) => a.label.localeCompare(b.label))
  // Config Camera
  const captureImage = useCallback(() => {
    const webcam = webcamRef.current
    if (webcam) {
      const imageSrcShot = webcam.getScreenshot()
      if (imageSrcShot) {
        const canvas = document.createElement('canvas')
        const img = new Image()
        img.src = imageSrcShot
        img.onload = () => {
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext('2d')
          if (ctx) {
            ctx.drawImage(img, 0, 0)
            const imageData = canvas.toDataURL('image/png')
            setShotImage(imageData)
            const base64: any = imageData
            const base64Edit: any = base64.split(',')
            setImageSrc(base64Edit)
          }
        }
      }
    }
  }, [shotImage])

  const webcamRef: any = React.useRef()

  // Convert file to Base64
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileRender = new FileReader()
      fileRender.readAsDataURL(file)

      fileRender.onload = () => {
        const base64 : any = fileRender.result
        const base64Edit : any = base64.split(',')
        resolve(base64Edit[1])
      }

      fileRender.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleOnChangeUploadFile = async (e) => {
    const file = e.target.files[0]
    const base64 = await convertBase64(file)
    setUploadFile(base64)
    setUploadFileName(file.name)
    setUploadFileType(file.type)
  }

  // functions MODAL
  const showModalUpLoading = () => {
    setIsModalOpenUpLoading(true)
    setDisplayValueCamera('none')
  }
  const handleOkUpLoading = () => {
    setDataArchivo({ ...dataArchivo, nombreArchivo: fileUploadFileName, archivo1: fileUploadFile })
    setIsModalOpenUpLoading(false)
    setLoadingModal(false)
    setDisplayValueCamera('')
  }
  const showModalCamera = () => {
    setIsModalOpenCamera(true)
    setDisplayValueUploading('none')
  }
  const handleCancelUpLoading = () => {
    setIsModalOpenUpLoading(false)
    setDisplayValueCamera('')
    setUploadFile(null)
    setUploadFileName(null)
    setUploadFileType(null)
    formUpLoading.resetFields(['input'])
  }
  const handleOkCamera = () => {
    setDataArchivo({ ...dataArchivo, nombreArchivo: nameImageCamera, archivo1: imageSrc })
    setIsModalOpenCamera(false)
    setLoadingModal(true)
    setDisplayValueUploading('')
  }
  const handleCancelCamera = () => {
    setIsModalOpenCamera(false)
    setDisplayValueUploading('')
    formModalCamera.resetFields(['archiveCamera', 'archiveCamera-button'])
  }
  // const arrayTipo: any[] = apiTipoScaneo! && apiTipoScaneo[0]!.map((e: any, index: number) => ({ value: e.descripcion, label: e.descripcion, codigoPerioricidad: e.codigoPerioricidad, codigoTipoScaneo: e.codigoTipoScaneo, descripcion: e.descripcion, fechaAlta: e.fechaAlta, grabaCodigoHc: e.grabaCodigoHc, obligatorio: e.obligatorio, usaAño: e.usaAño, usaFechaVencimiento: e.usaFechaVencimiento, usaMes: e.usaMes, usaPaciente: e.usaPaciente, usaProfesional: e.usaProfesional, usuarioAlta: e.usuarioAlta }))

  // Function GetInformation
  const getInformation = async (e: any | string, type: string) => {
    if (type === 'paciente') {
      setCodPatient(e)
      patientsOptions!.map((a: any, index: number) => a.value === e ? setEntityPatient(a.entidad) : null)
      setDataArchivo({ ...dataArchivo, codigoPaciente: e })
      // setDataArchivo({ ...dataArchivo, codigoPaciente: e, año: selectValueYear, mes: parseInt(selectValueMonth) })
      formSelects.resetFields(['Select-Tipo'])
    }
    if (type === 'tipoScaneo') {
      setValueTipoScaneo(e)
      setDataArchivo({ ...dataArchivo, codigoTipoScaneo: e })
    }
    if (type === 'hcScaneoProfesional') {
      setValueDetalleHcScaneoProfesional(e)
      setDataArchivo({ ...dataArchivo, cantidadVisitas: e })
    }
    if (type === 'mes') {
      setselectvalueMonth(e)
      if (disableTipoScaneo.usaMes === 1) {
        setDataArchivo({ ...dataArchivo, mes: e })
      }
    }
    if (type === 'año') {
      setselectvalueYear(e)
      if (disableTipoScaneo.usaAño === 1) {
        setDataArchivo({ ...dataArchivo, año: e })
      }
    }
    if (type === 'fechaVencimiento') {
      const date = new Date(e)
      setDataArchivo({ ...dataArchivo, fechaVencimiento: date })
    }
  }

  // Post
  const post = async (data : any) => {
    if (!data.archivo1) {
      return OpenNotification('Tiene Datos Incompletos', 'Se debe Cargar un Archivo..', 'top', 'error')
    }
    const arrayTipoarchivo: any = fileUploadFileType.split('/')
    if (disableTipoScaneo.usaProfesional === 1 && disableTipoScaneo.usaPaciente !== 1) {
      return await (
        archivoRepositoriesPost({ ...data, tipoArchivo: arrayTipoarchivo[1], descripcion: disableTipoScaneo.descripcion, codigoHc: valueDataHcScaneoProfesional.codigoHc, codigoEntidad: disableTipoScaneo.grabaCodigoHc !== 1 ? entityPatient : valueDataHcScaneoProfesional.codigoEntidad, codigoProfesional: userInfo.codigoProfesional }),
        setReloadPost(1)
      )
    } else if (disableTipoScaneo.usaProfesional === 1 && disableTipoScaneo.usaPaciente === 1) {
      return await (
        archivoRepositoriesPost({ ...data, tipoArchivo: arrayTipoarchivo[1], descripcion: disableTipoScaneo.descripcion, codigoHc: valueDataHcScaneoProfesional.codigoHc, codigoEntidad: disableTipoScaneo.grabaCodigoHc !== 1 ? entityPatient : valueDataHcScaneoProfesional.codigoEntidad, codigoProfesional: userInfo.codigoProfesional }),
        setReloadPost(1)
      )
    } else if (disableTipoScaneo.usaProfesional !== 1 && disableTipoScaneo.usaPaciente === 1) {
      return await (
        archivoRepositoriesPost({ ...data, tipoArchivo: arrayTipoarchivo[1], descripcion: disableTipoScaneo.descripcion, codigoHc: valueDataHcScaneoProfesional.codigoHc, codigoEntidad: disableTipoScaneo.grabaCodigoHc !== 1 ? entityPatient : valueDataHcScaneoProfesional.codigoEntidad }),
        setReloadPost(1)
      )
    }
  }

  return (
    <div>

        <div className='Selects'>
        <Form form={formSelects} onFinish={() => post(dataArchivo)}>

          <SelectYearMonthScaneo year={selectValueYear} month={selectValueMonth} selectYear={function (e: any): void {
            getInformation(e, 'año')
          } } selectMonth={function (e: any): void {
            getInformation(e, 'mes')
          } } disabledSelectYear={disableTipoScaneo.usaAño !== 1} disabledSelectMonth={disableTipoScaneo.usaMes !== 1} />

           <TipoScaneoCall valueTipoScaneoProps={valueTipoScaneo} setDisableTipoScaneoProps={function (e: any): void {
             setDisableTipoScaneo(e)
           } } getInformationProps={function (e: any): void {
             getInformation(e, 'tipoScaneo')
           } } selectTipoScaneo={'selectTipoScaneo'}/>

          <Form.Item
            name={'Select-Patient'}
            rules={[{ required: disableTipoScaneo.usaPaciente === 1, message: 'Ingresar Paciente' }]}
          >
              <Select
               className='selectPaciente'
                  placeholder="Paciente"
               optionFilterProp="children"
               onChange={(e) => getInformation(e, 'paciente')}
               filterOption={(input, option) =>
                 (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
               options={patientsOptionsOrder}
               disabled={disableTipoScaneo.usaPaciente !== 1}
              />
           </Form.Item>

          {codPatient === null || codPatient === undefined || disableTipoScaneo.grabaCodigoHc !== 1
            ? null
            : <HcScaneoProfesionalCall codPatientProps={codPatient} year={selectValueYear} month={selectValueMonth} valueDetalleHcScaneoProfesionalProps={valueDetalleHcScaneoProfesional} setValueDataHcScaneoProfesionalProps={function (e: any): void {
              setValueDataHcScaneoProfesional(e)
            } } DisableTipoScaneoProps={disableTipoScaneo} getInformationProps={function (e: any): void {
              getInformation(e, 'hcScaneoProfesional')
            } } selectHcScaneoProfesional={'selectHcScaneoProfesional'} patient={patients}/>}

        <Space direction="vertical" style={{ width: '100%' }}>
         <Form.Item
            name={'Select-FechaVencimiento'}
            rules={[{ required: disableTipoScaneo.usaFechaVencimiento === 1, message: 'Ingresar Fecha Vencimiento' }]}
          >
            <DatePicker className='selectFechaVencimiento' disabled={disableTipoScaneo.usaFechaVencimiento !== 1} placeholder={'Fecha Vencimiento'} onChange={(e) => getInformation(e, 'fechaVencimiento')} />
          </Form.Item>
        </Space>
        <div className='div-ButtonCargarScaneo'>
           <Button className='button-CargarScaneo' type="primary" htmlType="submit">Guardar</Button>
        </div>
        </Form>
        </div>
        <div>
        <Skeleton loading={modalLoading} active>

        {
          isModalOpenUpLoading
            ? null
            : <FixedWidgets style={{ display: displayValueUploading }}>
                <Avatar onClick={() => valueTipoScaneo !== null ? showModalUpLoading() : OpenNotification('Tipo de Scaneo Nulo', 'Se debe seleccionar anteriormente el tipo de Scaneo', 'top', 'error')} size={60} icon={<PaperClipOutlined />} style={ AvatarFixed } />
              </FixedWidgets>
        }
        {
          isModalOpenCamera
            ? null
            : <FixedWidgets style={{ bottom: '160px', display: displayValueCamera }}>
                <Avatar onClick={() => valueTipoScaneo !== null ? showModalCamera() : OpenNotification('Tipo De Scaneo Nulo', 'Se debe seleccionar anteriormente el tipo de Scaneo', 'top', 'error')} size={60} icon={<CameraOutlined />} style={ AvatarFixed } />
              </FixedWidgets>
        }
        <Modal
        className='modalUpLoading'
        title="Subir Archivo"
        open={isModalOpenUpLoading}
        onOk={handleOkUpLoading}
        onCancel={handleCancelUpLoading}
        confirmLoading={loadingModal}
      >
        <Form form={formUpLoading} className='modalFormUploading'>
             <div className='inputFile-select'>
                <Input className='inputFile' type="file" onChange={(e) => handleOnChangeUploadFile(e)} />
             </div>
             <p>{fileUploadFileName}</p>
             <p>{fileUploadFileType}</p>
        </Form>
        </Modal>
        <Modal
        title="Sacar Foto"
        open={isModalOpenCamera}
        onOk={handleOkCamera}
        onCancel={handleCancelCamera}
        confirmLoading={loadingModal}
        footer={[
          <Button key="back" onClick={handleCancelCamera}>
            Volver
          </Button>
        ]}
      >
        <Form form={formModalCamera} className='modalFormCamera' onFinish={handleOkCamera}>
            <Form.Item
                name="archiveCamera"
                rules={[{ required: true, message: 'Ingresar Nombre Archivo' }]}
            >
                <Input placeholder="Ingresar Nombre Archivo" onChange={(e) => setNameImageCamera(e)} />
            </Form.Item>
            <Webcam
            audio={false}
            height={420}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={280}
            videoConstraints={ videoConstraintsMobile }
          />
          <Form.Item
             name="archiveCamera-button"
             rules={[{ required: true, message: 'Debes sacar una foto' }]}
          >
             <Button className='button-camera' onClick={() => captureImage}>Capturar</Button>
          </Form.Item>
          <div>
          <img src={shotImage} width="580" height="300" alt="image"/>
          </div>
          <div>
             <Button className='button-camera-guardar' type="primary" htmlType="submit">Guardar</Button>
          </div>
        </Form>
        </Modal>
        </Skeleton>
        </div>
    </div>
  )
}
export default Scaneo
