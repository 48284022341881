import axios from 'axios'
import { authService } from './authService'
import { UserInfo } from 'interfaces/Common'

const patientsServices = async (values: UserInfo, valueYear: number, valueMonth: string) => {
  const year = valueYear.toString()
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '[]')
  authService()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/pacientes?CodigoProfesional=${userInfo.codigoProfesional}&A%C3%B1o=${year}&Mes=${valueMonth}&Limit=9999999&Offset=0`).then(res => res)
  return await api
}

export default patientsServices
