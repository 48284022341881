import React from 'react'
import { TimePicker } from 'antd'
import 'moment/locale/es-us'
import locale from 'antd/lib/date-picker/locale/es_ES'
import '../../styles/InputTime.css'

// .ant-picker-now {
//   display: none;
// }

const disabledDateTime = (bloquearDuracion: number) => ({
  disabledHours: () => bloquearDuracion === 0 ? [...Array(24).keys()].slice(4, 24) : [],
  disabledMinutes: (selectedHour: number) => {
    return bloquearDuracion === 0 ? selectedHour === 3 ? [...Array(60).keys()].slice(1, 60) : [] : []
  },
})

const InputTime = (props: any) => {
  return <TimePicker locale={locale} inputReadOnly={true} disabledTime={() => disabledDateTime(props.bloquearDuracion)} {...props} popupClassName={'timerPickerVGClass'} />
}

export default InputTime
