import React from 'react'
import { MoreOutlined } from '@ant-design/icons/lib/icons'
import { Dropdown, MenuProps } from 'antd'
import { IMoreOptions } from '@interfaces-components/moreOptions'

export const MoreOptions = (props: IMoreOptions) => {
  const items: MenuProps['items'] = props.items

  return (
  <Dropdown menu={{ items }} arrow trigger={['click']}>
          <MoreOutlined style={{ fontSize: 25, fontWeight: 'bold' }}/>
  </Dropdown>
  )
}
