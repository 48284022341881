import { archivoPatientServices, archivoProfessionalServices, deleteArchivoPatient, deleteArchivoProfessional, getArchivoPatientServices, getArchivoProfessionalServices, hcScaneoProfesionalServices, postArchivo, tipoScaneoServices } from '../services/scaneoService'

export const tipoScaneoRepositories = async (values:any, valueYear: number, valueMonth: string) => {
  return await tipoScaneoServices(values, valueYear, valueMonth)
}

export const hcScaneoProfesionalRepositories = async (values:any, valueYear: number, valueMonth: string) => {
  return await hcScaneoProfesionalServices(values, valueYear, valueMonth)
}

export const getArchivoPatientRepositories = async (values:any, valueYear: number, valueMonth: string) => {
  return await getArchivoPatientServices(values, valueYear, valueMonth)
}
export const getArchivoProfessionalRepositories = async (values:any, valueYear: number, valueMonth: string) => {
  return await getArchivoProfessionalServices(values, valueYear, valueMonth)
}

export const archivoPatientRepositories = async (values:any, valueYear: number, valueMonth: string) => {
  return await archivoPatientServices(values, valueYear, valueMonth)
}

export const archivoProfessionalRepositories = async (values:any, valueYear: number, valueMonth: string) => {
  return await archivoProfessionalServices(values, valueYear, valueMonth)
}

export const archivoRepositoriesDeletePatient = async (params : any) => {
  const data: any = {
    codigoArchivo: params.CodigoArchivo,
    codigoPaciente: params.CodigoPaciente,
    codigoProfesional: params.CodigoProfesional,
    codigoEs: params.CodigoEs,
    codigoHc: params.CodigoHc,
    tipoArchivo: params.TipoArchivo,
    fechaCarga: params.FechaCarga,
    descripcion: params.Descripcion,
    codigoTipoScaneo: params.CodigoTipoScaneo,
    nombreArchivo: params.NombreArchivo
  }
  return await deleteArchivoPatient(data)
}

export const archivoRepositoriesDeleteProfessional = async (params : any) => {
  const data: any = {
    codigoArchivo: params.CodigoArchivo,
    codigoProfesional: params.CodigoProfesional,
    codigoEs: params.CodigoEs,
    codigoHc: params.CodigoHc,
    tipoArchivo: params.TipoArchivo,
    fechaCarga: params.FechaCarga,
    descripcion: params.Descripcion,
    codigoTipoScaneo: params.CodigoTipoScaneo,
    nombreArchivo: params.NombreArchivo
  }
  return await deleteArchivoProfessional(data)
}

export const archivoRepositoriesPost = async (params : any) => {
  const dateNow = new Date()
  const formatDate4 = async (e: Date) => {
    const month = ('00' + ((e.getMonth() + 1)))
    const day = ('00' + (e.getDate()))
    const hour = ('00' + (e.getHours()))
    const minute = ('00' + (e.getMinutes()))
    const seconds = ('00' + (e.getSeconds()))
    const formattedDate2 = e.getFullYear() + '-' + month.substring(month.length, month.length - 2) + '-' + day.substring(day.length, day.length - 2) + 'T' + hour.substring(hour.length, hour.length - 2) + ':' + minute.substring(minute.length, minute.length - 2) + ':' + seconds.substring(seconds.length, seconds.length - 2)
    return formattedDate2
  }
  const date = await formatDate4(dateNow)
  const data: any = {
    codigoArchivo: 0,
    mes: params.mes,
    año: params.año,
    codigoPaciente: params.codigoPaciente,
    codigoProfesional: params.codigoProfesional,
    codigoEs: 0,
    codigoHc: params.codigoHc,
    tipoArchivo: params.tipoArchivo,
    archivo1: params.archivo1,
    cantidadVisitas: 0,
    fechaCarga: date,
    descripcion: params.descripcion,
    fechaVencimiento: params.fechaVencimiento,
    codigoEntidad: params.codigoEntidad,
    codigoTipoScaneo: params.codigoTipoScaneo,
    nombreArchivo: params.nombreArchivo
  }
  return await postArchivo(data)
}
