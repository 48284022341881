import { Form, Select } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

export interface HcScaneoProfesionalCallInterfece {
    codPatientProps: number
    year: number
    patient: any[]
    month: string
    valueDetalleHcScaneoProfesionalProps: any
    setValueDataHcScaneoProfesionalProps: (e: React.SetStateAction<any>) => void
    DisableTipoScaneoProps: any
    getInformationProps: (e: any) => void
    selectHcScaneoProfesional: string
  }

const HcScaneoProfesionalCall = (props:HcScaneoProfesionalCallInterfece) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo')!)
  const [apiHcScaneoProfesional, setApiHcScaneoProfesional] = useState<any[]>()

  const callHcScaneoProfesional = () => {
    const dataHcScaneoProfesionalPush :any = []
    props.patient && props.patient.map((e: any, index: number) => {
      if (e === props.codPatientProps) {
        const fecthHcScaneoProfesional = async () => {
          const data = await axios.get(`${process.env.REACT_APP_URI_BASE}/HcScaneoProfesional?CodigoProfesional=${userInfo.codigoProfesional}&CodigoPaciente=${e}&Año=${props.year}&Mes=${props.month}&Limit=100&Offset=0`)
          dataHcScaneoProfesionalPush.push(data.data)
          return dataHcScaneoProfesionalPush
        }

        fecthHcScaneoProfesional().then((res) => {
          setApiHcScaneoProfesional(res !== undefined ? [res] : res)
        })
      }
      return apiHcScaneoProfesional
    })
  }

  useEffect(() => {
    callHcScaneoProfesional()
  }, [props.codPatientProps])

  const detalleHcScaneoProfesional: any[] = []
  apiHcScaneoProfesional && apiHcScaneoProfesional[0]?.map((ee: any, index: number) => ee.data.map((e: any, index2: number) => detalleHcScaneoProfesional.push(({ value: e.detalle, label: e.detalle }))))

  useEffect(() => {
    apiHcScaneoProfesional && apiHcScaneoProfesional[0]?.filter((ee: any, index: number) => ee.data.map((e: any, index2: number) => props.valueDetalleHcScaneoProfesionalProps === e.detalle ? props.setValueDataHcScaneoProfesionalProps({ año: e.año, codigoEntidad: e.codigoEntidad, codigoHc: e.codigoHc, codigoPaciente: e.codigoPaciente, codigoProfesional: e.codigoProfesional, detalle: e.detalle, mes: e.mes }) : null))
  }, [props.valueDetalleHcScaneoProfesionalProps])
  return (
      <Form.Item
            name={'Select-Tipo'}
            rules={[{ required: props.DisableTipoScaneoProps.usaPaciente === 1, message: 'Ingresar Cantidad De Visitas' }]}
          >
            <Select
            className={props.selectHcScaneoProfesional}
            placeholder="Práctica Médica"
            optionFilterProp="children"
            onChange={(e) => props.getInformationProps(e)}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={detalleHcScaneoProfesional}
            disabled={props.DisableTipoScaneoProps.usaPaciente !== 1}
          />
      </Form.Item>

  )
}
export default HcScaneoProfesionalCall
