import { CheckOutlined } from '@ant-design/icons'
import { Radio } from 'antd'
import React from 'react'

export const Geolocation = (props: any) => {
  return (<div>
            <h3 className='title-Hc'>Geolocalizacion:</h3>
            <div className='button-geolocation'>
                <div className='button-geolocation-entrada'>
                    <Radio.Group buttonStyle="solid" defaultValue={props.defaultValueEntrada}>
                        <Radio.Button value="entrada" disabled={props.Edited} {...props} > { !props.defaultValueEntrada ? <></> : <CheckOutlined />} Entrada</Radio.Button>
                    </Radio.Group>
                </div>
                <div className='button-geolocation-salida'>
                    <Radio.Group buttonStyle="solid" defaultValue={props.defaultValueSalida}>
                        <Radio.Button value="salida" disabled={props.Edited} {...props} > { !props.defaultValueSalida ? <></> : <CheckOutlined />} Salida</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
      </div>)
}
