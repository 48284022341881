import React, { useContext, useEffect, useState } from 'react'
import { Button, Collapse, Empty, Skeleton, Space } from 'antd'
import { getClinicHistory, clinicHistoryRepoPut } from '../../repositories'
import { Geolocation } from './location'
import { InputTime, SaveAndCancelButton, OpenNotification } from '../../components'
import moment from 'moment'
import TextArea from 'antd/lib/input/TextArea'
import { BsCalendarCheckFill, BsFillCalendar2XFill } from 'react-icons/bs'
import { setIn } from 'timm'
import { ClinicHistoryPut } from '../../interfaces'
import { Signature } from './signature'
import { AuthLoginContext } from '../../services/authContextService'
import { VisitPeriod } from './visitPeriod'

interface Detalle {
  codigoHc: number
  fechaVisita: string
  usuarioModifica: number
  fechaModifica: string
  fechaInicio: string
  fechaFin:string
  codigoArchivo: number
  entradaLongitud: string
  entradaLatitud: string
  entradaFechaCarga: string
  codigoLinea: number
  fechaHoraVisita: string
  tiempoAtencion: string
  detalle: string
  salidaFechaCarga: string
  salidaLatitud: string
  salidaLongitud: string
  firma: string
}

export const DetailsHC = (props: any) => {
  const { Panel } = Collapse
  const [data, setData] = useState<Detalle[]>()
  const [cancel, setCancel] = useState<boolean>(true)
  const [loading, setLoading] = useState(false)
  const numberPatient = parseInt(window.location.toString().split('/')[4])
  const userContext = useContext(AuthLoginContext)

  useEffect(() => {
    setLoading(true)
    const fecth = async () => {
      return await getClinicHistory({
        profesionalCode: userContext.userInfo.codigoProfesional,
        patientCode: numberPatient,
        user: userContext.userInfo.nombreUsuario,
        role: 0,
        numAllHc: 0
      }, parseInt(props.year), props.month)
    }

    fecth().then((res) => {
      const data = res?.data.data
      setLoading(false)
      setData(data)
    })
  }, [props.year, props.month, cancel])

  const ButtonEdit = (props: any) => {
    const currentDate = moment()
    const currentYear = currentDate.year()
    const currentPeriod = (currentDate.month() + 1)

    /** Valido hasta que dia del mes puedo modificar HC viejas */
    const daysEnabled = (moment().date() <= 15)
    /** Valido el periodo de la hc y tambien si estoy dentro del rando de dias para modificarla */
    const enabledOldHC = daysEnabled && parseInt(props.mes) === ((currentPeriod - 1) === 0 ? 12 : (currentPeriod - 1))
    /** Valido si las hc si son del periodo actual */
    const currentPeriodEnabled = parseInt(props.mes) === (currentPeriod) && parseInt(props.año) === (currentYear)
    return currentPeriodEnabled || enabledOldHC ? <Button disabled={!props.Edited} onClick={props.clicked} type='primary'>Editar</Button> : <></>
  }

  const FillCalendarX = (props: any) => {
    const calendarActive = !props.fechaHoraVisita && !props.comment
    return calendarActive ? <BsFillCalendar2XFill style={{ fontSize: '30px', color: '#f00c0c' }} /> : <BsCalendarCheckFill style={{ fontSize: '30px', color: '#2196f3' }} />
  }

  const onEdit = () => {
    props.Editing.setEdited(!props.Editing.edited)
  }

  const onCancel = () => {
    props.Editing.setEdited(true)
    setCancel(!cancel)
  }
  const onSave = async (dataPutHC: ClinicHistoryPut) => {
    if (!dataPutHC.fechaVisita) {
      return OpenNotification('Tiene Datos Incompletos', 'Se deben completar todos los datos Fecha de la visita...', 'top', 'error')
    }
    if (dataPutHC.entradaFechaCarga && dataPutHC.salidaFechaCarga && (!dataPutHC.detalle || !dataPutHC.tiempoAtencion)) {
      return OpenNotification('Tiene Datos Incompletos', 'Se deben completar todos los datos (Tiempo, Comentario)...', 'top', 'error')
    }
    if (dataPutHC.entradaFechaCarga && dataPutHC.salidaFechaCarga && (!dataPutHC.detalle || dataPutHC.detalle.length < 6)) {
      return OpenNotification('Comentario Corto', 'Debe ingresar un comentario con un minimo de 6 caracteres.', 'top', 'error')
    }
    const response = await clinicHistoryRepoPut(dataPutHC)
    if (response?.status === 204) onCancel()
  }
  const onReset = async (dataPutHC: ClinicHistoryPut) => {
    const response = await clinicHistoryRepoPut({
      ...dataPutHC,
      firma: null,
      entradaLatitud: null,
      entradaLongitud: null,
      entradaFechaCarga: null,
      salidaFechaCarga: null,
      salidaLatitud: null,
      salidaLongitud: null,
      detalle: null,
      fechaVisita: null,
      tiempoAtencion: null
    })
    if (response?.status === 204) onCancel()
  }
  return (<>
  <Skeleton loading={loading}>
    <Collapse accordion expandIconPosition="start">
        {
          data?.map((detalle: Detalle, index: number) =>
            <Panel
              header={`${detalle.codigoLinea} ${!detalle.fechaHoraVisita ? 'Visita No Realizada' : detalle.fechaHoraVisita}`}
              key={index}
              extra={<FillCalendarX fechaHoraVisita={detalle.fechaHoraVisita} comment={detalle.detalle} />}
              collapsible={!props.Editing.edited ? 'disabled' : 'header'}>
              <div className="clinicHistory--container">
                <ButtonEdit {...detalle}{...props} Edited={props.Editing.edited} clicked={onEdit}/>
                <Geolocation
                  Edited={props.Editing.edited}
                  defaultValueEntrada={ !detalle.entradaFechaCarga ? '' : 'entrada' }
                  defaultValueSalida={ !detalle.salidaFechaCarga ? '' : 'salida' }
                  onChange={async (e) => {
                    const geoSelect = e.target.value
                    if (geoSelect === 'entrada') {
                      const newDateHC = setIn(data, [index, 'entradaFechaCarga'], new Date()) as Detalle[]
                      if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(async (position) => {
                          const newLat = setIn(newDateHC, [index, 'entradaLatitud'], String(position.coords.latitude)) as Detalle[]
                          const newLon = setIn(newLat, [index, 'entradaLongitud'], String(position.coords.longitude)) as Detalle[]
                          setData(newLon)
                        })
                      }
                    } else if (geoSelect === 'salida') {
                      const newDateHC = setIn(data, [index, 'salidaFechaCarga'], new Date()) as Detalle[]
                      if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(async (position) => {
                          const newLat = setIn(newDateHC, [index, 'salidaLatitud'], String(position.coords.latitude)) as Detalle[]
                          const newLon = setIn(newLat, [index, 'salidaLongitud'], String(position.coords.longitude)) as Detalle[]
                          setData(newLon)
                        })
                      }
                    }
                  }}/>
                  <VisitPeriod
                    key={props.Index}
                    disabled={props.Editing.edited}
                    defaultValue={ !detalle.fechaHoraVisita ? '' : moment(detalle.fechaHoraVisita, 'YYYY-MM-DD HH:mm')}
                    dateInicio={moment(detalle.fechaInicio)}
                    dateFin={moment(detalle.fechaFin)}
                    onChange={(date) => {
                      // console.log(date)
                      const newDateHC = setIn(data, [index, 'fechaVisita'], date._d) as Detalle[]
                      setData(newDateHC)
                    }}
                  />
                {/* <div className='edit'>
                  <div>
                    <h3 style={{ marginBottom: '11px' }}>Fecha/Hora de la Visita:</h3>
                    { !detalle.fechaHoraVisita
                      ? <CalendarHC key={props.Index} dateInicio={detalle.fechaInicio} dateFin={detalle.fechaFin} notShowTime={false} onChange={(date) => {
                        const newDateHC = setIn(data, [index, 'fechaVisita'], date._d) as Detalle[]
                        setData(newDateHC)
                      }
                    }/>
                      : <CalendarHC key={props.Index} defaultValue={moment(detalle.fechaHoraVisita, 'YYYY-MM-DD HH:mm')} disabled={props.Editing.edited} onChange={(date) => {
                        const newDateHC = setIn(data, [index, 'fechaVisita'], date._d) as Detalle[]
                        setData(newDateHC)
                      }
                    }/>}
                  </div>
                </div> */}

                <div className='TimeSpam'>
                  <h3 className='title-Hc'>Tiempo de atencion:</h3>
                  <Space direction="vertical">
                    <InputTime disabled={props.Editing.edited} format={'HH:mm'} defaultValue={moment((detalle.tiempoAtencion), 'HH:mm')} onChange={(time) => {
                      const TimeDate = `${(new Date(time._d)).toLocaleTimeString().substring(0, 5)}:00`
                      const newTimeHC = setIn(data, [index, 'tiempoAtencion'], TimeDate) as Detalle[]
                      setData(newTimeHC)
                    }}/>
                  </Space>
                </div>

                <div className='CommentsHC'>
                  <h3 className='title-Hc'>Comentario:</h3>
                  <TextArea
                    defaultValue={detalle.detalle}
                    disabled={props.Editing.edited}
                    id='comentario'
                    showCount
                    minLength={15}
                    rows={5}
                    placeholder="Comments.."
                    readOnly={false}
                    onChange={(e) => {
                      const newComment = setIn(data, [index, 'detalle'], e.target.value) as Detalle[]
                      setData(newComment)
                    }}/>
                </div>
                <Signature disabled={props.Editing.edited} index={index} data={data} setData={setData}/>

                <SaveAndCancelButton
                  disabled={props.Editing.edited}
                  clickedCancel={onCancel}
                  clickedSave={() => onSave(data[index])}
                  clickExtraButton={() => onReset(data[index])}
                  extraButtonHc={ (!!detalle.detalle && (!data[index + 1] || !data[index + 1].detalle)) }
                  extraClass={'Button-guardar-cancelar'}/>
              </div>
            </Panel>
          )
        }
    </Collapse>
    { !data || data.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <></>}
  </Skeleton>
</>)
}
