import { useEffect, useState } from 'react'

export interface responseGeo {
    status: string
    message: string
    extra: any
}

export const useGeolocation = () => {
  const [res, setRes] = useState<responseGeo>({
    status: '',
    message: '',
    extra: ''
  })
  useEffect(() => {
    if ('geolocation' in navigator) {
      // Verificar si el usuario ha dado permiso para acceder a la ubicación
      navigator.geolocation.getCurrentPosition((position) => {
        // El usuario ha dado permiso y la ubicación está activada
        setRes({
          message: 'Ubicación activada',
          status: 'GEO_ACTIVE',
          extra: null
        })
      }, (error) => {
        // El usuario ha denegado el acceso a la ubicación o ha ocurrido un error
        switch (error.code) {
          case error.PERMISSION_DENIED:
            setRes({
              message: 'El usuario ha denegado el acceso a la ubicación. Se debe activar la geolocalizacion para poder grabar los datos.',
              status: 'PERMISSION_DENIED',
              extra: null
            })
            break
          case error.POSITION_UNAVAILABLE:
            setRes({
              message: 'La ubicación no está disponible.',
              status: 'POSITION_UNAVAILABLE',
              extra: null
            })
            break
          case error.TIMEOUT:
            setRes({
              message: 'Tiempo de espera agotado al intentar obtener la ubicación.',
              status: 'TIMEOUT',
              extra: null
            })
            break
          default:
            setRes({
              message: '',
              status: 'UNKNOWN_ERROR',
              extra: error
            })
            break
        }
      })
    } else {
      setRes({
        message: 'La API de geolocalización no está disponible en este navegador.',
        status: 'GEO_NO_ACTIVE',
        extra: null
      })
    }
  }, [])
  // La API de geolocalización está disponible
  return res
}
