import { getAnamnesisServices, postAnamnesisServices, putAnamnesisServices, deleteAnamnesis } from '../services/anamnesisServices'

export const getAnamnesisRepositories = async (values: any) => {
  return await getAnamnesisServices(values)
}
export const postAnamnesisRepositories = async (value: any) => {
  return await postAnamnesisServices(value)
}

export const putAnamnesisRepositories = async (value: any) => {
  return await putAnamnesisServices(value)
}
export const anamnesisRepoDelete = async (params : any) => {
  return await deleteAnamnesis(params)
}
