import axios from 'axios'
import { authService } from './authService'
import { IParametersServices } from 'interfaces'
import { IAgendaRepository } from '../repositories/agendaRepository'

interface IAgendaServices extends IAgendaRepository, IParametersServices {}

export const getAgendaList = async (parameters: IAgendaServices) => {
  authService()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/Agenda/${parameters.codiogProfesional}?FechaDesde=${parameters.dateStart.format('YYYY-MM-DD 00:00:00')}&FechaHasta=${parameters.dateEnd.format('YYYY-MM-DD 23:59:59')}&limit=${parameters.limit ? parameters.limit : 999}&offset=${parameters.offset ? parameters.offset : 0}`).then(res => res)
  return await api
}
