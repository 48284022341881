import { Card, Statistic } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

export interface DevelopmentsCallInterfece {
    year: number
    month: string
    loading: boolean
    setLoading: (e: React.SetStateAction<boolean>) => void
    setpatient: any[] | undefined
    patient: any[]
    codigoProfesional: number
  }

  interface PacienteInfo {
    paciente: number;
    Hc: number;
  }

const DevelopmentsCall = (props:DevelopmentsCallInterfece) => {
  const [apiDevelopmentsData, setApiDevelopmentsData] = useState<any[]>()
  const [apiHistoryData, setApiHistoryData] = useState<any[]>()

  // Developments call
  const callDevelopments = () => {
    const dataDevelopmentsPush :any = []
    props.patient && props.patient.map((e: any, index: number) => {
      const fecthDevelopments = async () => {
        const data = await axios.get(`${process.env.REACT_APP_URI_BASE}/Evoluciones?CodigoPaciente=${e}&Año=${props.year}&Mes=${props.month}&Limit=9999999&Offset=0`)
        dataDevelopmentsPush.push(data.data)
        return dataDevelopmentsPush
      }
      fecthDevelopments().then((res) => {
        setApiDevelopmentsData(res !== undefined ? [res] : res)
      })
      return apiDevelopmentsData
    })
  }

  // HC call
  const callHistory = () => {
    const fecthHistory = async () => {
      const data = await axios.get(`${process.env.REACT_APP_URI_BASE}/HistoriaClinica/${props.codigoProfesional}?CodigoProfesional=${props.codigoProfesional}&Año=${props.year}&Mes=${props.month}&Limit=9999999&Offset=0&Allhc=0`)
      return data.data
    }
    fecthHistory().then((res) => {
      setApiHistoryData(res !== undefined ? [res] : res)
    })
  }

  useEffect(() => {
    callDevelopments()
    callHistory()
  }, [props.year, props.month, props.setpatient])

  // Manejo de datos Developments
  const dataDevelopmentsFinal : any = []
  const dataGlobalDevelopments = () => {
    const count = apiDevelopmentsData && apiDevelopmentsData[0]?.map((e: any, index: number) => e.count)
    const sumQuantity = count?.reduce((count: any, countNext: any) => count + countNext, 0)
    dataDevelopmentsFinal.push(sumQuantity)
  }

  // Manejo de datos HC
  const countDataHcScaneada : any = []
  const dataHistory = () => {
    apiHistoryData && apiHistoryData.map((e: any, index: number) => e.data.map((ee: any, index2: number) => countDataHcScaneada.push({ paciente: ee.codigoPaciente, Hc: ee.hcScaneada })))
  }

  dataHistory()

  function agruparPorPaciente (arr) {
    const mapa = new Map()

    arr.forEach(obj => {
      const paciente = obj.paciente

      if (mapa.has(paciente)) {
        mapa.get(paciente).push(obj)
      } else {
        mapa.set(paciente, [obj])
      }
    })

    return Array.from(mapa.values())
  }

  const arrayHc = agruparPorPaciente(countDataHcScaneada)

  function eliminarObjetosRepetidos (array: Array<Array<any>>): Array<Array<any>> {
    return array.map(subarray => {
      return subarray.filter((objeto, indice, array) => {
        return indice === array.findIndex(obj => {
          return JSON.stringify(obj) === JSON.stringify(objeto)
        })
      })
    })
  }

  const hcArrayClean = eliminarObjetosRepetidos(arrayHc)

  function reduceHcInfo (info: PacienteInfo[][]): PacienteInfo[] {
    const pacientesMap = new Map<number, { Hc: number }>()

    info.forEach((pacienteInfo: PacienteInfo[]) => {
      pacienteInfo.forEach((obj: PacienteInfo) => {
        const { paciente, Hc } = obj

        const pacienteData = pacientesMap.get(paciente) ?? { Hc: 1 }
        pacienteData.Hc = pacienteData.Hc === 0 || Hc === 0 ? 0 : 1
        pacientesMap.set(paciente, pacienteData)
      })
    })

    const pacientesArray: PacienteInfo[] = []

    pacientesMap.forEach((pacienteData, paciente) => {
      pacientesArray.push({ paciente, Hc: pacienteData.Hc })
    })

    return pacientesArray
  }

  const hcArray = reduceHcInfo(hcArrayClean)

  const hcScaneadaData : any = []
  const dataHistory1 = () => {
    const countDataHcScaneada1 : any = []
    hcArray && hcArray.map((e: any, index: number) => e.Hc === 0 ? countDataHcScaneada1.push(1) : null)

    const hcScaneada = countDataHcScaneada1?.reduce((count: any, countNext: any) => count + countNext, 0)
    hcScaneadaData.push(hcScaneada)
  }
  dataHistory1()
  dataGlobalDevelopments()
  const gridStyle: React.CSSProperties = {
    padding: '12px',
    textAlign: 'center'
  }
  return (
    <Card className="card" title="Pacientes">
      <Card.Grid style={gridStyle}>
        <Statistic className='asignados' title="Asignados" value={props.patient.length} />
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <Statistic className='asignados' title="S/Evolucion" value={dataDevelopmentsFinal} />
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <Statistic className='asignados' title="S/HC Sin Escanear" value={hcScaneadaData} />
      </Card.Grid>
    </Card>
  )
}
export default DevelopmentsCall
