import { Card, Statistic } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

export interface NewsCallInterfece {
    year: number
    month: string
    loading: boolean
    setLoading: (e: React.SetStateAction<boolean>) => void
    setpatient: any[] | undefined
    patient: any[]
  }

const NewsCall = (props:NewsCallInterfece) => {
  const [apiNewsData, setApiNewsData] = useState<any[]>()

  const callNews = () => {
    const dataNewsPush :any = []
    props.patient && props.patient.map((e: any, index: number) => {
      const fecthNews = async () => {
        const data = await axios.get(`${process.env.REACT_APP_URI_BASE}/novedades?mes=${props.month}&año=${props.year}&codigopaciente=${e}&limit=9999999&offset=0`)
        dataNewsPush.push(data.data)
        return dataNewsPush
      }
      fecthNews().then((res) => {
        setApiNewsData(res !== undefined ? [res] : res)
      })
      return apiNewsData
    })
  }

  useEffect(() => {
    callNews()
  }, [props.year, props.month, props.setpatient])

  // Manejo de datos
  const dataNewsArray : any = []

  const dataNews = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '[]')
    const dateLoginLocal = localStorage.getItem('dateLogin')
    const dateLoginLocalClearSlice = dateLoginLocal?.slice(1, -1)
    const dateOptional = new Date(dateLoginLocalClearSlice!)
    const newsArray : any = []

    apiNewsData && apiNewsData[0]?.map((e: any, index: number) => e.data.map((ee: any, index2: number) => ee.fechaNovedad !== null && userInfo.codigoUsuario !== ee.usuarioAlta && new Date(ee.fechaNovedad) > dateOptional ? newsArray.push(ee.codigoEntidad) : null))

    const news = newsArray?.filter(el => el).length
    dataNewsArray.push(news)
  }

  const dataGlobalNews = () => {
    dataNews()
  }
  dataGlobalNews()
  const gridStyle: React.CSSProperties = {
    padding: '12px',
    textAlign: 'center'
  }
  return (
    <Card className="card" title="Novedades">
      <Card.Grid style={gridStyle}>
        <Statistic className='nuevasNews' title="Nuevas" value={dataNewsArray} />
      </Card.Grid>
    </Card>
  )
}
export default NewsCall
