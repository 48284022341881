import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import '../styles/home.css'
import { UserInfo, useFetchParams } from '../interfaces'
import { PatientsRepositories } from '../repositories'
import NewsCall from '../components/Home/newsCall'
import DevelopmentsCall from '../components/Home/DevelopmentsCall'
import BillingCall from '../components/Home/billingCall'
import ClinicHistoryCall from '../components/Home/clinicHistoryCall'
import SelectorYearMonth from '../components/Inputs/SelectorYearMonth'
import { useAuthentication } from 'services/authContextService'
import { useHeaderPage } from 'services/context/common/headerPageContextServices'

export const Home = () => {
  const useHeader = useHeaderPage()

  useEffect(() => {
    useHeader.setHeaderPageTitle('')
    useHeader.setOnBack('')
    useHeader.setActiveNavBot(false)
    useHeader.setActiveMoreOptionMenu(false)
  }, [])

  const userInfo = useAuthentication().userInfo
  const data: UserInfo = {
    user: userInfo.nombreUsuario,
    role: userInfo.codigoRol
  }
  const currentTime = new Date()
  const mes = (currentTime.getMonth() + 1).toString()
  const yearActual = currentTime.getFullYear()
  const [selectValueYear, setselectvalueYear] = useState(yearActual)
  const [selectValueMonth, setselectvalueMonth] = useState(mes)
  const [apiPatientData, setApiPatientData] = useState<any[]>()
  const [loading, setLoading] = useState(true)

  // llamada a patients

  const params: useFetchParams = { repositorieParam: PatientsRepositories, param: data, valueYear: selectValueYear, valueMonth: selectValueMonth }
  useEffect(() => {
    setLoading(true)
    const fecthPatient = async (values : useFetchParams) => {
      const data = await values.repositorieParam(values.param, values.valueYear, values.valueMonth)
      return data
    }
    fecthPatient(params).then((res) => {
      setApiPatientData(res.data.data !== undefined ? [res.data.data] : res.data)
      setLoading(false)
    })
  }, [selectValueYear, selectValueMonth])

  const filtered : any[] = apiPatientData !== undefined &&
     apiPatientData![0].filter((patient: any, index: any, array: any) => {
       return array !== undefined ? patient.codigoPaciente !== array.codigoPaciente : null
     }).sort((a: { empresaContratante:any }, b: { empresaContratante:any }) => a.empresaContratante.localeCompare(b.empresaContratante)).sort((a: { nombrePaciente:any }, b: { nombrePaciente:any }) => a.nombrePaciente.localeCompare(b.nombrePaciente))

  const patients: any[] = filtered && filtered.map((e: any, index: number) => e.codigoPaciente)

  return (
    <>
      <SelectorYearMonth disabledSelect={false} defaulYear={selectValueYear} defaulMonth={selectValueMonth} selectYear={function (e: React.SetStateAction<number>): void {
        setselectvalueYear(e)
      } } selectMonth={function (e: React.SetStateAction<string>): void {
        setselectvalueMonth(e)
      } }/>
        <Skeleton loading={loading} style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px' }}>
          <DevelopmentsCall year={selectValueYear} month={selectValueMonth} loading={loading} setLoading={setLoading} setpatient={apiPatientData} patient={patients} codigoProfesional={userInfo.codigoProfesional} />
          <ClinicHistoryCall year={selectValueYear} month={selectValueMonth} loading={loading} setLoading={setLoading} setpatient={apiPatientData} patient={patients} codigoProfesional={userInfo.codigoProfesional} />
          <BillingCall year={selectValueYear} month={selectValueMonth} loading={loading} setLoading={setLoading} patient={patients} codigoProfesional={userInfo.codigoProfesional} />
          <NewsCall year={selectValueYear} month={selectValueMonth} loading={loading} setLoading={setLoading} setpatient={apiPatientData} patient={patients}/>
        </Skeleton>
    </>
  )
}

export default Home
