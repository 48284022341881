import React from 'react'
import { Button, Drawer } from 'antd'
import MenuApp from './menu'
import { SidebarProps } from '.'
import styled from 'styled-components'
import { DoubleLeftOutlined } from '@ant-design/icons'

export interface MobileSidebarProps extends SidebarProps {}

const CollapseContainer = styled.div`
    bottom: 0;
    height: 50px;
    background: rgba(255, 255, 255);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s ease-out;
`

const Mobile = (props: MobileSidebarProps) => {
  const collapse = (
    <CollapseContainer onClick={props.onCollapse}>
        <Button type='text'><DoubleLeftOutlined /></Button>
    </CollapseContainer>
  )

  return (
        <Drawer
          width="80vw"
          closable={false}
          placement="left"
          style={{
            padding: 0,
            height: '100vh'
          }}
          onClose={props.onCollapse}
          open={props.collapsed}>
            <MenuApp />
            {collapse}
        </Drawer>
  )
}

export default Mobile
