/* eslint-disable indent */
/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react'
import '../styles/clinicHistory.css'
import { clinicHistoryRepositories } from '../repositories'
import { useFetchParams, ClinicHistory } from '../interfaces'
import {
  Button,
  Collapse,
  Space,
  Input,
  Tabs,
  Skeleton,
  Empty,
  Checkbox,
  Alert,
} from 'antd'
import { BsFillCalendar2XFill, BsCalendarCheckFill } from 'react-icons/bs'
import {
  clinicHistoryRepoPut,
  clinicHistoryRepoReset,
} from '../repositories/clinicHistoryRepositories'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import SignaturePad from 'react-signature-canvas'
import { FormOutlined } from '@ant-design/icons'
import moment, { Moment } from 'moment'
import SelectorYearMonth from '../components/Inputs/SelectorYearMonth'
import InputTime from '../components/Inputs/InputTime'
import { OpenNotification } from '../components/Notification/notification'
import CalendarHC from '../components/Inputs/CalendarHC'
import { accountConfigRepositories } from '../repositories/AccountRepositories'
import { useGeolocation } from '../hooks'
import { responseGeo } from '../hooks/useGeolocation'
import { usePatient } from '../services/patients/patientContextServices'
import { useAuthentication } from '../services/authContextService'
import { useHeaderPage } from '../services/context/common/headerPageContextServices'
import { FullNamePatient } from '../components'
import _ from 'lodash'

const { Panel } = Collapse
const { TextArea } = Input

const ClinicHistories = () => {
  const usePatients = usePatient()
  const userInfo = useAuthentication().userInfo
  const useHeader = useHeaderPage()

  useEffect(() => {
    useHeader.setHeaderPageTitle('Historia Clinica')
    useHeader.setOnBack(`/Paciente/${usePatients.patientCode}`)
    useHeader.setActiveNavBot(true)
    useHeader.setActiveMoreOptionMenu(false)
  }, [])

  // const namePatient = usePatients.fullName
  const [numberAllHc, setNumberAllHc] = useState(0)

  const data: ClinicHistory = {
    profesionalCode: userInfo.codigoProfesional,
    patientCode: usePatients.patientCode,
    user: userInfo.nombreUsuario,
    role: userInfo.codigoRol,
    numAllHc: numberAllHc,
  }
  const dataConfigCall: any = {
    codigoEntidad: usePatients.entityCode,
  }
  const selectYear = localStorage.getItem('selectYear')
  const selectYearClear = parseInt(selectYear!)
  const selectMonth = localStorage.getItem('selectMonth')
  const selectMonthClear = selectMonth!.slice(1, -1)
  const [selectValueYear, setselectvalueYear] = useState(selectYearClear)
  const [selectValueMonth, setselectvalueMonth] = useState(selectMonthClear)
  const [dataClinicHistory, setDataClinicHistory] = useState<any>({})
  const [api, setApi] = useState<any[]>()
  const [dataConfig, setDataConfig] = useState<any[]>()
  const [edit, setEdit] = useState<boolean>(true)
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [reloadPut, setReloadPut] = useState<any>(0)
  const [dataUrlImage, setDataUrlImage] = useState<string>('')
  const [dataUrlImageEdit, setDataUrlImageEdit] = useState<boolean>(false)
  const [dataClinicHistoryInputs, setDataClinicHistoryInputs] = useState<any>({
    fechaVisita: null,
    tiempoAtencion: null,
    detalle: null,
    entradaLatitud: null,
    entradaLogintud: null,
    salidaLatitud: null,
    salidaLongitud: null,
    firma: null,
  })
  const [geolocationClickedEntrada, setGeolocationClickedEntrada] =
    useState(false)
  const [geolocationClickedSalida, setGeolocationClickedSalida] =
    useState(false)
  const [buttonCancel, setButtonCancel] = useState<boolean>(false)

  const [editTime, setEditTime] = useState<boolean>(true)
  const [editDescription, setEditDescription] = useState<boolean>(true)
  const [editLocation, setEditLocation] = useState<boolean>(true)
  const [parametro501, setParametro501] = useState<number>(0)
  const [editFirma, setEditFirma] = useState<boolean>(true)
  const [editDateHour, setEditDateHour] = useState<boolean>(true)
  const [resGeo, setResGeo] = useState<responseGeo>({
    status: '',
    message: '',
    extra: '',
  })
  const geoStatus = useGeolocation()

  const dateNowCompare = new Date()
  const dateNowMonthCompare = dateNowCompare.getMonth() + 1
  const dateNowYearCompare = dateNowCompare.getFullYear()
  const dateYearCompare: any = []
  const dateMonthCompare: any = []

  const func = (a, b) => {
    dateYearCompare.pop()
    dateMonthCompare.pop()
    dateYearCompare.push(a)
    dateMonthCompare.push(b)
  }

  const compareDate = () => {
    dateNowCompare.getMonth() + 1 - 1 === 0
      ? func(dateNowCompare.getFullYear() - 1, 12)
      : func(dateNowCompare.getFullYear(), dateNowCompare.getMonth() + 1 - 1)
  }
  compareDate()

  const paramsConfig: useFetchParams = {
    repositorieParam: accountConfigRepositories,
    param: dataConfigCall,
    valueYear: selectValueYear,
    valueMonth: selectValueMonth,
  }
  const params: useFetchParams = {
    repositorieParam: clinicHistoryRepositories,
    param: data,
    valueYear: selectValueYear,
    valueMonth: selectValueMonth,
  }
  useEffect(() => {
    setLoading(true)
    const fecth = async (values: useFetchParams) => {
      const data = await values.repositorieParam(
        values.param,
        values.valueYear,
        values.valueMonth
      )
      return data
    }
    const groupedClinicHistoryInputs = {}
    fecth(params).then((res) => {
      const data = res.data.data !== undefined ? res.data.data : res.data
      const dataSort = params.param.numAllHc === 1 ? data : _.orderBy(data, el => el.codigoLinea, 'asc')
      setApi(dataSort)
      setLoading(false)
      setButtonCancel(false)
      const clinicHistoryInputs = dataSort // res.data.data

      // Agrupar los datos por prestacion
      clinicHistoryInputs.forEach((el) => {
        const prestacion = el.prestacion

        if (!groupedClinicHistoryInputs[prestacion]) {
          groupedClinicHistoryInputs[prestacion] = []
        }

        groupedClinicHistoryInputs[prestacion].push({
          fechaVisita: el.fechaHoraVisita,
          tiempoAtencion: el.tiempoAtencion,
          detalle: el.detalle,
          entradaLatitud: el.entradaLatitud,
          entradaLongitud: el.entradaLongitud,
          salidaLatitud: el.salidaLatitud,
          salidaLongitud: el.salidaLongitud,
          firma: el.firma,
        })
      })

      // Establecer los datos agrupados en setDataClinicHistoryInputs
      setDataClinicHistoryInputs(groupedClinicHistoryInputs)
    })
    fecth(paramsConfig).then((res) => {
      setDataConfig(res.data.data !== undefined ? [res.data.data] : res.data)
    })
    setReloadPut(0)
  }, [selectValueYear, selectValueMonth, numberAllHc, reloadPut, buttonCancel])
  // Firma
  useEffect(() => {
    editHcTime()
    editHcEntradaSalida()
    editHcFirma()
    editHcDescription()
    editHcDate()
  }, [dataConfig])
  // const [dataURL, setDataURL] = React.useState<string | null>(null)
  const padRef = React.useRef<SignaturePad>(null)
  const groupedHistoryClinics = {}
  const dataSortApi = _.orderBy(api, el => el.codigoLinea, 'asc')
  api &&
    api.length > 0 &&
    dataSortApi?.forEach((historyClinic) => {
      const prestacion = historyClinic.prestacion

      if (!groupedHistoryClinics[prestacion]) {
        groupedHistoryClinics[prestacion] = []
      }

      groupedHistoryClinics[prestacion].push(historyClinic)
    })
  // Localizacion
  const entradaLocation = async (prestacion: any, index: number) => {
    const dateNow = new Date()
    const formatDate = (e) => {
      const month = '00' + (e.getMonth() + 1)
      const day = '00' + e.getDate()
      const hours = '00' + e.getHours()
      const minute = '00' + e.getMinutes()
      const seconds = '00' + e.getSeconds()
      const formattedDate2 = `${e.getFullYear()}-${month.substring(
        month.length,
        month.length - 2
      )}-${day.substring(day.length, day.length - 2)}T${hours.substring(
        hours.length,
        hours.length - 2
      )}:${minute.substring(
        minute.length,
        minute.length - 2
      )}:${seconds.substring(seconds.length, seconds.length - 2)}` // e.getFullYear() + '-' + month.substring(month.length, month.length - 2) + '-' + day.substring(day.length, day.length - 2) + 'T' + hours.substring(hours.length, hours.length - 2) + ':' + minute.substring(minute.length, minute.length - 2) + ':' + seconds.substring(seconds.length, seconds.length - 2) + 'Z'
      return formattedDate2
    }
    const date = formatDate(dateNow)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        const latitud = String(position.coords.latitude)
        const longitud = String(position.coords.longitude)
        await getInformation(
          { latitud, longitud, date },
          'entradaData',
          index,
          prestacion
        )
      })
    }
  }
  const salidaLocation = async (prestacion: any, index: number) => {
    const dateNow = new Date()
    const formatDate = (e) => {
      const month = '00' + (e.getMonth() + 1)
      const day = '00' + e.getDate()
      const hours = '00' + e.getHours()
      const minute = '00' + e.getMinutes()
      const seconds = '00' + e.getSeconds()
      const formattedDate2 = `${e.getFullYear()}-${month.substring(
        month.length,
        month.length - 2
      )}-${day.substring(day.length, day.length - 2)}T${hours.substring(
        hours.length,
        hours.length - 2
      )}:${minute.substring(
        minute.length,
        minute.length - 2
      )}:${seconds.substring(seconds.length, seconds.length - 2)}` // e.getFullYear() + '-' + month.substring(month.length, month.length - 2) + '-' + day.substring(day.length, day.length - 2) + 'T' + hours.substring(hours.length, hours.length - 2) + ':' + minute.substring(minute.length, minute.length - 2) + ':' + seconds.substring(seconds.length, seconds.length - 2) + 'Z'
      return formattedDate2
    }
    const date2 = formatDate(dateNow)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        const latitud2 = String(position.coords.latitude)
        const longitud2 = String(position.coords.longitude)
        await getInformation(
          { latitud2, longitud2, date2 },
          'salidaData',
          index,
          prestacion
        )
      })
    }
  }
  // firma

  const clear = () => {
    padRef.current?.clear()
  }
  const trim = async (prestacion: any, index: number) => {
    setDataUrlImageEdit(true)
    const url = padRef.current?.getTrimmedCanvas().toDataURL('image/png')
    if (url) {
      setDataUrlImage(url)
    }
    return await (getInformation(url, 'firma', index, prestacion),
    setIsOpen(false))
  }
  // otras
  const getLabelVisit = (date: Moment, detail: string | undefined) => {
    if (date && detail) return date
    if (date && !detail) return `${date} | Planificada`

    return 'Visita no realizada'
  }
  const getExtra = (date: Moment, detail: string | undefined) => {
    // TODO: hay que modificar la condicion
    if (date && detail) return <BsCalendarCheckFill style={{ fontSize: '40px', color: '#36bc9b' }} />
    if (date && !detail) return <BsCalendarCheckFill style={{ fontSize: '40px', color: '#2196f3' }} />

    return <BsFillCalendar2XFill style={{ fontSize: '40px', color: '#f00c0c' }} />
  }

  const put = async (
    data: any,
    prestacion: any,
    index: number,
    modificaHC: number
  ) => {
    setResGeo({
      status: '',
      message: '',
      extra: '',
    })

    if (editLocation === true) {
      const salidaPut: {
        date2: string
        latitud2: string
        longitud2: string
      }[] = []
      const formatDate = (e) => {
        const month = '00' + (e.getMonth() + 1)
        const day = '00' + e.getDate()
        const hours = '00' + e.getHours()
        const minute = '00' + e.getMinutes()
        const seconds = '00' + e.getSeconds()
        const formattedDate2 = `${e.getFullYear()}-${month.substring(
          month.length,
          month.length - 2
        )}-${day.substring(day.length, day.length - 2)}T${hours.substring(
          hours.length,
          hours.length - 2
        )}:${minute.substring(
          minute.length,
          minute.length - 2
        )}:${seconds.substring(seconds.length, seconds.length - 2)}` // e.getFullYear() + '-' + month.substring(month.length, month.length - 2) + '-' + day.substring(day.length, day.length - 2) + 'T' + hours.substring(hours.length, hours.length - 2) + ':' + minute.substring(minute.length, minute.length - 2) + ':' + seconds.substring(seconds.length, seconds.length - 2) + 'Z'
        return formattedDate2
      }
      const getDateAndLocation = () => {
        return new Promise<void>((resolve, reject) => {
          const dateNow = new Date()
          const date2 = formatDate(dateNow)

          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const latitud2 = String(position.coords.latitude)
                const longitud2 = String(position.coords.longitude)
                salidaPut.push({ date2, latitud2, longitud2 })
                resolve()
              },
              (error) => {
                reject(error)
              }
            )
          } else {
            reject(
              new Error('Geolocalización no es compatible en este navegador')
            )
          }
        })
      }

      // Llamar a la función para obtener la fecha y la ubicación
      getDateAndLocation()
        .then(async () => {
          if (!editDateHour && !data.fechaVisita) {
            return OpenNotification(
              'Tiene Datos Incompletos',
              'Se deben completar los datos de Fecha de la visita...',
              'top',
              'error'
            )
          }
          if (editDescription === false && !data.detalle) {
            return OpenNotification(
              'Tiene Datos Incompletos',
              'Se deben completar los datos de Detalle...',
              'top',
              'error'
            )
          }
          if (editTime === false && data.tiempoAtencion === '00:00:00') {
            return OpenNotification(
              'Tiene Datos Incompletos',
              'Se deben completar los datos Tiempo de Visita...',
              'top',
              'error'
            )
          }
          if (editFirma === false && data.firma === ' ') {
            return OpenNotification(
              'Tiene Datos Incompletos',
              'Se deben completar los datos Firma.',
              'top',
              'error'
            )
          }

          const fecha = new Date(data.fechaVisita)
          const formatDate2 = (e) => {
            const month = '00' + (e.getMonth() + 1)
            const day = '00' + e.getDate()
            const hours = '00' + e.getHours()
            const minute = '00' + e.getMinutes()
            const seconds = '00' + e.getSeconds()
            const formattedDate2 =
              e.getFullYear() +
              '-' +
              month.substring(month.length, month.length - 2) +
              '-' +
              day.substring(day.length, day.length - 2) +
              'T' +
              hours.substring(hours.length, hours.length - 2) +
              ':' +
              minute.substring(minute.length, minute.length - 2) +
              ':' +
              seconds.substring(seconds.length, seconds.length - 2)
            return formattedDate2
          }
          const dataClinic = groupedHistoryClinics[prestacion][index]
          await (clinicHistoryRepoPut({
            ...dataClinic,
            ...data,
            salidaLatitud: salidaPut[0].latitud2,
            salidaLongitud: salidaPut[0].longitud2,
            salidaFechaCarga: salidaPut[0].date2,
            fechaVisita: editDateHour === true ? null : formatDate2(fecha),
          }),
          editHc(true),
          setEditTime(true),
          setEditLocation(true),
          setEditFirma(true),
          setEditDescription(true),
          setEditDateHour(true),
          setReloadPut(1),
          setDataUrlImage(''),
          setDataClinicHistory({}),
          setDataUrlImageEdit(false),
          setGeolocationClickedSalida(false),
          setGeolocationClickedEntrada(false))
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    } else {
      if (editLocation === false) {
        if (
          ((parametro501 === 2 && modificaHC === 1) ||
            (parametro501 === 1 && editLocation === false)) &&
          !data.entradaLatitud
        ) {
          return OpenNotification(
            'Tiene Datos Incompletos',
            'Se debe ingresar Localizacion Entrada',
            'top',
            'error'
          )
        }
        if (editLocation === false && data.entradaLatitud) {
          if (editDateHour === false && !data.fechaVisita) {
            return OpenNotification(
              'Tiene Datos Incompletos',
              'Se deben completar los datos de Fecha de la visita...',
              'top',
              'error'
            )
          }
        }
        if (editLocation === false && data.salidaLatitud) {
          if (editDescription === false && !data.detalle) {
            return OpenNotification(
              'Tiene Datos Incompletos',
              'Se deben completar los datos de Detalle...',
              'top',
              'error'
            )
          }
          if (editTime === false && data.tiempoAtencion === '00:00:00') {
            return OpenNotification(
              'Tiene Datos Incompletos',
              'Se deben completar los datos Tiempo de Visita...',
              'top',
              'error'
            )
          }
          if (editFirma === false && data.firma === ' ') {
            return OpenNotification(
              'Tiene Datos Incompletos',
              'Se deben completar los datos Firma.',
              'top',
              'error'
            )
          }
        }
      } else {
        if (editDateHour === false && !data.fechaVisita) {
          return OpenNotification(
            'Tiene Datos Incompletos',
            'Se deben completar los datos de Fecha de la visita...',
            'top',
            'error'
          )
        }
        if (editDescription === false && !data.detalle) {
          return OpenNotification(
            'Tiene Datos Incompletos',
            'Se deben completar los datos de Detalle...',
            'top',
            'error'
          )
        }
        if (editTime === false && data.tiempoAtencion === '00:00:00') {
          return OpenNotification(
            'Tiene Datos Incompletos',
            'Se deben completar los datos Tiempo de Visita...',
            'top',
            'error'
          )
        }
        if (editFirma === false && data.firma === ' ') {
          return OpenNotification(
            'Tiene Datos Incompletos',
            'Se deben completar los datos Firma.',
            'top',
            'error'
          )
        }
      }

      const fecha = new Date(data.fechaVisita)
      const formatDate2 = (e) => {
        const month = '00' + (e.getMonth() + 1)
        const day = '00' + e.getDate()
        const hours = '00' + e.getHours()
        const minute = '00' + e.getMinutes()
        const seconds = '00' + e.getSeconds()
        const formattedDate2 =
          e.getFullYear() +
          '-' +
          month.substring(month.length, month.length - 2) +
          '-' +
          day.substring(day.length, day.length - 2) +
          'T' +
          hours.substring(hours.length, hours.length - 2) +
          ':' +
          minute.substring(minute.length, minute.length - 2) +
          ':' +
          seconds.substring(seconds.length, seconds.length - 2)
        return formattedDate2
      }
      const dataClinic = groupedHistoryClinics[prestacion][index]
      await (clinicHistoryRepoPut({
        ...dataClinic,
        ...data,
        fechaVisita: editDateHour === true ? null : formatDate2(fecha),
      }),
      editHc(true),
      setEditTime(true),
      setEditLocation(true),
      setEditFirma(true),
      setEditDescription(true),
      setEditDateHour(true),
      setReloadPut(1),
      setDataUrlImage(''),
      setDataClinicHistory({}),
      setDataUrlImageEdit(false),
      setGeolocationClickedSalida(false),
      setGeolocationClickedEntrada(false))
    }
  }
  const reset = async (prestacion: any, index: number) => {
    const dataClinic = groupedHistoryClinics[prestacion][index]
    await (clinicHistoryRepoReset({
      ...dataClinic,
      firma: null,
      entradaLatitud: null,
      entradaLongitud: null,
      entradaFechaCarga: null,
      salidaFechaCarga: null,
      salidaLatitud: null,
      salidaLongitud: null,
      detalle: null,
      fechaVisita: null,
      tiempoAtencion: null,
    }),
    editHc(true),
    setEditTime(true),
    setEditLocation(true),
    setEditFirma(true),
    setEditDateHour(true),
    setEditDescription(true),
    setReloadPut(1),
    setDataClinicHistory({}),
    setDataUrlImageEdit(false),
    setGeolocationClickedSalida(false),
    setGeolocationClickedEntrada(false))
  }

  const editHcTimeBoolean = (estado: boolean) => {
    setEditTime(estado)
    setEditDateHour(estado)
  }

  const editHcTime = () => {
    dataConfig &&
      dataConfig![0]
        .filter((configData: any, index: any) => {
          return configData.codigo === 500
        })
        .map((configData2: any, index: any) => {
          return configData2.valor === '0'
            ? editHcTimeBoolean(false)
            : setEditTime(true)
        })
  }

  const editHcEntradaSalida = () => {
    dataConfig &&
      dataConfig![0]
        .filter((configData: any, index: any) => {
          return configData.codigo === 501
        })
        .map((configData2: any, index: any) => {
          const valor: number = parseInt(configData2.valor)
          setParametro501(valor)
          return configData2.valor === '0'
            ? setEditLocation(true)
            : setEditLocation(false)
        })
  }

  const editHcDate = () => {
    const state =
      dataConfig &&
      dataConfig![0]
        .filter((configData: any, index: any) => {
          return configData.codigo === 501
        })
        .map((configData2: any, index: any) => {
          return configData2.valor === '0'
        })
    const stateTime =
      dataConfig &&
      dataConfig![0]
        .filter((configData: any, index: any) => {
          return configData.codigo === 500
        })
        .map((configData2: any, index: any) => {
          return configData2.valor === '0'
        })
    dataConfig &&
      dataConfig![0]
        .filter((configData: any, index: any) => {
          return configData.codigo === 502
        })
        .map((configData2: any, index: any) => {
          return configData2.valor === '1' && state[0] === false
            ? setEditDateHour(false)
            : stateTime[0] === true
            ? setEditDateHour(false)
            : setEditDateHour(true)
        })
  }

  const editHcFirma = () => {
    dataConfig &&
      dataConfig![0]
        .filter((configData: any, index: any) => {
          return configData.codigo === 503
        })
        .map((configData2: any, index: any) => {
          return configData2.valor === '0'
            ? setEditFirma(true)
            : setEditFirma(false)
        })
  }

  const editHcDescription = () => {
    dataConfig &&
      dataConfig![0]
        .filter((configData: any, index: any) => {
          return configData.codigo === 504
        })
        .map((configData2: any, index: any) => {
          return configData2.valor === '0'
            ? setEditDescription(false)
            : setEditDescription(true)
        })
  }
  const editHc = (estado: boolean) => {
    setResGeo(geoStatus) // GEO_ACTIVE
    setEdit(estado)
  }
  const cancelar = () => {
    setResGeo({
      status: '',
      message: '',
      extra: '',
    })
    setButtonCancel(true)
    setEdit(true)
    setEditTime(true)
    setEditLocation(true)
    setEditFirma(true)
    setEditDateHour(true)
    setEditDescription(true)
    setDataUrlImage('')
    setDataClinicHistory({})
    setDataUrlImageEdit(false)
    setGeolocationClickedSalida(false)
    setGeolocationClickedEntrada(false)
  }

  const getInformation = async (
    e: any | string,
    type: string,
    index: number,
    prestacion: any
  ) => {
    if (type === 'firma') {
      if (
        dataClinicHistoryInputs &&
        dataClinicHistoryInputs[prestacion] &&
        dataClinicHistoryInputs[prestacion][index]
      ) {
        const updatedObject = {
          ...dataClinicHistoryInputs[prestacion][index],
          firma: e,
        }

        const updatedArray = [...dataClinicHistoryInputs[prestacion]]
        updatedArray[index] = updatedObject

        const updatedInputs = { ...dataClinicHistoryInputs }
        updatedInputs[prestacion] = updatedArray

        setDataClinicHistory({
          ...dataClinicHistory,
          firma: e,
        })

        setDataClinicHistoryInputs(updatedInputs)
      }
    }
    if (type === 'entradaData') {
      if (
        dataClinicHistoryInputs &&
        dataClinicHistoryInputs[prestacion] &&
        dataClinicHistoryInputs[prestacion][index]
      ) {
        const updatedObject = {
          ...dataClinicHistoryInputs[prestacion][index],
          entradaLatitud: e.latitud,
          entradaLongitud: e.longitud,
          entradaFechaCarga: e.date,
        }

        const updatedArray = [...dataClinicHistoryInputs[prestacion]]
        updatedArray[index] = updatedObject

        const updatedInputs = { ...dataClinicHistoryInputs }
        updatedInputs[prestacion] = updatedArray

        setDataClinicHistoryInputs(updatedInputs)
        setGeolocationClickedEntrada(true)

        setDataClinicHistory({
          ...dataClinicHistory,
          entradaLatitud: e.latitud,
          entradaLongitud: e.longitud,
          entradaFechaCarga: new Date(),
        })
      }
    }
    if (type === 'salidaData') {
      if (
        dataClinicHistoryInputs &&
        dataClinicHistoryInputs[prestacion] &&
        dataClinicHistoryInputs[prestacion][index]
      ) {
        const updatedObject = {
          ...dataClinicHistoryInputs[prestacion][index],
          salidaLatitud: e.latitud2,
          salidaLongitud: e.longitud2,
          salidaFechaCarga: e.date2,
        }

        const updatedArray = [...dataClinicHistoryInputs[prestacion]]
        updatedArray[index] = updatedObject

        const updatedInputs = { ...dataClinicHistoryInputs }
        updatedInputs[prestacion] = updatedArray

        setDataClinicHistory({
          ...dataClinicHistory,
          salidaLatitud: e.latitud2,
          salidaLongitud: e.longitud2,
          salidaFechaCarga: new Date(),
        })
        setGeolocationClickedSalida(true)

        setDataClinicHistoryInputs(updatedInputs)
      }
    }
    if (type === 'comments') {
      const inputValue = e.target.value

      const updatedArray = dataClinicHistoryInputs[prestacion].map(
        (item: any, indexComments: number) => {
          if (indexComments === index) {
            // Clona el objeto existente y actualiza solo el campo 'detalle'
            return { ...item, detalle: inputValue }
          }
          return item // Mantiene los otros objetos sin cambios
        }
      )

      // Actualiza solo el objeto específico dentro de dataClinicHistoryInputs[prestacion]
      const updatedInputs = { ...dataClinicHistoryInputs }
      updatedInputs[prestacion] = updatedArray

      setDataClinicHistoryInputs(updatedInputs)

      // Actualiza el campo 'detalle' en dataClinicHistory
      setDataClinicHistory({ ...dataClinicHistory, detalle: inputValue })
    }
    if (type === 'date') {
      const inputValue = new Date(e._d)

      if (
        dataClinicHistoryInputs &&
        dataClinicHistoryInputs[prestacion] &&
        dataClinicHistoryInputs[prestacion][index]
      ) {
        const updatedObject = {
          ...dataClinicHistoryInputs[prestacion][index],
          fechaVisita: inputValue,
        }

        const updatedArray = [...dataClinicHistoryInputs[prestacion]]
        updatedArray[index] = updatedObject

        const updatedInputs = { ...dataClinicHistoryInputs }
        updatedInputs[prestacion] = updatedArray

        setDataClinicHistoryInputs(updatedInputs)

        setDataClinicHistory({ ...dataClinicHistory, fechaVisita: inputValue })
      }
    }
    if (type === 'time') {
      const inputValue = new Date(e._d)

      if (
        dataClinicHistoryInputs &&
        dataClinicHistoryInputs[prestacion] &&
        dataClinicHistoryInputs[prestacion][index]
      ) {
        const hours = '00' + inputValue.getHours()
        const minute = '00' + inputValue.getMinutes()
        const formattedTime =
          hours.substring(hours.length, hours.length - 2) +
          ':' +
          minute.substring(minute.length, minute.length - 2) +
          ':' +
          '00'

        const updatedObject = {
          ...dataClinicHistoryInputs[prestacion][index],
          tiempoAtencion: formattedTime,
        }

        const updatedArray = [...dataClinicHistoryInputs[prestacion]]
        updatedArray[index] = updatedObject

        const updatedInputs = { ...dataClinicHistoryInputs }
        updatedInputs[prestacion] = updatedArray

        setDataClinicHistoryInputs(updatedInputs)

        setDataClinicHistory({
          ...dataClinicHistory,
          tiempoAtencion: formattedTime,
        })
      }
    }
  }
  // funcion onChange del tabs

  const onchange = (key: string) => {
    if (key === '2') {
      setNumberAllHc(1)
    } else {
      setNumberAllHc(0)
    }
  }
  const dateNow = new Date()

  return (
    <div className="contentHistory">
      <FullNamePatient />
      <SelectorYearMonth
        disabledSelect={!edit}
        defaulYear={selectValueYear}
        defaulMonth={selectValueMonth}
        selectYear={setselectvalueYear}
        selectMonth={setselectvalueMonth}
      />
      <div className="clinicHistory">
        <Tabs
          defaultActiveKey="1"
          className="tabHistory"
          onChange={onchange}
          items={[
            {
              label: 'Mis H.C.',
              key: '1',
              children: (
                <div>
                  <Skeleton
                    loading={loading}
                    active
                    style={{
                      backgroundColor: 'white',
                      padding: '20px',
                      borderRadius: '5px',
                    }}
                  >
                    <Collapse accordion expandIconPosition="start">
                      {Object.keys(groupedHistoryClinics).map((prestacion) => (
                        <Panel
                          header={`Prestación: ${prestacion}`}
                          key={prestacion}
                          collapsible={edit === false ? 'disabled' : 'header'}
                        >
                          <Collapse accordion expandIconPosition="start">
                            {groupedHistoryClinics[prestacion].map(
                              (historyClinic, index) => {
                                // Obtener todas las fechasHoraVisita de este panel
                                const fechaHoraVisitas = groupedHistoryClinics[
                                  prestacion
                                ].map((item) => item.fechaHoraVisita)
                                return (
                                  <Panel
                                    // header={`${index + 1} ${
                                    //   historyClinic.fechaHoraVisita && historyClinic.detalle
                                    //     ? historyClinic.fechaHoraVisita
                                    //     : 'Visita no realizada'
                                    // }`}
                                    header={
                                      `${index + 1} ${String(getLabelVisit(historyClinic.fechaHoraVisita, historyClinic.detalle))}`
                                    }
                                    key={index}
                                    extra={getExtra(
                                      historyClinic.fechaHoraVisita, historyClinic.detalle
                                    )}
                                    collapsible={
                                      edit === false ? 'disabled' : 'header'
                                    }
                                  >
                                    {resGeo.status === 'GEO_ACTIVE' ||
                                    resGeo.status === '' ? (
                                      <></>
                                    ) : (
                                      <Alert
                                        style={{ marginTop: 10 }}
                                        message={resGeo.status}
                                        description={resGeo.message}
                                        type="error"
                                        closable
                                        onClose={() => {
                                          setResGeo({
                                            status: '',
                                            message: '',
                                            extra: '',
                                          })
                                        }}
                                      />
                                    )}
                                    <div
                                      key={index}
                                      className="clinicHistory--container"
                                    >
                                      {dateNowCompare.getDate() > 25 ? (
                                        dateNowMonthCompare ===
                                          parseInt(selectValueMonth) &&
                                        dateNowYearCompare ===
                                          selectValueYear ? (
                                          groupedHistoryClinics[prestacion][
                                            index - 1
                                          ]?.detalle === null ? (
                                            <Button
                                              disabled={!edit}
                                              style={{ display: 'none' }}
                                              onClick={() => editHc(false)}
                                            >
                                              Editar
                                            </Button>
                                          ) : (
                                            <Button
                                              disabled={!edit}
                                              style={{ marginRight: '13px' }}
                                              onClick={() => editHc(false)}
                                            >
                                              Editar
                                            </Button>
                                          )
                                        ) : (
                                          <Button
                                            disabled={!edit}
                                            style={{ display: 'none' }}
                                            onClick={() => editHc(false)}
                                          >
                                            Editar
                                          </Button>
                                        )
                                      ) : (dateMonthCompare[0] ===
                                          parseInt(selectValueMonth) &&
                                          dateYearCompare[0] ===
                                            selectValueYear) ||
                                        (dateNowMonthCompare ===
                                          parseInt(selectValueMonth) &&
                                          dateNowYearCompare ===
                                            selectValueYear) ? (
                                        groupedHistoryClinics[prestacion][
                                          index - 1
                                        ]?.detalle === null ? (
                                          <Button
                                            disabled={!edit}
                                            style={{ display: 'none' }}
                                            onClick={() => editHc(false)}
                                          >
                                            Editar
                                          </Button>
                                        ) : (
                                          <Button
                                            disabled={!edit}
                                            style={{ marginRight: '13px' }}
                                            onClick={() => editHc(false)}
                                          >
                                            Editar
                                          </Button>
                                        )
                                      ) : (
                                        <Button
                                          disabled={!edit}
                                          style={{ display: 'none' }}
                                          onClick={() => editHc(false)}
                                        >
                                          Editar
                                        </Button>
                                      )}
                                      {(parametro501 === 2 &&
                                        historyClinic.modificaHC === 1) ||
                                      (parametro501 === 1 &&
                                        editLocation === false) ? (
                                        <div>
                                          <h3 className="title-Hc">
                                            Geolocalizacion:
                                          </h3>
                                          <div className="button-geolocation">
                                            {historyClinic.entradaLatitud &&
                                            historyClinic.entradaLongitud ? (
                                              <div className="button-geolocation-entrada">
                                                <Button
                                                  disabled={true}
                                                  style={{ width: 75 }}
                                                  onClick={async () =>
                                                    await entradaLocation(
                                                      prestacion,
                                                      index
                                                    )
                                                  }
                                                >
                                                  Entrada
                                                </Button>
                                                <Checkbox.Group
                                                  options={[
                                                    {
                                                      label: '',
                                                      value: 'Apple',
                                                    },
                                                  ]}
                                                  disabled
                                                  defaultValue={['Apple']}
                                                />
                                              </div>
                                            ) : (
                                              <div className="button-geolocation-entrada">
                                                <Button
                                                  disabled={
                                                    edit === true
                                                      ? edit
                                                      : editLocation
                                                  }
                                                  style={{ width: 75 }}
                                                  onClick={async () =>
                                                    await entradaLocation(
                                                      prestacion,
                                                      index
                                                    )
                                                  }
                                                >
                                                  Entrada
                                                </Button>
                                                <Checkbox.Group
                                                  options={
                                                    geolocationClickedEntrada ===
                                                    true
                                                      ? [
                                                          {
                                                            label: '',
                                                            value: 'Apple',
                                                          },
                                                        ]
                                                      : [
                                                          {
                                                            label: '',
                                                            value: '',
                                                          },
                                                        ]
                                                  }
                                                  disabled
                                                  defaultValue={['Apple']}
                                                />
                                              </div>
                                            )}

                                            {historyClinic.salidaLatitud &&
                                            historyClinic.salidaLongitud ? (
                                              <div className="button-geolocation-salida">
                                                <Button
                                                  disabled={true}
                                                  style={{ width: 75 }}
                                                  onClick={async () =>
                                                    await salidaLocation(
                                                      prestacion,
                                                      index
                                                    )
                                                  }
                                                >
                                                  Salida
                                                </Button>
                                                <Checkbox.Group
                                                  options={[
                                                    {
                                                      label: '',
                                                      value: 'Apple',
                                                    },
                                                  ]}
                                                  disabled
                                                  defaultValue={['Apple']}
                                                />
                                              </div>
                                            ) : (
                                              <div className="button-geolocation-salida">
                                                <Button
                                                  disabled={
                                                    edit === true
                                                      ? edit
                                                      : editLocation
                                                  }
                                                  style={{ width: 75 }}
                                                  onClick={async () =>
                                                    await salidaLocation(
                                                      prestacion,
                                                      index
                                                    )
                                                  }
                                                >
                                                  Salida
                                                </Button>
                                                <Checkbox.Group
                                                  options={
                                                    geolocationClickedSalida ===
                                                    true
                                                      ? [
                                                          {
                                                            label: '',
                                                            value: 'Apple',
                                                          },
                                                        ]
                                                      : [
                                                          {
                                                            label: '',
                                                            value: '',
                                                          },
                                                        ]
                                                  }
                                                  disabled
                                                  defaultValue={['Apple']}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      ) : null}
                                      <div className="edit">
                                        <div>
                                          <h3 style={{ marginBottom: '11px' }}>
                                            Fecha/Hora de la Visita:
                                          </h3>
                                          {historyClinic.fechaHoraVisita ? (
                                            <Space direction="vertical">
                                              <CalendarHC
                                                dateInicio={
                                                  historyClinic.fechaInicio
                                                }
                                                dateFin={historyClinic.fechaFin}
                                                previusDate={
                                                  fechaHoraVisitas[
                                                    index - 1
                                                  ] === null
                                                    ? fechaHoraVisitas[
                                                        fechaHoraVisitas.length -
                                                          1
                                                      ] ===
                                                      fechaHoraVisitas[index]
                                                      ? fechaHoraVisitas[index]
                                                      : dateNow
                                                    : fechaHoraVisitas[
                                                        index - 1
                                                      ]
                                                }
                                                defaultValue={moment(
                                                  historyClinic.fechaHoraVisita,
                                                  'YYYY-MM-DD HH:mm:ss'
                                                )}
                                                disabled={
                                                  edit === true
                                                    ? edit
                                                    : editDateHour
                                                }
                                                notShowTime={false}
                                                onChange={(e) =>
                                                  getInformation(
                                                    e,
                                                    'date',
                                                    index,
                                                    prestacion
                                                  )
                                                }
                                              />
                                            </Space>
                                          ) : (
                                            <Space direction="vertical">
                                              <CalendarHC
                                                dateInicio={
                                                  historyClinic.fechaInicio
                                                }
                                                dateFin={historyClinic.fechaFin}
                                                previusDate={
                                                  fechaHoraVisitas[
                                                    index - 1
                                                  ] === null
                                                    ? fechaHoraVisitas[
                                                        fechaHoraVisitas.length -
                                                          1
                                                      ] ===
                                                      fechaHoraVisitas[index]
                                                      ? fechaHoraVisitas[index]
                                                      : dateNow
                                                    : fechaHoraVisitas[
                                                        index - 1
                                                      ]
                                                }
                                                disabled={
                                                  edit === true
                                                    ? edit
                                                    : editDateHour
                                                }
                                                notShowTime={false}
                                                onChange={(e) =>
                                                  getInformation(
                                                    e,
                                                    'date',
                                                    index,
                                                    prestacion
                                                  )
                                                }
                                              />
                                            </Space>
                                          )}
                                        </div>
                                      </div>

                                      <div>
                                        <h3 className="title-Hc">
                                          Duración de Visita:
                                        </h3>
                                        <div>
                                          {!historyClinic.tiempoAtencion ? (
                                            <div>
                                              <Space direction="vertical" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                                <InputTime
                                                  disabled={
                                                    edit === true
                                                      ? edit
                                                      : editTime
                                                  }
                                                  bloquearDuracion={historyClinic.bloquearDuracion}
                                                  format={'HH:mm'}
                                                  onChange={(e) =>
                                                    getInformation(
                                                      e,
                                                      'time',
                                                      index,
                                                      prestacion
                                                    )
                                                  }
                                                /> <p>(Hora : Minuto)</p>
                                              </Space>
                                            </div>
                                          ) : (
                                            <div>
                                              <Space direction="vertical" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                                <InputTime
                                                  bloquearDuracion={historyClinic.bloquearDuracion}
                                                  defaultValue={moment(
                                                    historyClinic.tiempoAtencion,
                                                    'HH:mm'
                                                  )}
                                                  disabled={
                                                    edit === true
                                                      ? edit
                                                      : editTime
                                                  }
                                                  format={'HH:mm'}
                                                  onChange={(e) =>
                                                    getInformation(
                                                      e,
                                                      'time',
                                                      index,
                                                      prestacion
                                                    )
                                                  }
                                                /> <p>(Hora : Minuto)</p>
                                              </Space>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <h3 className="title-Hc">
                                          Comentario:
                                        </h3>
                                        {historyClinic.detalle ? (
                                          <div>
                                            <TextArea
                                              defaultValue={
                                                historyClinic.detalle
                                              }
                                              disabled={
                                                edit === true
                                                  ? edit
                                                  : editDescription
                                              }
                                              id="comentario"
                                              showCount
                                              minLength={15}
                                              rows={5}
                                              placeholder="Comments.."
                                              onChange={(e) =>
                                                getInformation(
                                                  e,
                                                  'comments',
                                                  index,
                                                  prestacion
                                                )
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <div>
                                            <TextArea
                                              disabled={
                                                edit === true
                                                  ? edit
                                                  : editDescription
                                              }
                                              id="comentario"
                                              showCount
                                              minLength={15}
                                              rows={5}
                                              placeholder="Comments.."
                                              onChange={(e) =>
                                                getInformation(
                                                  e,
                                                  'comments',
                                                  index,
                                                  prestacion
                                                )
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                      {editFirma === true ? null : (
                                        <div>
                                          <h3 className="title-Hc">Firma:</h3>
                                          {!historyClinic.firma ||
                                          !historyClinic.firma.includes(
                                            'data:'
                                          ) ? (
                                            <div>
                                              <Popup
                                                modal
                                                on="click"
                                                open={isOpen}
                                                onOpen={() => setIsOpen(true)}
                                                trigger={
                                                  <button
                                                    className="Button-firma"
                                                    disabled={
                                                      edit === true
                                                        ? edit
                                                        : editFirma
                                                    }
                                                  >
                                                    <FormOutlined />
                                                  </button>
                                                }
                                                closeOnDocumentClick={false}
                                              >
                                                <div>
                                                  <SignaturePad
                                                    ref={padRef}
                                                    canvasProps={{
                                                      className: 'sigCanvas',
                                                    }}
                                                    key={index}
                                                  />
                                                  <div className="sigPreview">
                                                    <button
                                                      className="Button-firma-guardar"
                                                      id="guardar"
                                                      onClick={() =>
                                                        trim(prestacion, index)
                                                      }
                                                    >
                                                      Guardar
                                                    </button>
                                                    <button
                                                      className="Button-firma-limpiar"
                                                      onClick={clear}
                                                    >
                                                      Limpiar
                                                    </button>
                                                    <button
                                                      className="Button-firma-cerrar"
                                                      onClick={() =>
                                                        setIsOpen(false)
                                                      }
                                                    >
                                                      Cerrar
                                                    </button>
                                                  </div>
                                                </div>
                                              </Popup>
                                              {dataUrlImage &&
                                              fechaHoraVisitas[index - 1] !==
                                                null &&
                                              fechaHoraVisitas[index + 1] ===
                                                null ? (
                                                <img
                                                  id="horaAtencion"
                                                  className={'sigImage'}
                                                  src={dataUrlImage}
                                                />
                                              ) : null}
                                            </div>
                                          ) : (
                                            <div className={'divSigImage'}>
                                              <img
                                                id="Edit"
                                                className={'sigImage'}
                                                src={
                                                  dataUrlImageEdit === false
                                                    ? historyClinic.firma
                                                    : dataUrlImage
                                                }
                                                alt="user generated signature"
                                              />
                                              <Popup
                                                modal
                                                on="click"
                                                open={isOpen}
                                                onOpen={() => setIsOpen(true)}
                                                trigger={
                                                  <button
                                                    className="Button-firma-edit"
                                                    disabled={
                                                      edit === true
                                                        ? edit
                                                        : editFirma
                                                    }
                                                  >
                                                    Cambiar Firma
                                                  </button>
                                                }
                                                closeOnDocumentClick={false}
                                              >
                                                <div>
                                                  <SignaturePad
                                                    ref={padRef}
                                                    canvasProps={{
                                                      className: 'sigCanvas',
                                                    }}
                                                    key={index}
                                                  />
                                                  <div className="sigPreview">
                                                    <button
                                                      className="Button-firma-guardar"
                                                      id="guardarEdit"
                                                      onClick={() =>
                                                        trim(prestacion, index)
                                                      }
                                                    >
                                                      Guardar
                                                    </button>
                                                    <button
                                                      className="Button-firma-limpiar"
                                                      onClick={clear}
                                                    >
                                                      Limpiar
                                                    </button>
                                                    <button
                                                      className="Button-firma-cerrar"
                                                      onClick={() =>
                                                        setIsOpen(false)
                                                      }
                                                    >
                                                      Cerrar
                                                    </button>
                                                  </div>
                                                </div>
                                              </Popup>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      <div className="Button-guardar-cancelar">
                                        <Button
                                          className="Guardar-Hc"
                                          onClick={() => {
                                            dataClinicHistoryInputs[prestacion][
                                              index
                                            ]?.fechaVisita === null &&
                                            dataClinicHistoryInputs[prestacion][
                                              index
                                            ]?.tiempoAtencion === null &&
                                            dataClinicHistoryInputs[prestacion][
                                              index
                                            ]?.detalle === null
                                              ? put(
                                                  dataClinicHistory,
                                                  prestacion,
                                                  index,
                                                  historyClinic.modificaHC
                                                )
                                              : put(
                                                  dataClinicHistoryInputs[
                                                    prestacion
                                                  ][index],
                                                  prestacion,
                                                  index,
                                                  historyClinic.modificaHC
                                                )
                                          }}
                                          disabled={edit}
                                        >
                                          Guardar
                                        </Button>
                                        {fechaHoraVisitas.every(
                                          (fechaHora) => fechaHora !== null
                                        ) ===
                                        true ? null : groupedHistoryClinics[
                                            prestacion
                                          ][index + 1]?.fechaModifica ===
                                            null &&
                                          groupedHistoryClinics[prestacion][
                                            index - 1
                                          ]?.fechaModifica !== null &&
                                          groupedHistoryClinics[prestacion][
                                            index
                                          ]?.fechaModifica !== null ? (
                                          <Button
                                            className="Resetear-Hc"
                                            onClick={() =>
                                              reset(prestacion, index)
                                            }
                                            disabled={edit}
                                          >
                                            Resetear
                                          </Button>
                                        ) : groupedHistoryClinics[prestacion][
                                            index + 1
                                          ]?.fechaModifica === undefined &&
                                          groupedHistoryClinics[prestacion][
                                            index
                                          ]?.fechaModifica !== null &&
                                          new Set(
                                            groupedHistoryClinics[prestacion]
                                          ).size === 1 ? (
                                          <Button
                                            className="Resetear-Hc"
                                            onClick={() =>
                                              reset(prestacion, index)
                                            }
                                            disabled={edit}
                                          >
                                            Resetear
                                          </Button>
                                        ) : null}
                                        <Button
                                          className="Cancelar-Hc"
                                          onClick={() => cancelar()}
                                          disabled={edit}
                                        >
                                          Cancelar
                                        </Button>
                                      </div>
                                    </div>
                                  </Panel>
                                )
                              }
                            )}
                          </Collapse>
                        </Panel>
                      ))}
                    </Collapse>
                    <div>
                      {api && api.length === 0 && (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  </Skeleton>
                </div>
              ),
            },
            {
              label: 'H.C. De Otros',
              disabled: !edit,
              key: '2',
              children: (
                <div>
                  <Skeleton loading={loading}>
                    <Collapse accordion expandIconPosition="start">
                      {Object.keys(groupedHistoryClinics).map((prestacion) => (
                        <Panel
                          header={`Prestación: ${prestacion}`}
                          key={prestacion}
                          collapsible={edit === false ? 'disabled' : 'header'}
                        >
                          <Collapse accordion expandIconPosition="start">
                            {groupedHistoryClinics[prestacion].map(
                              (historyClinic, index) => {
                                return (
                                  <Panel
                                    header={
                                      `${index + 1} ${String(getLabelVisit(historyClinic.fechaHoraVisita, historyClinic.detalle))} | ${historyClinic.usuario}`
                                    }
                                    key={index}
                                    extra={getExtra(
                                      historyClinic.fechaHoraVisita, historyClinic.detalle
                                    )}
                                  >
                                    <div className="clinicHistory--container">
                                      <div className="edit">
                                        <div>
                                          <h3 className="title-Hc">
                                            Fecha/Hora de la Visita:
                                          </h3>
                                          {historyClinic.fechaVisita ? (
                                            <Space direction="vertical">
                                              <CalendarHC
                                                defaultValue={moment(
                                                  historyClinic.fechaHoraVisita,
                                                  'YYYY-MM-DD HH:mm:ss'
                                                )}
                                                disabled={true}
                                              />
                                            </Space>
                                          ) : (
                                            <Space direction="vertical">
                                              <CalendarHC disabled={true} />
                                            </Space>
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <h3 className="title-Hc">
                                          Duración de Visita:
                                        </h3>
                                        <div>
                                          {!historyClinic.tiempoAtencion ? (
                                            <div>
                                              <Space direction="vertical" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                                <InputTime
                                                  bloquearDuracion={historyClinic.bloquearDuracion}
                                                  disabled={true}
                                                  format={'HH:mm'}
                                                /> <p>(Hora : Minuto)</p>
                                              </Space>
                                            </div>
                                          ) : (
                                            <div>
                                              <Space direction="vertical" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                                <InputTime
                                                  bloquearDuracion={historyClinic.bloquearDuracion}
                                                  defaultValue={moment(
                                                    historyClinic.tiempoAtencion,
                                                    'HH:mm'
                                                  )}
                                                  disabled={true}
                                                  format={'HH:mm'}
                                                /> <p>(Hora : Minuto)</p>
                                              </Space>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <h3 className="title-Hc">
                                          Comentario:
                                        </h3>
                                        {historyClinic.detalle ? (
                                          <div>
                                            <TextArea
                                              defaultValue={
                                                historyClinic.detalle
                                              }
                                              disabled={true}
                                              id="comentario"
                                              showCount
                                              minLength={15}
                                              rows={5}
                                              placeholder="Comments.."
                                            />
                                          </div>
                                        ) : (
                                          <div>
                                            <TextArea
                                              disabled={true}
                                              id="comentario"
                                              showCount
                                              minLength={15}
                                              rows={5}
                                              placeholder="Comments.."
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <div></div>
                                    </div>
                                  </Panel>
                                )
                              }
                            )}
                          </Collapse>
                        </Panel>
                      ))}
                    </Collapse>
                    <div>
                      {api && api.length === 0 && (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  </Skeleton>
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default ClinicHistories
