import React, { useEffect, useState } from 'react'
import { useAuthentication } from '../../../services/authContextService'
import { Modal } from 'antd'
import { NewsPost, INewsUpdateProps, FieldData } from 'interfaces'
import moment from 'moment'
import { putNewsRepositories } from 'repositories/newsRepositories'
import { set } from 'timm'
import { CustomizedForm } from './NewsCustomizedForm'

export const NewsFormUpdate = (props: INewsUpdateProps) => {
  const { modalOpen, data, isOwn, selectYear, selectMonth, setModelOpen, setFinish } = props
  const userInfo = useAuthentication().userInfo
  const [fields, setFields] = useState<FieldData[]>([])
  const currentMonth = ((new Date()).getMonth() + 1) // obtengo el mes actual
  const currentYear = ((new Date()).getFullYear()) // obtengo el año actual
  const [dataEdited, setDataEdited] = useState<NewsPost>(undefined!)

  useEffect(() => {
    setDataEdited({
      codigoEntidad: data?.codigoEntidad,
      codigoPaciente: data?.codigoPaciente,
      codigoNovedad: parseInt(data?.key.toString()),
      descripcion: data?.descripcion,
      fechaNovedad: data?.fechaNovedad,
      fechaAlta: new Date(),
      usuarioAlta: userInfo.codigoUsuario
    })
    setFields([{ name: ['descripcion'], value: data?.descripcion },
      { name: ['fechaNovedad'], value: moment(data?.fechaNovedad) },
      { name: ['nombreUsuarioAlta'], value: data?.nombreUsuarioAlta }])
  }, [data])

  return <Modal
            title={isOwn ? 'Editar Novedad' : 'Visualizar Novedad' }
            open={modalOpen}
            onOk={ async () => {
              await putNewsRepositories(dataEdited)
              setModelOpen(false)
              setFinish()
            }}
            onCancel={() => setModelOpen(false)}
            maskClosable={false}
            closable={false}>
                <CustomizedForm
                    fields={fields}
                    onChange={async (newFields) => {
                      const fieldEdited = newFields.filter(x => x.touched)[0]
                      const newData = set(dataEdited, fieldEdited.name[0], fieldEdited.value) as NewsPost
                      setDataEdited(newData)
                    }}
                    own={!isOwn}
                    year={selectYear}
                    month={selectMonth}
                    dateNowMonth={currentMonth}
                    dateNowYear={currentYear}
                />
        </Modal>
}
