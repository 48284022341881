import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Badge, Skeleton, Typography } from 'antd'
import { PatientsRepositories } from '../../repositories'
import { useFetchParams } from '../../interfaces'
import '../../styles/patients.css'
import { FaUserTie } from 'react-icons/fa'
import { authService } from '../../services/authService'
// import SelectorYearMonth from '../../components/Inputs/SelectorYearMonth'
import { useAuthentication } from '../../services/authContextService'
import SelectorYearMonth from 'components/Inputs/SelectorYearMonth'
import { Patient } from 'interfaces/patients/patients'
import { UserInfo } from 'interfaces/Common'
import { usePatient } from '../../services/patients/patientContextServices'
import { useHeaderPage } from '../../services/context/common/headerPageContextServices'

const { Text } = Typography

const ListPatients = () => {
  const usePatients = usePatient()
  const useHeader = useHeaderPage()

  useEffect(() => {
    useHeader.setHeaderPageTitle('Pacientes')
    useHeader.setOnBack('/')
    useHeader.setActiveNavBot(false)
    useHeader.setActiveMoreOptionMenu(false)
  }, [])

  const userInfo = useAuthentication().userInfo
  authService()
  const data: UserInfo = {
    user: userInfo.nombreUsuario,
    role: userInfo.codigoRol
  }
  const currentTime = new Date()
  const mes = (currentTime.getMonth() + 1).toString()
  const yearActual = currentTime.getFullYear()
  const [selectValueYear, setselectvalueYear] = useState(yearActual)
  const [selectValueMonth, setselectvalueMonth] = useState(mes)
  const [listPatients, setListPatients] = useState<Patient[]>()
  const [loading, setLoading] = useState(true)
  const params: useFetchParams = { repositorieParam: PatientsRepositories, param: data, valueYear: selectValueYear, valueMonth: selectValueMonth }
  useEffect(() => {
    setLoading(true)
    const fecth = async (values : useFetchParams) => {
      const data = await values.repositorieParam(values.param, values.valueYear, values.valueMonth)
      localStorage.setItem('selectYear', JSON.stringify(selectValueYear))
      localStorage.setItem('selectMonth', JSON.stringify(selectValueMonth))
      return data
    }
    fecth(params).then((res) => {
      setListPatients(res.data.data !== undefined ? res.data.data : res.data)
      setLoading(false)
    })
  }, [selectValueYear, selectValueMonth])
  return (
    <div className='pacientes-page'>
      <SelectorYearMonth disabledSelect={false} defaulYear={selectValueYear} defaulMonth={selectValueMonth} selectYear={function (e: React.SetStateAction<number>): void {
        setselectvalueYear(e)
      } } selectMonth={function (e: React.SetStateAction<string>): void {
        setselectvalueMonth(e)
      } }/>
        <div>
        <Skeleton loading={loading} active avatar style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px' }}>
            <ul className='lista-pacientes'>
              {listPatients && listPatients.map((pacient: any, index: any) => (
              <li className='lista-pacientes-li' key={pacient.codigoPaciente}>
                    <Link to={`/paciente/${pacient.codigoPaciente}`} onClick={() => {
                      localStorage.setItem('nombrePaciente', JSON.stringify(pacient.nombrePaciente))
                      usePatients.setData({ patientCode: pacient.codigoPaciente, entityCode: pacient.codigoEntidadContratante, fullName: pacient.nombrePaciente })
                    }} key={pacient.codigoPaciente + index} className='pacientList'>
                        <div className='patient-container'>
                            <Text style={{ display: 'Flex', alignItems: 'center', marginRight: '20px' }}><FaUserTie style={{ fontSize: '30px', marginRight: '10px' }} />{pacient.nombrePaciente}</Text>
                            <Badge
                                count={pacient.empresaContratante}
                                style={{ backgroundColor: '#52c41a' }}
                            />
                          </div>
                    </Link>
              </li>))}
            </ul>
          </Skeleton>
        </div>
    </div>
  )
}

export default ListPatients
