import { authService } from './authService'
import axios from 'axios'
import developmentsPut from '../interfaces/developments/developments'
import { OpenNotification } from '../components/Notification/notification'

const developmentsServices = async (values : any, valueYear: number, valueMonth: string) => {
  authService()
  const year = valueYear.toString()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/Evoluciones?CodigoPaciente=${values.codigoPaciente}&Año=${year}&Mes=${valueMonth}&Limit=9999999&Offset=0`)
  return await api
}
export const putdevelopments = async (params: developmentsPut) => {
  authService()
  const api = axios.put(`${process.env.REACT_APP_URI_BASE}/Evoluciones`, params).then(res => res && OpenNotification('Evolucion Actualizada', 'Se ha Actualizado correctamente la Evolucion.', 'top', 'success')).catch(() => OpenNotification('Error', 'No se pudo realizar la actualizacion.', 'top', 'error'))
  return await api
}

export const postdevelopments = async (params: developmentsPut) => {
  authService()
  const api = axios.post(`${process.env.REACT_APP_URI_BASE}/Evoluciones`, params, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => OpenNotification('Evolucion Generada', 'Se ha generado correctamente la carga.', 'top', 'success')).catch(() => OpenNotification('Error', 'No se pudo realizar la carga.', 'top', 'error'))
  return await api
}
export const deleteDelevelopments = async (params: any) => {
  authService()
  const api = axios.delete(`${process.env.REACT_APP_URI_BASE}/Evoluciones?CodigoPaciente=${params.codigoPaciente}&NombrePaciente=${params.nombrePaciente}&CodigoProfesional=${params.codigoProfesional}&NombreProfesional=${params.nombreProfesional}&FechaEvolucion=${params.fechaEvolucion}&Descripcion=${params.descripcion}&FechaAlta=${params.fechaAlta}&CodigoEntidad=${params.codigoEntidad}&A%C3%B1o=${params.año}&Mes=${params.mes}`).then(res => res && OpenNotification('Eliminacion Realizada', 'Se ha realizado correctamente la eliminacion.', 'top', 'success')).catch(() => OpenNotification('Error', 'No se pudo realizar la eliminacion.', 'top', 'error'))
  return await api
}
export default developmentsServices
