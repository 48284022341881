import { Card, Statistic } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useFetchParams } from '../../interfaces'
import facturacionRepositories from '../../repositories/facturacionRepositories'

export interface HcCallInterfece {
    year: number
    month: string
    loading: boolean
    setLoading: (e: React.SetStateAction<boolean>) => void
    setpatient: any[] | undefined
    patient: any[]
    codigoProfesional: number
  }

const ClinicHistoryCall = (props:HcCallInterfece) => {
  const [apiHistoryData, setApiHistoryData] = useState<any[]>()
  const [apiFacturacionData, setApiFacturacionData] = useState<any[]>()

  // FACTURACION
  const dataFacturacion: any = {
    codigoProfesional: props.codigoProfesional
  }
  const paramsFacturacion: useFetchParams = { repositorieParam: facturacionRepositories, param: dataFacturacion, valueYear: props.year, valueMonth: props.month }
  useEffect(() => {
    const fecthFacturacion = async (values : useFetchParams) => {
      const data = await values.repositorieParam(values.param, values.valueYear, values.valueMonth)
      return data
    }
    fecthFacturacion(paramsFacturacion).then((res) => {
      setApiFacturacionData(res.data.data !== undefined ? [res.data.data] : res.data)
    })
  }, [props.year, props.month])

  // HC
  const callHistory = () => {
    const dataHistoryPush :any = []
    props.patient && props.patient.map((e: any, index: number) => {
      const fecthHistory = async () => {
        const data = await axios.get(`${process.env.REACT_APP_URI_BASE}/HistoriaClinica?CodigoProfesional=${props.codigoProfesional}&CodigoPaciente=${e}&Allhc=0&Año=${props.year}&Mes=${props.month}&Limit=9999999&Offset=0`)
        dataHistoryPush.push(data.data)
        return dataHistoryPush
      }
      fecthHistory().then((res) => {
        setApiHistoryData(res !== undefined ? [res] : res)
      })
      return apiHistoryData
    })
  }

  useEffect(() => {
    callHistory()
  }, [props.year, props.month, props.setpatient])

  // Manejo de datos HC
  const clinicHistoryData : any = []
  const clinicHistoryDoneData : any = []
  const clinicHistoryunfinishedData : any = []
  const clinicHistoryLateData : any = []
  const dataHistory = async () => {
    const date = new Date()

    const formatDate5 = (e: Date) => {
      const month = ('00' + (props.month))
      const day = ('00' + (e.getDate() + 1))
      const hours = ('00' + (e.getHours()))
      const minute = ('00' + (e.getMinutes()))
      const seconds = ('00' + (e.getSeconds()))
      const formattedDate2 = e.getFullYear() + '-' + month.substring(month.length, month.length - 2) + '-' + day.substring(day.length, day.length - 2) + 'T' + hours.substring(hours.length, hours.length - 2) + ':' + minute.substring(minute.length, minute.length - 2) + ':' + seconds.substring(seconds.length, seconds.length - 2)
      return formattedDate2
    }

    const dateFormat = formatDate5(date)
    const clinicHistoryDone : any = []
    const clinicHistoryunfinished : any = []
    const clinicHistoryLate: any = []
    const countDataHistory : any = []

    apiHistoryData && apiHistoryData[0]?.map((e: any, index: number) => countDataHistory.push(e.count))

    apiHistoryData && apiHistoryData[0]?.map((e: any, index: number) => e.data.map((ee: any, index2: number) => ee.fechaVisita !== null && ee.detalle !== null && ee.fechaHoraVisita !== null ? clinicHistoryDone.push(ee.codigoEntidad) : null))

    apiHistoryData && apiHistoryData[0]?.map((e: any, index: number) => e.data.map((ee: any, index2: number) => ee.fechaVisita !== null && new Date(dateFormat) < new Date((ee.fechaVisita)) && ee.detalle === null ? clinicHistoryunfinished.push(ee.codigoEntidad) : null))

    apiHistoryData && apiHistoryData[0]?.map((e: any, index: number) => e.data.map((ee: any, index2: number) => ee.fechaVisita !== null && new Date(dateFormat) > new Date(ee.fechaVisita) && ee.detalle === null ? clinicHistoryLate.push(ee.codigoEntidad) : null))
    const hcAssigned = countDataHistory?.reduce((count: any, countNext: any) => count + countNext, 0)
    const done = clinicHistoryDone?.filter(el => el).length
    const unfinished = clinicHistoryunfinished?.filter(el => el).length
    const late = clinicHistoryLate?.filter(el => el).length
    clinicHistoryData.push(hcAssigned)
    clinicHistoryDoneData.push(done)
    clinicHistoryunfinishedData.push(unfinished)
    clinicHistoryLateData.push(late)
  }

  const dataGlobal = () => {
    dataHistory()
  }

  // Manejo de datos Facturacion
  const dataBillingVisitasEstimadas : any = []
  const dataBillingPendientesEstimadas : any = []
  const dataGlobalBilling = () => {
    const totalVisitasEstimadas = apiFacturacionData && apiFacturacionData[0]?.map((e: any, index: number) => parseInt(e.visitasEstimadas))
    const totalPendientesEstimadas = apiFacturacionData && apiFacturacionData[0]?.map((e: any, index: number) => parseInt(e.pendientesEstimadas))
    const totalVisitasEstimadasFinal = totalVisitasEstimadas?.reduce((VisitasEstimadas: any, VisitasEstimadasNext: any) => VisitasEstimadas + VisitasEstimadasNext, 0)
    const totalPendientesEstimadasFinal = totalPendientesEstimadas?.reduce((result: any, resultNext: any) => result + resultNext, 0)
    dataBillingVisitasEstimadas.push(totalVisitasEstimadasFinal)
    dataBillingPendientesEstimadas.push(totalPendientesEstimadasFinal)
  }
  dataGlobalBilling()
  dataGlobal()
  const gridStyle: React.CSSProperties = {
    padding: '12px',
    textAlign: 'center'
  }
  return (
    <Card className="card" title="Historias Clinicas">
      <Card.Grid style={gridStyle}>
        <Statistic className='asignados' title="Asignadas" value={clinicHistoryData} />
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <Statistic className='realizados' title="Realizadas" value={clinicHistoryDoneData} />
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <Statistic className='visitasEstimadas' title="Visitas Estimadas" value={dataBillingVisitasEstimadas} />
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <Statistic className='pendientesEstimadas' title="Pendientes Estimadas" value={dataBillingPendientesEstimadas} />
      </Card.Grid>
    </Card>
  )
}
export default ClinicHistoryCall
