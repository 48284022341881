import { authService } from './authService'
import axios from 'axios'

const facturacionServices = async (values : any, valueYear: number, valueMonth: string) => {
  const year = valueYear.toString()
  authService()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/facturacion/${values.codigoProfesional}?año=${year}&mes=${valueMonth}&limit=9999999&offset=0`)
  return await api
}
export default facturacionServices
