import { Card, Statistic } from 'antd'
import React, { useEffect, useState } from 'react'
import { useFetchParams } from '../../interfaces'
import facturacionRepositories from '../../repositories/facturacionRepositories'

export interface FacturacionCallInterfece {
  year: number
  month: string
  loading: boolean
  setLoading: (e: React.SetStateAction<boolean>) => void
  patient: any[]
  codigoProfesional: number
}

const currencyFormatter = ({ currency, amount }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    minimumFractionDigits: 2,
    currency,
  })
  return formatter.format(amount)
}

const BillingCall = (props: FacturacionCallInterfece) => {
  const [apiFacturacionData, setApiFacturacionData] = useState<any[]>()

  const dataFacturacion: any = {
    codigoProfesional: props.codigoProfesional,
  }
  const paramsFacturacion: useFetchParams = {
    repositorieParam: facturacionRepositories,
    param: dataFacturacion,
    valueYear: props.year,
    valueMonth: props.month,
  }
  useEffect(() => {
    const fecthFacturacion = async (values: useFetchParams) => {
      const data = await values.repositorieParam(
        values.param,
        values.valueYear,
        values.valueMonth
      )
      return data
    }
    fecthFacturacion(paramsFacturacion).then((res) => {
      setApiFacturacionData(
        res.data.data !== undefined ? [res.data.data] : res.data
      )
    })
  }, [props.year, props.month])

  // Manejo de datos
  const dataBillingCosto: any = []
  const dataBillingCobrar: any = []
  const dataGlobalBilling = () => {
    // console.log(apiFacturacionData && apiFacturacionData[0])
    const totalCosto =
      apiFacturacionData &&
      apiFacturacionData[0]?.map((e: any, index: number) =>
        parseInt(e.costoTotal)
      )
    const totalACobrar =
      apiFacturacionData &&
      apiFacturacionData[0]?.map((e: any, index: number) => {
        const cantHorasAprox = Math.round(
          parseInt(e.cantidad) / parseInt(e.historiaClinica)
        ) // Cantidad de horas aproximada por visitas
        const cantHorasTotalAprox =
          cantHorasAprox * (parseInt(e.historiaClinica) - parseInt(e.pendiente)) // Cantidad de horas aproximada por visitas realizadas

        return (
          parseInt(e.costo) * cantHorasTotalAprox +
          parseInt(e.costoHorasAdicionales) * parseInt(e.horasAdicionales) +
          parseInt(e.viaticoVisita) *
            (parseInt(e.historiaClinica) - parseInt(e.pendiente)) +
          parseInt(e.costoVarios) +
          parseInt(e.costoViatico) +
          parseInt(e.costoFijo)
        )
      })
    // console.log(totalACobrar)
    const totalCostoFinal = totalCosto?.reduce(
      (costoTotal: any, costoTotalNext: any) => costoTotal + costoTotalNext,
      0
    )
    const totalACobrarFinal = totalACobrar?.reduce(
      (result: any, resultNext: any) => result + resultNext,
      0
    )
    dataBillingCosto.push(totalCostoFinal)
    dataBillingCobrar.push(totalACobrarFinal)
  }
  dataGlobalBilling()
  const gridStyle: React.CSSProperties = {
    padding: '12px',
    textAlign: 'center',
  }
  return (
    <Card className="card" title="Facturacion">
      <Card.Grid style={gridStyle}>
        <Statistic
          className="asignada"
          title="Asignadas"
          value={currencyFormatter({
            currency: 'USD',
            amount: dataBillingCosto,
          })}
        />
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <Statistic
          className="aCobrar"
          title="A Cobrar"
          value={currencyFormatter({
            currency: 'USD',
            amount: dataBillingCobrar,
          })}
        />
      </Card.Grid>
    </Card>
  )
}
export default BillingCall
