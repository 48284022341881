import React, { useEffect, useState } from 'react'
import { useFetchParams } from '../interfaces'
import '../styles/geolocation.css'
import { Alert, Typography } from 'antd'
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api'
import Geocode from 'react-geocode'
import { authService } from '../services'
import SelectorYearMonth from '../components/Inputs/SelectorYearMonth'
import geolocationRepositories from '../repositories/geolocationRepositories'
import { SpinLoad } from '../components'
import { useAuthentication } from '../services/authContextService'

const { Title } = Typography

export const Geolocation = () => {
  const [loading, setLoading] = useState<boolean>(true)
  localStorage.setItem('namePage', JSON.stringify('Geolocalizacion'))
  const userInfo = useAuthentication().userInfo
  authService()

  const param = {
    codigoProfesional: userInfo.codigoProfesional
  }
  const currentTime = new Date()
  const mes = (currentTime.getMonth() + 1).toString()
  const yearActual = currentTime.getFullYear()
  const [valueYear, setselectvalueYear] = useState(yearActual)
  const [valueMonth, setselectvalueMonth] = useState(mes)
  const [api, setApi] = useState<any[]>()
  const [latitud, setLat] = useState(-32.949960)
  const [longitud, setLng] = useState(-60.681876)
  const params: useFetchParams = { repositorieParam: geolocationRepositories, param, valueYear, valueMonth }
  useEffect(() => {
    setLoading(true)
    const fecth = async (values : useFetchParams) => {
      const data = await values.repositorieParam(values.param, values.valueYear, values.valueMonth)
      return data
    }
    fecth(params).then((res) => {
      setApi(res.data.data !== undefined ? [res.data.data] : res.data)
      setLoading(false)
    })
  }, [valueYear, valueMonth])

  const filtered : any[] = api !== undefined
    ? api![0].filter((patient: any, index: any, array: any) => {
      return array !== undefined ? patient.codigoPaciente !== array.codigoPaciente : null
    })
    : null

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`
  })

  const [activeMarker, setActiveMarker] = useState(null)

  const handleActiveMarker = (marker, lat, lon) => {
    setLat(lat)
    setLng(lon)
    if (marker === activeMarker) {
      return
    }
    setActiveMarker(marker)
  }
  // Generador de lat y long
  Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_API_KEY}`)
  const getAddress = async (direccion: string, localidad: string, index: number, paciente: string) => {
    const res = await Geocode.fromAddress(direccion + ' ' + localidad)
    // const {lat, lng} = res.results[0].geometry.location
    const { lat, lng } = res.results[0].geometry.location

    return { lat, lng }
  }
  const renderMarket = (direccion: string, localidad: string, index: number, paciente: string, telefono: number, empresa: string) => {
    const latt = {
      lat: '',
      lng: ''
    }

    getAddress(direccion, localidad, index, paciente).then((res) => {
      setLat(res.lat)
      setLng(res.lng)
      latt.lat = res.lat
      latt.lng = res.lng
    })
    // console.log(latt)
    return (
      <Marker key={index} position={{ lat: latitud, lng: longitud }} onClick={() => handleActiveMarker(index, latitud, longitud)} onDragEnd={undefined}>
                  {activeMarker === index &&
                     (<InfoWindow onCloseClick={() => setActiveMarker(null)}>
                         <div className='info-geolocation'>
                          <p>Paciente: {paciente}</p>
                          <p>Direccion:{direccion} / {localidad}</p>
                          <p>Tel: {telefono}</p>
                          <p>Empresa: {empresa}</p>
                         </div>
                       </InfoWindow>)}
                  </Marker>
    )
  }

  return (
      <div>
          <SelectorYearMonth disabledSelect={false} defaulYear={valueYear} defaulMonth={valueMonth} selectYear={function (e: React.SetStateAction<number>): void {
            setselectvalueYear(e)
          } } selectMonth={function (e: React.SetStateAction<string>): void {
            setselectvalueMonth(e)
          } }/>
          <div className='geolacation-container'>
            <Title level={3}>Ubicacion de Pacientes</Title>
            <SpinLoad spinning={loading}>
                {isLoaded
                  ? <GoogleMap zoom={6} center={{ lat: latitud, lng: longitud }} onClick={() => setActiveMarker(null)} mapContainerClassName={'map--container'}>

                  {filtered && filtered.map((a: any, index: number) => {
                    return (
                      <div key={index}>
                      {a.latitud && a.longitud
                        ? <Marker key={index} position={{ lat: parseFloat(a.latitud), lng: parseFloat(a.longitud) }} onClick={() => handleActiveMarker(index, a.latitud, a.longitud)}>
                        {activeMarker === index &&
                          (<InfoWindow onCloseClick={() => setActiveMarker(null)}>
                              <div className='info-geolocation'>
                                <p>Paciente: {a.nombrePaciente}</p>
                                <p>Direccion: {a.direccionAtencion} / {a.localidad}</p>
                                <p>Tel: {a.telefono}</p>
                                <p>Empresa: {a.empresaContratante}</p>
                              </div>
                            </InfoWindow>)}
                        </Marker>
                        : renderMarket(a.direccionAtencion, a.localidad, index, a.nombrePaciente, a.telefono, a.empresaContratante)}
                        </div>
                    )
                  }
                  )
                  }
                  </GoogleMap>
                  : <Alert showIcon message={'Error al cargar el mapa'} description={'Si este error persiste por favor contacte a su proveedor de servicio.'} type={'error'} />}
            </SpinLoad>
          </div>

      </div>
  )
}
