import { NewsPost, News } from '../interfaces'
import newsDelete from '../interfaces/news/newsDelete'
import { newsServices, postNewsServices, putNewsServices } from '../services'
import { deleteNewsServices } from '../services/newsServices'

export const newsRepositories = async (values: News) => {
  return await newsServices(values)
}

export const postNewsRepositories = async (value: NewsPost) => {
  return await postNewsServices(value)
}

export const putNewsRepositories = async (value: NewsPost) => {
  return await putNewsServices(value)
}

export const deleteNewsRepositories = async (params : newsDelete) => {
  return await deleteNewsServices(params)
}
