import { Form, Input } from 'antd'
import Calendar from 'components/Inputs/Calendar'
import { CustomizedFormProps } from 'interfaces/news'
import React from 'react'

const DYAS_TO_MODIFY: number = parseInt(process.env.REACT_APP_DAYS_TO_MODIFY!) // obtengo el hasta que dia puedo modificar datos hacia atras
const ENABLED_MODIFY_OLD_RECORDS: boolean = !((new Date()).getDate() > DYAS_TO_MODIFY) // habilito a modificar valore viejos

const CustomForm = (props: CustomizedFormProps) => {
  const { onChange, fields, own, year, month, dateNowMonth, dateNowYear, form } = props

  return <Form
            form={form}
            name="global_state"
            fields={fields}
            onFieldsChange={(_, allFields) => {
              onChange(allFields)
            }}
            >
            <Form.Item
                name="fechaNovedad"
                label="Fecha Novedad"
            >
                {
                    ENABLED_MODIFY_OLD_RECORDS || (dateNowMonth === parseInt(month) && dateNowYear === year)
                      ? <Calendar notShowTime={true} disabled={own} style={{ color: 'Black' }} />
                      : <Calendar notShowTime={true} disabled={true} style={{ color: 'Black' }} />
                }
            </Form.Item>

            <Form.Item
                name="descripcion"
                label="Descripcion"
            >
                <Input.TextArea rows={6} disabled={own} style={{ color: 'Black' }}/>
            </Form.Item>
            <Form.Item
                name="nombreUsuarioAlta"
                label="Usuario"
            >
                <Input disabled={true} style={{ color: 'Black' }}/>
            </Form.Item>
            </Form>
}

export const CustomizedForm = (props: CustomizedFormProps) => {
  const { onChange, fields, own, year, month, dateNowMonth, dateNowYear, form } = props
  return <CustomForm
            form={form}
            onChange={onChange}
            fields={fields}
            own={own}
            year={year}
            month={month}
            dateNowMonth={dateNowMonth}
            dateNowYear={dateNowYear}
        />
}
