import axios from 'axios'
import { OpenNotification } from '../components/Notification/notification'
import { authService } from './authService'
import { IAnamnesis } from 'interfaces/patients/anamnesis'
export const getAnamnesisServices = async (values: any) => {
  authService()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/HistoriaClinicaAnamnesis?codigoPaciente=${values.patientCode}&limit=9999999&offset=0`).then(res => res)
  return await api
}
export const postAnamnesisServices = async (values: IAnamnesis) => {
  authService()
  const api = axios.post(`${process.env.REACT_APP_URI_BASE}/HistoriaClinicaAnamnesis`, values, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => OpenNotification('Anamnesis Generada', 'Se ha generado correctamente la carga.', 'top', 'success')).catch(() => OpenNotification('Error', 'No se pudo realizar la carga.', 'top', 'error'))
  return await api
}

export const putAnamnesisServices = async (values: IAnamnesis) => {
  authService()
  const api = axios.put(`${process.env.REACT_APP_URI_BASE}/HistoriaClinicaAnamnesis`, values, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => OpenNotification('Anamnesis Actualizada', 'Se ha Actualizado correctamente la Anamnesis.', 'top', 'success')).catch(() => OpenNotification('Error', 'No se pudo realizar la actualizacion.', 'top', 'error'))
  return await api
}
export const deleteAnamnesis = async (params: any) => {
  authService()
  const api = axios.delete(`${process.env.REACT_APP_URI_BASE}/HistoriaClinicaAnamnesis?CodigoPaciente=${params.codigoPaciente}&MotivoInternacion=${params.motivoInternacion}&DiagnosticoIngreso=${params.diagnosticoIngreso}&EnfermedadActual=${params.enfermedadActual}`).then(res => res && OpenNotification('Eliminacion Realizada', 'Se ha realizado correctamente la eliminacion.', 'top', 'success')).catch(() => OpenNotification('Error', 'No se pudo realizar la eliminacion.', 'top', 'error'))
  return await api
}
