/*eslint-disable */
import { useEffect, useState } from 'react'
import SelectorYearMonth from '../../components/Inputs/SelectorYearMonth'
import { Collapse, Empty, Skeleton, Tabs } from 'antd'
import { DetailsHC } from './details'
import CalendarHC from '../../components/Inputs/CalendarHC'

const HC = () => {
  localStorage.setItem('namePage', JSON.stringify('Historia Clinica'))
  const namePatient = localStorage.getItem('nombrePaciente')
  const namePatientClear = namePatient?.slice(1, -1)
  const [edited, setEdited] = useState(true)
  const [selectValueYear, setselectvalueYear] = useState(2023)
  const [selectValueMonth, setselectvalueMonth] = useState('8')
  const [putData, setPutData] = useState<any>()
  // eslint-disable-next-line react/react-in-jsx-scope
  return (
    <div className='contentHistory'>
      <div className='nombre-paciente'>
        <h1 className='h1-nombre-paciente'> {namePatientClear}</h1>
      </div>      
      <SelectorYearMonth disabledSelect={false} 
        defaulYear={selectValueYear} 
        defaulMonth={selectValueMonth} 
        selectYear={setselectvalueYear} 
        selectMonth={setselectvalueMonth}
      />

      <Tabs
      defaultActiveKey="1"
      className='tabHistory'
      // onChange={onchange}
      items={[
        {
          label: 'Mis H.C.',
          key: '1',
          disabled: !edited,
          children: 
            <DetailsHC month={selectValueMonth} year={selectValueYear} Editing={{edited, setEdited}}/>
        },
        {
          label: 'H.C. de Otros',
          key: '2',
          // disabled: !edited,          
          children:
            <Skeleton loading={false}>
              <CalendarHC disabled={false} onChange={(e) => {
                  setPutData({ detalle: '' })
                }}/>
            </Skeleton>          
        }
      ]}
      ></Tabs>
  </div>
  )
}

export default HC
