import React from 'react'
import {
  ChangePassword,
  ClinicHistory,
  Developments,
  Facturacion,
  Home,
  Login,
  News,
  Patient,
  ListPatients,
  Remitos
} from '../pages'
import HC from '../pages/ClinicHistory/index'
import { Routes, Route, Navigate } from 'react-router-dom'
import NewScaneo from '../components/Scaneo/NewScaneo'
import { PrivateRoute } from './PrivateRoute'
import Anamnesis from 'pages/anamnesis'
import { Agenda } from 'pages/Agenda'

export const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<PrivateRoute/>}>
        <Route path='/' element={<Home />}/>
        <Route path='/pacientes' element={<ListPatients />}/>
        <Route path='/Facturacion' element={<Facturacion />}/>
        <Route path='/Agenda' element={<Agenda />}/>
        <Route path='/Scaneo/NewScaneo' element={<NewScaneo />}/>
        <Route path='/Contrasena' element={<ChangePassword />}/>
        <Route path='/paciente/:codigoPacient' element={<Patient />}/>
        <Route path='/paciente/:codigoPacient/historia-clinica2' element={<HC />}/>
        <Route path='/paciente/:codigoPacient/historia-clinica' element={<ClinicHistory />}/>
        <Route path='/paciente/:codigoPacient/novedades' element={<News />}/>
        <Route path='/paciente/:codigoPacient/evoluciones' element={<Developments />}/>
        <Route path='/paciente/:codigoPacient/stock' element={<Remitos />}/>
        <Route path='/paciente/:codigoPacient/anamnesis' element={<Anamnesis />}/>
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  )
}
