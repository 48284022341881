import React from 'react'
import { Moment } from 'moment'
import { CalendarHC } from '../../components'

interface IVisitPeriod {
    key?: string | number
    defaultValue: Moment | string
    disabled: boolean
    dateInicio: Moment
    dateFin: Moment
    onChange: (value?: any) => void
}

export const VisitPeriod = (props: IVisitPeriod) => {
  return <div className='edit'>
  <div>
    <h3 style={{ marginBottom: '11px' }}>Fecha/Hora de la Visita:</h3>
    <CalendarHC
        key={props.key}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        onChange={props.onChange}
        dateInicio={props.dateInicio}
        dateFin={props.dateFin}
        />
    {/* { !detalle.fechaHoraVisita
      ? <CalendarHC key={props.Index} dateInicio={detalle.fechaInicio} dateFin={detalle.fechaFin} notShowTime={false} onChange={(date) => {
        const newDateHC = setIn(data, [index, 'fechaVisita'], date._d) as Detalle[]
        setData(newDateHC)
      }
    }/>
      : <CalendarHC key={props.Index} defaultValue={moment(detalle.fechaHoraVisita, 'YYYY-MM-DD HH:mm')} disabled={props.Editing.edited} onChange={(date) => {
        const newDateHC = setIn(data, [index, 'fechaVisita'], date._d) as Detalle[]
        setData(newDateHC)
      }
    }/>} */}
  </div>
</div>
}
