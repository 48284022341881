import { notification } from 'antd'
import { NotificationPlacement } from 'antd/lib/notification'

export type NotificationType = 'success' | 'warning' | 'error';
export const OpenNotification = (titleMessage: string, descriptionMessage: string, placement: NotificationPlacement, type: NotificationType) => {
  notification[type]({
    message: titleMessage,
    description: descriptionMessage,
    onClick: () => {
    },
    placement
  })
}
