import React, { useEffect } from 'react'
import { Button, Input, Form, Typography } from 'antd'
import { User } from '../interfaces'
import '../styles/changePassword.css'
import { LockOutlined } from '@ant-design/icons'
import changePasswordRepositories from '../repositories/changePasswordRepositories'
import { OpenNotification } from '../components/Notification/notification'
import { useHeaderPage } from '../services/context/common/headerPageContextServices'
// import { AuthLoginContext } from '../services/authContextService'
const { Paragraph } = Typography

export const ChangePassword = () => {
  // const userInfo = useContext(AuthLoginContext).userInfo
  const useHeader = useHeaderPage()
  useEffect(() => {
    useHeader.setHeaderPageTitle('Editar Contraseña')
    useHeader.setOnBack('/')
    useHeader.setActiveNavBot(false)
    useHeader.setActiveMoreOptionMenu(false)
  }, [])
  const nameUserLogin = localStorage.getItem('userInfo-UsuarioLogin')
  const nameUserLoginClear = nameUserLogin?.slice(1, -1)

  const userChangePassword = async (values: User) => {
    if (values.password !== values.passwordNew) {
      if (values.passwordNew === values.passwordNewConfirm) {
        await changePasswordRepositories(values, nameUserLoginClear!)
      } else OpenNotification('Error', 'Las contraseñas no coinciden, vuelve a intentar confirmar la nueva contraseña', 'top', 'error')
    } else OpenNotification('Error', 'Ingrese una nueva contraseña diferente a la actual', 'top', 'error')
  }
  return (
    <div className='changePassword--container'>
      <Paragraph style={{ padding: '20px', textAlign: 'center', fontSize: '1.2rem', fontWeight: '500' }}>Cambiar Contraseña</Paragraph>
    <Form
        className="changePassword-form"
        name="basic"
        onFinish={userChangePassword}
        autoComplete="off"
        layout={'horizontal'}
      >
        <Form.Item
        className='changePassword-form-item'
          name="password"
          rules={[{ required: true, message: 'Por favor ingresa una contraseña' }]}
        >
        <Input.Password
        prefix={<LockOutlined className="site-form-item-icon" style={{ color: '#36bc9b' }} />}
        placeholder="Contraseña Actual"/>
        </Form.Item>
        <Form.Item
        className='changePassword-form-item'
          name="passwordNew"
          rules={[{ required: true, message: 'Por favor ingresa una Nueva contraseña' }]}
        >
        <Input.Password
        prefix={<LockOutlined className="site-form-item-icon" style={{ color: '#36bc9b' }} />}
        placeholder="Nueva Contraseña"/>
        </Form.Item>
        <Form.Item
        className='changePassword-form-item'
          name="passwordNewConfirm"
          rules={[{ required: true, message: 'Por favor ingresa la Nueva contraseña para confirmar' }]}
        >
        <Input.Password
        prefix={<LockOutlined className="site-form-item-icon" style={{ color: '#36bc9b' }} />}
        placeholder="Confirmar Contraseña"/>
        </Form.Item>
        <Form.Item
        className='changePassword-form-item'
        >
        <Button type="primary" htmlType="submit" className="changePassword-form-button" style={{ background: '#36bc9b', borderColor: '#36bc9b' }}>
          Generar
        </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
