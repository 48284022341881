import React from 'react'
import { DatePicker, Space } from 'antd'
import locale from 'antd/lib/date-picker/locale/es_ES'
import 'moment/locale/es-us'
import { RangePickerProps } from 'antd/lib/date-picker'
import moment from 'moment'
import { CSSProperties } from 'styled-components'

export interface CalendarInterfece {
  notShowTime: boolean
  style: CSSProperties
  notModuleHC?: boolean
}

const Calendar = (props: CalendarInterfece | any) => {
  const { notShowTime, notModuleHC } = props
  const dateNow = new Date()
  const monthSelect = (dateNow.getMonth() + 1) // Mes Actual // props.month

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    if (moment(dateNow, 'YYYY-MM-DD').date() <= 15) {
      return moment(monthSelect, 'MM').add(-1, 'month') > current || current > moment(dateNow, 'YYYY-MM-DD').endOf('day')
    } else {
      return current < moment(dateNow, 'YYYY-MM-DD').startOf('month') || current > moment(dateNow, 'YYYY-MM-DD').endOf('day')
    }
  }

  return <Space direction='vertical' size={12}>
            <DatePicker locale={locale}
                        showTime={ notShowTime ? false : { format: 'HH:mm' }}
                        disabledDate={!notModuleHC && disabledDate}
                        inputReadOnly={true}
                        {...props}/>
         </Space>
}

export default Calendar
