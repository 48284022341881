import React, { useEffect, useState } from 'react'
import { useFetchParams, News, DataType } from '../../interfaces'
import { newsRepositories, deleteNewsRepositories } from '../../repositories'
import '../../styles/news.css'
import { Button } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import { Key } from 'antd/lib/table/interface'
import { useAuthentication } from '../../services/authContextService'
import { usePatient } from '../../services/patients/patientContextServices'
import { SpinLoad, FullNamePatient, SelectorYearMonth, FloatButtom, NewsFormUpdate, NewsFormPost } from 'components'
import { useHeaderPage } from '../../services/context/common/headerPageContextServices'

const DYAS_TO_MODIFY: number = parseInt(process.env.REACT_APP_DAYS_TO_MODIFY!) // obtengo el hasta que dia puedo modificar datos hacia atras
const ENABLED_MODIFY_OLD_RECORDS: boolean = !((new Date()).getDate() > DYAS_TO_MODIFY) // habilito a modificar valore viejos

const New = () => {
  const usePatientInfo = usePatient()
  const useHeader = useHeaderPage()

  useEffect(() => {
    useHeader.setHeaderPageTitle('Novedades')
    useHeader.setOnBack(`/Paciente/${usePatientInfo.patientCode}`)
    useHeader.setActiveNavBot(true)
    useHeader.setActiveMoreOptionMenu(false)
  }, [])
  const currenteDate = new Date()
  localStorage.setItem('numNav', JSON.stringify('2'))

  const [finish, setFinish] = useState<boolean>(false)
  const userInfo = useAuthentication().userInfo

  /** Selector de Periodos */
  const selectYear = localStorage.getItem('selectYear')
  const selectYearClear = parseInt(selectYear!)
  const selectMonth = localStorage.getItem('selectMonth')
  const selectMonthClear = selectMonth!.slice(1, -1)
  const [selectValueYear, setselectvalueYear] = useState(selectYearClear)
  const [selectValueMonth, setselectvalueMonth] = useState(selectMonthClear)
  /** */
  const [dataSource, setDataSource] = useState<DataType[]>([]) // el data source de la tabla que se renderiza
  const [dataUpdate, setDataUpdate] = useState<DataType>() // los datas actualizado que se deben guardar en la base de datos
  /* Data Modal */
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false)
  /** FloatButtoms */
  const [displayValue, setDisplayValue] = useState<boolean>(false)
  /** Own Data */
  const [isOwn, setIsOwn] = useState(true)
  /** extra */
  const [loading, setLoading] = useState(false)
  const [selectedRowKey, setSelectedRowKey] = useState<Key | undefined>(0)
  /** Periodo Actual */
  const currentMonth = (currenteDate.getMonth() + 1)
  const currenteYear = currenteDate.getFullYear()

  const handleButtonClick = async (key: React.Key, descrip, codigoEnti, codigoPac, codigoNov) => {
    // Maneja el clic en el botón usando la clave de la fila clickeada
    await deleteNewsRepositories({ codigoNovedad: codigoNov, descripcion: descrip, codigoEntidad: codigoEnti, codigoPaciente: codigoPac })
    setFinish(!finish)
  }

  /** Refactorizar */
  const showModal = () => {
    setIsModalOpen(true)
    setDisplayValue(false)
  }
  const showModalEdit = () => {
    setIsModalOpenEdit(true)
  }

  useEffect(() => {
    setLoading(true)
    setDisplayValue(false)
    const data: News = {
      valueYear: selectValueYear,
      valueMonth: selectValueMonth,
      pacientCode: usePatientInfo?.patientCode
    }
    const params: useFetchParams = { repositorieParam: newsRepositories, param: data, valueYear: selectValueYear, valueMonth: selectValueMonth }
    const fecth = async (values : useFetchParams) => {
      const data = await values.repositorieParam(values.param, values.valueYear, values.valueMonth)
      return data
    }
    fecth(params).then((res) => {
      setDataSource(res.data.data.map((el: any) => {
        return { key: el.codigoNovedad, fechaNovedad: moment(el.fechaNovedad).format('YYYY-MM-DD'), descripcion: el.descripcion, nombreUsuarioAlta: el.nombreUsuarioAlta, codigoEntidad: el.codigoEntidad, codigoPaciente: el.codigoPaciente, disabled: false, isSelected: false, codigoUsuarioAlta: el.usuarioAlta }
      }))
      setLoading(false)
    })
  }, [selectValueYear, selectValueMonth, finish, usePatientInfo.patientCode])

  const columns: ColumnsType<DataType> = [
    {
      title: 'Fecha Novedad',
      dataIndex: 'fechaNovedad'
    },
    {
      title: 'Descripcion',
      dataIndex: 'descripcion'
    },
    {
      title: 'Usuario',
      dataIndex: 'nombreUsuarioAlta'
    },
    {
      title: 'Acción',
      dataIndex: 'Accion',
      render: (_, record) => <Button disabled={!record.isSelected || !isOwn} className='button-delete' onClick={() => handleButtonClick(record.key, record.descripcion, record.codigoEntidad, record.codigoPaciente, record.key)}>Eliminar</Button>
    }
  ]
  return (
    <div style={{ backgroundColor: 'white' }}>
      <FullNamePatient />
      <SelectorYearMonth disabledSelect={false} defaulYear={selectValueYear} defaulMonth={selectValueMonth} selectYear={function (e: React.SetStateAction<number>): void {
        setselectvalueYear(e)
      } } selectMonth={function (e: React.SetStateAction<string>): void {
        setselectvalueMonth(e)
      } }/>
      <SpinLoad spinning={loading}>
        <Table
          pagination={false}
          className='tabla-news'
          rowSelection={{
            type: 'radio',
            selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
            onSelect: (record: DataType) => {
              setSelectedRowKey(record.key)
              setDisplayValue(true)
              setDataUpdate(record)
              setIsOwn(record.codigoUsuarioAlta === userInfo.codigoUsuario)
            }
          }}
          columns={columns}
          dataSource={dataSource.map(item => ({
            ...item,
            isSelected: item.key === selectedRowKey
          }))}
        />
      </SpinLoad>
      {
        (ENABLED_MODIFY_OLD_RECORDS || (currentMonth === parseInt(selectValueMonth) && currenteYear === selectValueYear)) && !isModalOpen && !isModalOpenEdit
          ? <FloatButtom type={'Add'} onClick={showModal} position={'90px'} visible={true} key={3}/>
          : undefined
      }
      {isModalOpenEdit
        ? null
        : <FloatButtom type={isOwn ? 'Edit' : 'View'} onClick={showModalEdit} position={'160px'} visible={ (parseInt(selectedRowKey?.toString()!) !== 0 && !isModalOpen) ? true : displayValue} key={1}/>
      }

      <NewsFormPost
        modalOpen={isModalOpen}
        setModelOpen={setIsModalOpen}
        data={dataUpdate!}
        isOwn={isOwn}
        selectYear={selectValueYear}
        selectMonth={selectValueMonth}
        setFinish={() => {
          setSelectedRowKey(0)
          setFinish(!finish)
        }}
      />
      <NewsFormUpdate
        modalOpen={isModalOpenEdit}
        setModelOpen={setIsModalOpenEdit}
        data={dataUpdate!}
        isOwn={isOwn}
        selectYear={selectValueYear}
        selectMonth={selectValueMonth}
        setFinish={() => {
          setSelectedRowKey(0)
          setFinish(!finish)
        }}
        />
    </div>
  )
}

export default New
/* 398 lineas de codigo */
