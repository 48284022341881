import { Form, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useFetchParams } from '../../interfaces'
import { tipoScaneoRepositories } from '../../repositories/scaneoRepositories'

export interface TipoScaneoCallCallInterfece {
    valueTipoScaneoProps: any
    setDisableTipoScaneoProps: (e: React.SetStateAction<any>) => void
    getInformationProps: (e: any) => void
    selectTipoScaneo: string
  }

const TipoScaneoCall = (props:TipoScaneoCallCallInterfece) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo')!)
  const [apiTipoScaneo, setApiTipoScaneo] = useState<any[]>()

  const dataHcScaneadaProfesional = {
    CodigoProfesional: userInfo.codigoProfesional

  }

  const paramsTipoScaneo: useFetchParams = { repositorieParam: tipoScaneoRepositories, param: dataHcScaneadaProfesional, valueYear: 0, valueMonth: '' }
  useEffect(() => {
    const fecth = async (values : useFetchParams) => {
      const data = await values.repositorieParam(values.param, values.valueYear, values.valueMonth)
      return data
    }
    fecth(paramsTipoScaneo).then((res) => {
      setApiTipoScaneo(res.data.data !== undefined ? [res.data.data] : res.data)
    })
  }, [])

  const tipoScaneo: any[] = apiTipoScaneo! && apiTipoScaneo[0]!.map((e: any, index: number) => ({ value: e.codigoTipoScaneo, label: e.descripcion }))

  const tipoScaneoOptions = tipoScaneo && tipoScaneo.slice().sort((a, b) => a.label.localeCompare(b.label))
  useEffect(() => {
    apiTipoScaneo! && apiTipoScaneo[0]!.filter((e: any, index: number) => props.valueTipoScaneoProps === e.codigoTipoScaneo ? props.setDisableTipoScaneoProps({ codigoPerioricidad: e.codigoPerioricidad, codigoTipoScaneo: e.codigoTipoScaneo, descripcion: e.descripcion, fechaAlta: e.fechaAlta, grabaCodigoHc: e.grabaCodigoHc, obligatorio: e.obligatorio, usaAño: e.usaAño, usaFechaVencimiento: e.usaFechaVencimiento, usaMes: e.usaMes, usaPaciente: e.usaPaciente, usaProfesional: e.usaProfesional, usuarioAlta: e.usuarioAlta }) : null)
  }, [props.valueTipoScaneoProps])

  return (
      <Form.Item
            name={'Select-TipoScaneo'}
            rules={[{ required: true, message: 'Ingresar Tipo Scaneo' }]}
          >
            <Select
            className={props.selectTipoScaneo}
            placeholder="Tipo Scaneo"
            optionFilterProp="children"
            onChange={(e) => props.getInformationProps(e)}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={tipoScaneoOptions}
          />
      </Form.Item>

  )
}
export default TipoScaneoCall
