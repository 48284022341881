import axios from 'axios'
import { OpenNotification } from '../components/Notification/notification'
import { News, NewsPost } from '../interfaces/'
import { authService } from './authService'

export const newsServices = async (values: News) => {
  authService()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/novedades?mes=${values.valueMonth}&año=${values.valueYear}&codigopaciente=${values.pacientCode}&limit=9999999&offset=0`).then(res => res)
  return await api
}
export const postNewsServices = async (values: NewsPost) => {
  authService()
  const api = axios.post(`${process.env.REACT_APP_URI_BASE}/novedades`, values, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => OpenNotification('Novedad Generada', 'Se ha generado correctamente la carga.', 'top', 'success')).catch(() => OpenNotification('Error', 'No se pudo realizar la carga.', 'top', 'error'))
  return await api
}

export const putNewsServices = async (values: NewsPost) => {
  authService()
  const api = axios.put(`${process.env.REACT_APP_URI_BASE}/novedades`, values, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => OpenNotification('Novedad Actualizada', 'Se ha Actualizado correctamente la novedad.', 'top', 'success')).catch(() => OpenNotification('Error', 'No se pudo realizar la actualizacion.', 'top', 'error'))
  return await api
}
export const deleteNewsServices = async (params: any) => {
  authService()
  const api = axios.delete(`${process.env.REACT_APP_URI_BASE}/novedades?CodigoEntidad=${params.codigoEntidad}&CodigoPaciente=${params.codigoPaciente}&CodigoNovedad=${params.codigoNovedad}&Descripcion=${params.descripcion}`).then(res => res && OpenNotification('Eliminacion Realizada', 'Se ha realizado correctamente la eliminacion.', 'top', 'success')).catch(() => OpenNotification('Error', 'No se pudo realizar la eliminacion.', 'top', 'error'))
  return await api
}
