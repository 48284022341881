import React from 'react'
import { DatePicker, Space } from 'antd'
import locale from 'antd/lib/date-picker/locale/es_ES'
import 'moment/locale/es-us'
import { RangePickerProps } from 'antd/lib/date-picker'
import moment, { Moment } from 'moment'
import { CSSProperties } from 'styled-components'

export interface CalendarInterfece {
  notShowTime: boolean
  style: CSSProperties
  previusDate: any
  dateInicio: any | Moment
  dateFin: any | Moment
}

const CalendarHC = (props: CalendarInterfece | any) => {
  const dateNow = new Date()
  const monthSelect = (dateNow.getMonth() + 1) // Mes Actual // props.month
  const notShowTime = props.notShowTime
  const datePrevius = new Date(props.previusDate)
  const dateInicio = moment(dateNow).date() > 15 ? new Date(props.dateInicio) : new Date(props.previusDate)
  const dateFin = new Date(props.dateFin)
  const dateFinFix = moment(dateFin).format('YYYY-MM-DD') + ' 23:59:59'
  const dateFinFixed = new Date(dateFinFix)

  const lastHour = moment(datePrevius).hour()
  const lastMinute = moment(datePrevius).minute()

  const disabledTime = (current: Moment | null) => {
    if (!current) {
      return {}
    }

    if (moment(current).isSame(datePrevius, 'day')) {
      // Si la fecha seleccionada es igual a la fecha de datePrevius,
      // deshabilita las horas y minutos anteriores a la última hora ingresada
      return {
        disabledHours: () => Array.from({ length: lastHour }, (_, i) => i),
        disabledMinutes: () => Array.from({ length: lastMinute }, (_, i) => i)
      }
    }

    return {}
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const isLastDateSameAsToday = moment(datePrevius).isSame(current, 'day')

    if ((dateNow !== datePrevius && dateNow > datePrevius)) {
      return (
        !isLastDateSameAsToday && (
          (moment(dateInicio) > current ||
            moment(dateFinFixed) < current) ||
          (moment(monthSelect, 'MM').add(-1, 'month') > current ||
            current < moment(datePrevius, 'YYYY-MM-DD').endOf('day') ||
            current > moment(dateNow, 'YYYY-MM-DD').endOf('day'))
        )
      )
    } else if (moment(dateNow, 'YYYY-MM-DD').date() <= 15) {
      return (
        !isLastDateSameAsToday && (
          (moment(monthSelect, 'MM').add(-1, 'month') > current ||
            moment(dateFinFixed) < current) ||
          (moment(monthSelect, 'MM').add(-1, 'month') > current ||
            current < moment(datePrevius, 'YYYY-MM-DD').endOf('day') ||
            current > moment(dateNow, 'YYYY-MM-DD').endOf('day'))
        )
      )
    } else {
      return (
        (moment(dateInicio) > current ||
          moment(dateFinFixed) < current) ||
        (current < moment(dateNow, 'YYYY-MM-DD').startOf('month') ||
          current > moment(dateNow, 'YYYY-MM-DD').endOf('day'))
      )
    }
  }

  return <Space direction='vertical' size={12}>
            <DatePicker locale={locale}
                        showTime={ notShowTime
                          ? false
                          : {
                              format: 'HH:mm'
                            }}
                        disabledDate={disabledDate}
                        disabledTime={disabledTime}
                        inputReadOnly={true}
                        {...props}/>
         </Space>
}

export default CalendarHC
