import axios from 'axios'
import { authService } from './authService'

const geolocationServices = async (param: any, valueYear: number, valueMonth: string) => {
  const year = valueYear.toString()
  authService()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/pacientes?CodigoProfesional=${param.codigoProfesional}&A%C3%B1o=${year}&Mes=${valueMonth}&Limit=3&Offset=0`).then(res => res)
  return await api
}

export default geolocationServices
