import React from 'react'
import TopBarDesktop from './desktop'
import TopBarMobile from './mobile'
import '../topBar/topBar.css'
import { useIsMobile } from 'services/responsive'

export interface TopbarProps {
    collapsed: boolean
    onCollapse(): void
    page: string | null | undefined
}

const TopBar = (props: TopbarProps) => {
  return !useIsMobile()
    ? (<TopBarDesktop {...props}/>)
    : (<TopBarMobile {...props}/>)
}

export default TopBar
