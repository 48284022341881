import axios from 'axios'
import { OpenNotification } from '../components/Notification/notification'
import { authService } from './authService'
import { LogoutChangePassword } from './loginService'

const changePasswordServices = async (param: any, usuario: string) => {
  authService()
  const api = axios.get(`${process.env.REACT_APP_URI_BASE}/Account/ChangePassword?usuario=${usuario}&password=${param.passwordNew}&oldpassword=${param.password}`)
    .then((res) => LogoutChangePassword()).catch(() => OpenNotification('Error', 'No se pudo realizar la modificacion.', 'top', 'error'))
  return await api
}

export default changePasswordServices
