import developmentsPut from '../interfaces/developments/developments'
import developmentsDelete from '../interfaces/developments/developmentsDelete'
import { developmentsServices } from '../services'
import { deleteDelevelopments, postdevelopments, putdevelopments } from '../services/developmentsServices'

const developmentsRepositories = async (values:any, valueYear: number, valueMonth: string) => {
  return await developmentsServices(values, valueYear, valueMonth)
}
export const developmentsRepoPut = async (params : developmentsPut) => {
  return await putdevelopments(params)
}
export const developmentsRepoPost = async (params : developmentsPut) => {
  return await postdevelopments(params)
}
export const developmentsRepoDelete = async (params : developmentsDelete) => {
  return await deleteDelevelopments(params)
}

export default developmentsRepositories
