import React from 'react'
import { TopbarProps } from './topBar'

export interface TopBarDesktopProps extends TopbarProps {}

const TopBarDesktop = (props: TopBarDesktopProps) => {
  return (
    <div className='top-bar-desktop'>
      <span className='titulo-page'>{props.page}</span>
    </div>
  )
}

export default TopBarDesktop
