import React from 'react'
import { MenuOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { TopbarProps } from './topBar'
// import { Menu } from 'antd'

export interface TopBarMobileProps extends TopbarProps {}

const TopBarMobile = (props: TopBarMobileProps) => {
  return (
    <div className='top-bar-mobile'>
      <Button onClick={props.onCollapse}><MenuOutlined /></Button>
      <span className='titulo-page'>{props.page}</span>
    </div>
  )
}

export default TopBarMobile
