import React, { useEffect, useState } from 'react'
import Table, { ColumnsType } from 'antd/lib/table'
import { useFetchParamsAnamnesis } from 'interfaces/useFetchParams'
import { getAnamnesisRepositories, putAnamnesisRepositories } from 'repositories/anamnesisRepositories'
import { Form, Input, Modal } from 'antd'
import { set } from 'timm'
import { useAuthentication } from '../services/authContextService'
import { FloatButtom, FullNamePatient, SpinLoad } from 'components'
import { FieldData } from 'interfaces'
import { usePatient } from 'services/patients/patientContextServices'
import { useHeaderPage } from '../services/context/common/headerPageContextServices'

interface DataType {
  key: React.Key;
  motivoInternacion: string;
  diagnosticoIngreso: string;
  enfermedadActual:string;
  codigoPaciente: number;
  disabled:boolean,
  isSelected: boolean,
  patient: string
}

interface CustomizedFormProps {
  onChange: (fields: FieldData[]) => void;
  fields: FieldData[];
}

const CustomizedForm: React.FC<CustomizedFormProps> = ({
  onChange,
  fields
}) => (
  <Form
    name="global_state"
    fields={fields}
    onFieldsChange={(_, allFields) => {
      onChange(allFields)
    }}
  >
    <Form.Item
      name="motivoInternacion"
      label="Motivo Internacion"
    >
      <Input.TextArea rows={6} style={{ color: 'Black' }}/>
    </Form.Item>
    <Form.Item
      name="diagnosticoIngreso"
      label="Diagnostico Ingreso"
    >
      <Input.TextArea rows={6} style={{ color: 'Black' }}/>
    </Form.Item>
    <Form.Item
      name="enfermedadActual"
      label="Enfermedad Actual"
    >
      <Input.TextArea rows={6} style={{ color: 'Black' }}/>
    </Form.Item>
  </Form>
)

const Anamnesis = () => {
  const currenteDate = new Date()
  const userInfo = useAuthentication().userInfo
  const useHeader = useHeaderPage()
  const usePatientInfo = usePatient()
  const patientCode = usePatientInfo.patientCode

  useEffect(() => {
    useHeader.setHeaderPageTitle('Anamnesis')
    useHeader.setOnBack(`/Paciente/${usePatientInfo.patientCode}`)
    useHeader.setActiveNavBot(true)
    useHeader.setActiveMoreOptionMenu(false)
  }, [])

  const [hsAnamnesis, setHsAnamnesis] = useState<DataType[]>(undefined!)
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false)
  const [fields, setFields] = useState<FieldData[]>([])
  const [disabledFloatButton, setDisabledFloatButton] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [saveData, setSaveData] = useState<boolean>(false)
  const [loadingModal, setLoadingModal] = useState(false)

  const data: any = {
    patientCode
  }
  const getDataApi = async () => {
    setIsLoading(true)
    const fecth = async (values : useFetchParamsAnamnesis) => {
      const data = await values.repositorieParam(values.param)
      return data
    }
    await fecth(params).then((res) => {
      setHsAnamnesis(res.data.data.map((el: any, index: number) => {
        return { key: index, motivoInternacion: el.motivoInternacion, diagnosticoIngreso: el.diagnosticoIngreso, enfermedadActual: el.enfermedadActual, codigoPaciente: el.codigoPaciente, disabled: false, isSelected: false, patient: usePatientInfo.fullName }
      }))
    })
    setIsLoading(false)
  }
  const params: useFetchParamsAnamnesis = { repositorieParam: getAnamnesisRepositories, param: data }
  useEffect(() => {
    getDataApi()
  }, [saveData])

  const columns: ColumnsType<DataType> = [
    {
      title: 'Paciente',
      dataIndex: 'patient'
    },
    {
      title: 'Motivo Internacion',
      dataIndex: 'motivoInternacion'
    },
    {
      title: 'Diagnostico Ingreso',
      dataIndex: 'diagnosticoIngreso'
    },
    {
      title: 'Enfermedad Actual',
      dataIndex: 'enfermedadActual'
    }
  ]

  const handleSave = async () => {
    setLoadingModal(true)
    await putAnamnesisRepositories({ ...hsAnamnesis[0], usuarioModifica: userInfo.codigoUsuario, fechaModifica: currenteDate })
    setLoadingModal(false)
    setIsModalOpenEdit(false)
    setSaveData(!saveData)
  }
  return <div style={{ backgroundColor: 'white' }}>
            <FullNamePatient />
            <SpinLoad spinning={isLoading}>
              <Table
                pagination={false}
                className='tabla-news'
                rowSelection={{
                  type: 'radio',
                  onSelect: (record: DataType) => {
                    setDisabledFloatButton(false)
                    setFields([{ name: ['motivoInternacion'], value: record.motivoInternacion }, { name: ['diagnosticoIngreso'], value: record.diagnosticoIngreso }, { name: ['enfermedadActual'], value: record.enfermedadActual }])
                  },
                }}
                columns={columns}
                dataSource={hsAnamnesis?.map(item => ({
                  ...item,
                  isSelected: true
                }))}
              />
            </SpinLoad>

            { isModalOpenEdit || disabledFloatButton
              ? null
              : <>
                  <FloatButtom type={'Edit'} onClick={() => setIsModalOpenEdit(true)} position={'160px'} visible={true} key={1}></FloatButtom>
               </>
            }

            <Modal
              title={ 'Editar Anamnesis' }
              open={isModalOpenEdit}
              onOk={handleSave}
              onCancel={() => {
                setIsModalOpenEdit(false)
                setSaveData(!saveData)
              }}
              maskClosable={false}
              closable={false}
              confirmLoading={loadingModal}>

              <CustomizedForm
                fields={fields}
                onChange={(newFields) => {
                  const newAnamnesis = set(set(set(hsAnamnesis[0], 'motivoInternacion', newFields[0].value), 'diagnosticoIngreso', newFields[1].value), 'enfermedadActual', newFields[2].value)
                  setHsAnamnesis([newAnamnesis])
                }}
              />
            </Modal>

          </div>
}

export default Anamnesis
