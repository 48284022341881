import { Input, Select, Typography } from 'antd'
import React from 'react'
import '../../styles/select.css'

export interface SelectProps {
    defaulYear: number
    defaulMonth: string
    selectYear: (value: number) => void
    selectMonth: (value: string) => void
    disabledSelect: boolean
}

const SelectorYearMonth = (props: SelectProps) => {
  const currentTime = new Date()
  const year = currentTime.getFullYear()
  const { Option } = Select
  const { Text } = Typography

  return (
    <>
    <Input.Group compact className='select'>
          <Text className='Select-Text'>Año</Text>
          <Select disabled={props.disabledSelect} defaultValue={props.defaulYear} onChange={props.selectYear}>
              <Option value={year - 3}>{year - 3}</Option>
              <Option value={year - 2}>{year - 2}</Option>
              <Option value={year - 1}>{year - 1}</Option>
              <Option value={year}>{year}</Option>
              <Option value={year + 1}>{year + 1}</Option>
          </Select>
          <Text className='Select-Text'>Mes</Text>
          <Select disabled={props.disabledSelect} defaultValue={props.defaulMonth} onChange={props.selectMonth} style={{ width: 120 }} listHeight={400} >
              <Option value="1">Enero</Option>
              <Option value="2">Febrero</Option>
              <Option value="3">Marzo</Option>
              <Option value="4">Abril</Option>
              <Option value="5">Mayo</Option>
              <Option value="6">Junio</Option>
              <Option value="7">Julio</Option>
              <Option value="8">Agosto</Option>
              <Option value="9">Septiembre</Option>
              <Option value="10">Octubre</Option>
              <Option value="11">Noviembre</Option>
              <Option value="12">Diciembre</Option>
          </Select>
      </Input.Group>
      </>
  )
}
export default SelectorYearMonth
