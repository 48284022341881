import React, { createContext, useContext, useState } from 'react'
import { IPatientContext, ISetDataContext } from '../../interfaces/patients/patients'

export const PatientContext = createContext<IPatientContext>(undefined!)

export const usePatient = () => useContext(PatientContext)

export const PatientContextProvider = (props: any) => {
  const [patientCode, setPatientCode] = useState<number>(0)
  const [entityCode, setEntityCode] = useState<number>(0)
  const [fullName, setFullName] = useState<string>('')

  const setData = (data: ISetDataContext) => {
    const { patientCode, entityCode, fullName } = data
    setPatientCode(patientCode)
    setEntityCode(entityCode)
    setFullName(fullName)
    localStorage.setItem('patientCode', patientCode.toString())
    localStorage.setItem('entityCode', entityCode.toString())
    localStorage.setItem('fullName', fullName)
  }

  const validaData = () => {
    const patientCode = localStorage.getItem('patientCode')
    const entityCode = localStorage.getItem('entityCode')
    const fullName = localStorage.getItem('fullName')
    if (patientCode && entityCode && fullName) {
      setPatientCode(parseInt(patientCode))
      setEntityCode(parseInt(entityCode))
      setFullName(fullName)
    }
  }

  const contextValue: IPatientContext = {
    patientCode,
    entityCode,
    fullName,
    setData,
    validaData
  }

  return <PatientContext.Provider value={contextValue}>{props.children}</PatientContext.Provider>
}
