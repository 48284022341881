import React from 'react'
import { Menu, Image, Divider } from 'antd'
import { Link } from 'react-router-dom'
import { Logout } from '../../services/loginService'
import styled from 'styled-components'
import { useIsMobile } from '../../services/responsive'
import CurarteIcon from '../Common/CurarteIcons'
import { useHeaderPage } from '../../services/context/common/headerPageContextServices'
import '../../styles/menu.css'

const MenuContainer = styled.div`
  padding: 10px 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  transition: all 1s ease-out;
`

const IamgeMenu = (props: any) => {
  const marginLeft = !useIsMobile()
    ? {
        marginLeft: props.inlineCollapsed ? '5px' : '25px',
      }
    : {
        marginLeft: '0px',
      }
  const uriIconMenu = props.inlineCollapsed ? 'iso-01.png' : 'logo.png'
  const width = !useIsMobile() ? (props.inlineCollapsed ? undefined : 150) : 270
  return (
    <Image
      preview={false}
      width={width}
      src={require(`../../assets/${uriIconMenu}`)}
      style={marginLeft}
    />
  )
}
const DividerVersion = (props: any) => {
  const displayLabel = props.inlineCollapsed ? 'none' : 'inline'
  return (
    <Divider orientation="right" plain>
      <label style={{ display: displayLabel }}>V1.4.3 - 20240819</label>
    </Divider>
  )
}

const items = [
  {
    key: 1,
    label: <Link to="/">Inicio</Link>,
    title: '',
    icon: <CurarteIcon name={'HomeOutlined'} />,
  },
  {
    key: 2,
    label: <Link to="/pacientes">Pacientes</Link>,
    title: 'Pacientes',
    icon: <CurarteIcon name={'UserSwitchOutlined'} />,
  },
  {
    key: 3,
    label: <Link to="/Agenda">Agenda</Link>,
    title: 'Agenda',
    icon: <CurarteIcon name={'SnippetsOutlined'} />,
  },
  {
    key: 4,
    label: <Link to="/Facturacion">Facturación</Link>,
    title: 'Facturación',
    icon: <CurarteIcon name={'DollarOutlined'} />,
  },
  {
    key: 5,
    label: <Link to="/Contrasena">Cambiar contraseña</Link>,
    title: 'Cambiar contraseña',
    icon: <CurarteIcon name={'KeyOutlined'} />,
  },
  {
    key: 6,
    label: (
      <Link onClick={() => Logout()} to="#">
        Cerrar sesion
      </Link>
    ),
    title: 'Cerrar sesion',
    icon: <CurarteIcon name={'LogoutOutlined'} />,
  },
]

const MenuApp = (props: any) => {
  const useHeader = useHeaderPage()

  return (
    <MenuContainer>
      <IamgeMenu {...props} />
      <DividerVersion {...props} />
      <Menu
        defaultSelectedKeys={['1']}
        mode="inline"
        theme="light"
        items={items}
        onClick={(data) => {
          const selected = items.find(el => el.key === Number(data.key))!
          useHeader.setHeaderPageTitle(selected.title)
        }}
        {...props}
      />
    </MenuContainer>
  )
}

export default MenuApp
