import Axios, { AxiosRequestConfig } from 'axios'
import { OpenNotification } from '../components/Notification/notification'
import { ClinicHistory } from '../interfaces'
import { authService } from './authService'

export const clininHistotyServices = async (values: ClinicHistory, valueYear: number, valueMonth: string) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '[]')
  const year = valueYear.toString()
  authService()
  const api = Axios.get(`${process.env.REACT_APP_URI_BASE}/HistoriaClinica?CodigoProfesional=${userInfo.codigoProfesional}&CodigoPaciente=${values.patientCode}&Allhc=${values.numAllHc}&Año=${year}&Mes=${valueMonth}&Limit=9999999&Offset=0`)
  return await api
}

export const GetClininHistory = async (params: ClinicHistory, valueYear: number, valueMonth: string) => {
  const year = valueYear.toString()
  authService()
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${process.env.REACT_APP_URI_BASE}/HistoriaClinica`,
    params: {
      CodigoProfesional: params.profesionalCode,
      CodigoPaciente: params.patientCode,
      Allhc: params.numAllHc,
      Año: year,
      Mes: valueMonth,
      Limit: 9999,
      Offset: 0
    }
  }
  try {
    const response = await Axios(config)

    return response
  } catch (error) {
    OpenNotification('Error', 'No se pudo realizar la carga.', 'top', 'error')
    return null
  }
}

export const putClinicHistory = async (params: any) => {
  authService()
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${process.env.REACT_APP_URI_BASE}/HistoriaClinica`,
    data: params
  }
  try {
    const response = await Axios(config)
    if (response) { OpenNotification('Carga Realizada', 'Se ha realizado correctamente la carga.', 'top', 'success') }

    return response
  } catch (error) {
    OpenNotification('Error', 'No se pudo realizar la carga.', 'top', 'error')
  }
  // const api = axios.put(`${process.env.REACT_APP_URI_BASE}/HistoriaClinica`, params).then(res => res && OpenNotification('Carga Realizada', 'Se ha realizado correctamente la carga.', 'top', 'success')).catch(() => OpenNotification('Error', 'No se pudo realizar la carga.', 'top', 'error'))
}
