import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'antd'
import { INewsUpdateProps } from 'interfaces/news'
import { CustomizedForm } from './NewsCustomizedForm'
import { usePatient } from '../../../services/patients/patientContextServices'
import { NewsPost } from 'interfaces'
import { useAuthentication } from '../../../services/authContextService'
import { postNewsRepositories } from 'repositories/newsRepositories'
import { set } from 'timm'

export const NewsFormPost = (props: INewsUpdateProps) => {
  const { modalOpen, isOwn, selectYear, selectMonth, setModelOpen, setFinish } = props
  const usePatientInfo = usePatient()
  const userInfo = useAuthentication().userInfo
  const currentMonth = ((new Date()).getMonth() + 1) // obtengo el mes actual
  const currentYear = ((new Date()).getFullYear()) // obtengo el año actual
  const [dataPost, setDataPost] = useState<NewsPost>(undefined!)
  useEffect(() => {
    usePatientInfo.validaData()
  }, [])
  useEffect(() => {
    const defaultValue: NewsPost = {
      codigoEntidad: usePatientInfo.entityCode,
      codigoPaciente: usePatientInfo.patientCode,
      codigoNovedad: 0,
      descripcion: '',
      fechaNovedad: undefined,
      fechaAlta: new Date(),
      usuarioAlta: userInfo.codigoUsuario
    }
    setDataPost(defaultValue)
  }, [usePatientInfo.entityCode, usePatientInfo.patientCode])

  const [form] = Form.useForm()
  return <Modal
            title="Nueva Novedad"
            open={modalOpen}
            onOk={ async () => {
              await postNewsRepositories(dataPost)
              setModelOpen(false)
              form.resetFields()
              setFinish()
            }}
            onCancel={() => setModelOpen(false)}
            maskClosable={false}
            closable={false}
        >
            <CustomizedForm
                fields={[{ name: 'nombreUsuarioAlta', value: userInfo.nombreUsuario }]}
                form={form}
                onChange={async (newFields) => {
                  newFields.map(el => {
                    if (el.touched) {
                      const newData = set(dataPost, el.name[0].toString(), el.value) as NewsPost
                      setDataPost(newData)
                    }
                    return ''
                  })
                }}
                own={!isOwn}
                year={selectYear}
                month={selectMonth}
                dateNowMonth={currentMonth}
                dateNowYear={currentYear}
            />
        </Modal>
}
