import axios from 'axios'
import { LogoutTokenExpired } from './loginService'

export const authService = () => {
  const token = localStorage.getItem('token')
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common.Authorization
  }

  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response?.status === 401) {
        LogoutTokenExpired()
      }

      return Promise.reject(error)
    }
  )
}
