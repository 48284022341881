import React, { useEffect, useState } from 'react'
import { useFetchParams } from '../interfaces'
import '../styles/billingCss.css'
import SelectorYearMonth from '../components/Inputs/SelectorYearMonth'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import facturacionRepositories from '../repositories/facturacionRepositories'
import { authService } from '../services'
import { useAuthentication } from '../services/authContextService'
import { SpinLoad } from '../components'
import { useHeaderPage } from '../services/context/common/headerPageContextServices'

const currencyFormatter = ({ currency, amount }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    minimumFractionDigits: 2,
    currency
  })
  return formatter.format(amount)
}

interface DataType {
  key: React.Key;
  Cant: number,
  Costo: number,
  CostoFijo: number,
  CostoHorasAdicionales: number,
  CostoTotal: number,
  CostoVarios: number,
  CostoViatico: number,
  Especialidad: string,
  Frecuencia: string,
  HistoriaClinica: any,
  HorasAdicionales: number,
  Paciente: string,
  Pendiente: number,
  ViaticoVisita: number,
  VisitasEstimadas: number,
  PendientesEstimadas: number
}
const columns: ColumnsType<DataType> = [
  {
    title: 'Pacientes',
    dataIndex: 'Paciente',
    fixed: true
  },
  {
    title: 'Especialidad',
    dataIndex: 'Especialidad'
  },
  {
    title: 'Frecuencia',
    dataIndex: 'Frecuencia'
  },
  {
    title: 'Cant',
    dataIndex: 'Cant',
    align: 'center'
  },
  {
    title: 'Costo',
    dataIndex: 'Costo',
    align: 'right',
    render: (amount) => {
      return currencyFormatter({
        currency: 'USD',
        amount
      })
    }
  },
  {
    title: 'Sub-Total',
    align: 'right',
    render: (el) => {
      const amount = el.Costo * el.Cant
      return <span style={{ fontWeight: 'bold' }}>{currencyFormatter({
        currency: 'USD',
        amount
      })}</span>
    }
  },
  {
    title: 'Adicional de Horas',
    children: [
      {
        title: 'Hs. Adicionales',
        dataIndex: 'HorasAdicionales',
        align: 'center'
      },
      {
        title: 'Costo Hs. Adicionales',
        dataIndex: 'CostoHorasAdicionales',
        align: 'right',
        render: (amount) => {
          return currencyFormatter({
            currency: 'USD',
            amount
          })
        }
      },
      {
        title: 'Sub-Total',
        align: 'right',
        render: (el) => {
          const amount = el.CostoHorasAdicionales * el.HorasAdicionales
          return <span style={{ fontWeight: 'bold' }}>{currencyFormatter({
            currency: 'USD',
            amount
          })}</span>
        }
      }
    ]
  },
  {
    title: 'Adicional Visita',
    children: [
      {
        title: 'Viatico Visita',
        dataIndex: 'ViaticoVisita',
        align: 'right',
        render: (amount) => {
          return currencyFormatter({
            currency: 'USD',
            amount
          })
        }
      },
      {
        title: 'Sub-Total',
        align: 'right',
        render: (el) => {
          const amount = el.ViaticoVisita * el.Cant
          return <span style={{ fontWeight: 'bold' }}>{currencyFormatter({
            currency: 'USD',
            amount
          })}</span>
        }
      }
    ]
  },
  {
    title: 'Viaticos',
    children: [
      {
        title: 'Costo Varios',
        dataIndex: 'CostoVarios',
        align: 'right',
        render: (amount) => {
          return currencyFormatter({
            currency: 'USD',
            amount
          })
        }
      },
      {
        title: 'Costo Viatico',
        dataIndex: 'CostoViatico',
        align: 'right',
        render: (amount) => {
          return currencyFormatter({
            currency: 'USD',
            amount
          })
        }
      },
      {
        title: 'Costo Fijo',
        dataIndex: 'CostoFijo',
        align: 'right',
        render: (amount) => {
          return currencyFormatter({
            currency: 'USD',
            amount
          })
        }
      },
      {
        title: 'Sub-Total',
        align: 'right',
        render: (el) => {
          const amount = el.CostoVarios + el.CostoViatico + el.CostoFijo
          return <span style={{ fontWeight: 'bold' }}>{currencyFormatter({
            currency: 'USD',
            amount
          })}</span>
        }
      }
    ]
  },
  {
    title: 'Costo Total',
    dataIndex: 'CostoTotal',
    align: 'right',
    render: (amount) => {
      return <span style={{ fontWeight: 'bold' }}>{currencyFormatter({
        currency: 'USD',
        amount
      })}</span>
    }
  },
  {
    title: 'H.C.',
    dataIndex: 'HistoriaClinica',
    align: 'center'
  },
  {
    title: 'H.C. Pendiente',
    dataIndex: 'Pendiente',
    align: 'center'
  },
  {
    title: 'Visitas Estimadas',
    dataIndex: 'VisitasEstimadas',
    align: 'center'
  },
  {
    title: 'Pendientes Estimadas',
    dataIndex: 'PendientesEstimadas',
    align: 'center'
  }
]

export const Facturacion = () => {
  const useHeader = useHeaderPage()
  useEffect(() => {
    useHeader.setHeaderPageTitle('Facturacion')
    useHeader.setOnBack('/')
    useHeader.setActiveNavBot(false)
    useHeader.setActiveMoreOptionMenu(false)
  }, [])
  const [loading, setLoading] = useState<boolean>(true)
  const userInfo = useAuthentication().userInfo
  authService()

  const param = {
    codigoProfesional: userInfo.codigoProfesional
  }
  const currentTime = new Date()
  const mes = (currentTime.getMonth() + 1).toString()
  const yearActual = currentTime.getFullYear()
  const [valueYear, setselectvalueYear] = useState(yearActual)
  const [valueMonth, setselectvalueMonth] = useState(mes)
  const [data, setData] = useState<DataType[]>([])
  const params: useFetchParams = { repositorieParam: facturacionRepositories, param, valueYear, valueMonth }
  useEffect(() => {
    const fecth = async (values : useFetchParams) => {
      const data = await values.repositorieParam(values.param, values.valueYear, values.valueMonth)
      return data
    }
    fecth(params).then((res) => {
      setData(res.data.data.map((el: any, index: number) => {
        return {
          key: index,
          Paciente: el.nombrePaciente,
          Especialidad: el.especialidad,
          Frecuencia: el.frecuencia,
          Cant: el.cantidad,
          Costo: el.costo,
          HorasAdicionales: el.horasAdicionales,
          CostoHorasAdicionales: el.costoHorasAdicionales,
          ViaticoVisita: el.viaticoVisita,
          CostoVarios: el.costoVarios,
          CostoViatico: el.costoViatico,
          CostoFijo: el.costoFijo,
          CostoTotal: el.costoTotal,
          HistoriaClinica: el.historiaClinica,
          Pendiente: el.pendiente,
          VisitasEstimadas: el.visitasEstimadas,
          PendientesEstimadas: el.pendientesEstimadas
        }
      }))
      setLoading(false)
    })
  }, [valueYear, valueMonth])
  return (
    <div>
        <SelectorYearMonth disabledSelect={false} defaulYear={valueYear} defaulMonth={valueMonth} selectYear={function (e: React.SetStateAction<number>): void {
          setselectvalueYear(e)
        } } selectMonth={function (e: React.SetStateAction<string>): void {
          setselectvalueMonth(e)
        } }/>
        <h1>Dezlizar {'->'}</h1>
        <SpinLoad spinning={loading}>
         <Table
            className='tabla-fact'
            columns={columns}
            dataSource={data}
            pagination={false}
          />
      </SpinLoad>
    </div>
  )
}
