import axios from 'axios'
import { User } from '../interfaces/'
import { Authentication } from '../interfaces/api/authentication'
import { authService } from './authService'
import { OpenNotification } from '../components/Notification/notification'

const loginService = async (user: User) => {
  const res = await axios(`${process.env.REACT_APP_URI_BASE}/Account/Login?Usuario=${user.usuario}&Password=${user.password}`)
    .then(res => {
      const apiToken: Authentication = res.data
      localStorage.setItem('token', apiToken.token)
      localStorage.setItem('userInfo', JSON.stringify(apiToken.userInfo))
      localStorage.setItem('userInfo-UsuarioLogin', JSON.stringify(user.usuario))
      authService()
      return apiToken
    }).catch(() => OpenNotification('Error', 'Usuario o Contraseña Incorrecta.', 'top', 'error'))
  return res
}
export const Logout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('userInfo')
  localStorage.removeItem('userInfo-UsuarioLogin')
  localStorage.removeItem('collapse')
  localStorage.removeItem('namePage')
  localStorage.removeItem('numNav')
  localStorage.removeItem('dat')
  localStorage.removeItem('selectMonth')
  localStorage.removeItem('selectYear')
  localStorage.removeItem('año')
  localStorage.removeItem('mes')
  localStorage.removeItem('nombrePaciente')
  window.location.reload()
}
export const LogoutChangePassword = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('userInfo')
  localStorage.removeItem('userInfo-UsuarioLogin')
  localStorage.removeItem('collapse')
  localStorage.removeItem('namePage')
  localStorage.removeItem('numNav')
  localStorage.removeItem('dat')
  localStorage.removeItem('selectMonth')
  localStorage.removeItem('selectYear')
  localStorage.removeItem('año')
  localStorage.removeItem('mes')
  localStorage.removeItem('nombrePaciente')
  window.location.reload()
  localStorage.setItem('CorrectChangePassword', JSON.stringify('true'))
}
export const LogoutTokenExpired = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('userInfo')
  localStorage.removeItem('userInfo-UsuarioLogin')
  localStorage.removeItem('collapse')
  localStorage.removeItem('namePage')
  localStorage.removeItem('numNav')
  localStorage.removeItem('dat')
  localStorage.removeItem('selectMonth')
  localStorage.removeItem('selectYear')
  localStorage.removeItem('año')
  localStorage.removeItem('mes')
  localStorage.removeItem('nombrePaciente')
  window.location.reload()
  localStorage.setItem('TokenExpired', JSON.stringify('true'))
}
export default loginService
