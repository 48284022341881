import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { ISpin } from '@interfaces-components/spin'

export const SpinLoad = (props: ISpin) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  return (<Spin indicator={antIcon} spinning={props.spinning}>{props.children}</Spin>)
}
